import React from "react";

import DashboardLayout from "../components/dashboardLayout";

//import ProspectApplicationVideoModal from "../components/prospectApplicationVideoModal";

import { connect } from "react-redux";

// import { updateUserDetails } from "../state/app";

// import firebase from "../utilities/firebase.js";

//ERROR!!!!!! This package is uninstalled - as it crashes the npm due to outaed React. Must be switched of fixed.
//import { useRecordWebcam } from "react-record-webcam";
//ERROR!!!!!! This package is uninstalled - as it crashes the npm due to outaed React. Must be switched of fixed.

const Apply = ({ isDarkMode, user, dispatch }) => {
  // const [applicationVideoBlob, setApplicationVideoBlob] = useState("");

  // const [modalOpen, setModalOpen] = useState(false);
  // const [modalVideoLink, setModalVideoLink] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [uploadProgress, setUploadProgress] = useState("0");
  // const [isDeletingVideoApplication, setIsDeletingVideoApplication] =
  //   useState(false);

  // const closeModal = () => {
  //   setModalOpen(false);
  // };

  // const recordWebcam = useRecordWebcam();

  // const saveFile = async () => {
  //   const blob = await recordWebcam.getRecording();
  //   console.log("SAVING FILE");
  //   setApplicationVideoBlob(blob);
  // };

  // if (user && user.userApplicationStatus === "idle" && !modalVideoLink) {
  //   setModalVideoLink(user.userApplicationVideoLink);
  // }

  // let sendVideoLinkToDb = async () => {
  //   setIsSubmitting(true);
  //   //const blob = await recordWebcam.getRecording();
  //   var storageRef = firebase.storage().ref();
  //   /*var videoRef = storageRef.child('user-video.mp4')
  //   videoRef.put(videoBlob).then(function (snapshot) {
  //     console.log('Uploaded a blob or file!')
  //   })*/

  //   // File or Blob named mountains.jpg
  //   var file = await recordWebcam.getRecording();

  //   // Create the file metadata
  //   var metadata = {
  //     contentType: "video/mp4",
  //   };

  //   // Upload file and metadata to the object 'images/mountains.jpg'
  //   var uploadTask = storageRef
  //     .child("applications/" + user.representsBrandId + "/" + user.id)
  //     .put(file, metadata);

  //   // Listen for state changes, errors, and completion of the upload.
  //   uploadTask.on(
  //     firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
  //     function (snapshot) {
  //       // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //       var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       setUploadProgress(Math.round(progress));
  //       console.log("Upload is " + progress + "% done");
  //       switch (snapshot.state) {
  //         case firebase.storage.TaskState.PAUSED: // or 'paused'
  //           console.log("Upload is paused");
  //           break;
  //         case firebase.storage.TaskState.RUNNING: // or 'running'
  //           console.log("Upload is running");
  //           break;
  //         default:
  //           break;
  //       }
  //     },
  //     function (error) {
  //       // A full list of error codes is available at
  //       // https://firebase.google.com/docs/storage/web/handle-errors
  //       setIsSubmitting(false);
  //       switch (error.code) {
  //         case "storage/unauthorized":
  //           // User doesn't have permission to access the object
  //           console.log("1");
  //           console.log(error);
  //           break;

  //         case "storage/canceled":
  //           // User canceled the upload
  //           console.log("2");
  //           break;

  //         case "storage/unknown":
  //           // Unknown error occurred, inspect error.serverResponse
  //           console.log("3");
  //           break;
  //         default:
  //           break;
  //       }
  //     },
  //     function () {
  //       // Upload completed successfully, now we can get the download URL
  //       uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
  //         console.log("File available at", downloadURL);
  //         var setVideoApplicationLink = firebase
  //           .functions()
  //           .httpsCallable("setVideoApplicationLink");

  //         setVideoApplicationLink({
  //           userApplicationVideoLink: downloadURL,
  //           userBrandAssociationDocumentId: user.userBrandAssociationDocumentId,
  //         }).then((result) => {
  //           console.log("RESULT DONE SET: ", result.data);
  //           console.log("ADDDDD!!!! ", downloadURL);
  //           dispatch(
  //             updateUserDetails({
  //               userApplicationVideoLink: downloadURL,
  //               userApplicationStatus: "idle",
  //               userApplicationVideoLinkCreated: "now",
  //             })
  //           );
  //           setIsSubmitting(false);
  //           window.location.reload(false); //TODO: This is here to prevent a bug where video is not ready to be played. Remove this when fixed.
  //         });
  //       });
  //     }
  //   );
  // };

  // let deleteVideoApplication = async () => {
  //   //const blob = await recordWebcam.getRecording();
  //   var storageRef = firebase.storage().ref();
  //   setIsDeletingVideoApplication(true);

  //   // Create a reference to the file to delete
  //   var videoApplicationRef = storageRef.child(
  //     "applications/" + user.representsBrandId + "/" + user.id
  //   );

  //   // Delete the file
  //   videoApplicationRef
  //     .delete()
  //     .then(() => {
  //       var deleteVideoApplicationLink = firebase
  //         .functions()
  //         .httpsCallable("deleteVideoApplicationLink");

  //       deleteVideoApplicationLink({
  //         userBrandAssociationDocumentId: user.userBrandAssociationDocumentId,
  //       }).then((result) => {
  //         console.log("RESULT DONE SET: ", result.data);

  //         console.log("DELETE!!!!");
  //         dispatch(
  //           updateUserDetails({
  //             userApplicationVideoLink: "na",
  //             userApplicationStatus: "not-applied",
  //             userApplicationVideoLinkCreated: "na",
  //           })
  //         );
  //         recordWebcam.retake();
  //         setIsDeletingVideoApplication(false);
  //       });
  //     })
  //     .catch((error) => {
  //       // Uh-oh, an error occurred!
  //     });
  // };

  // console.log(
  //   "user.userApplicationVideoLinkCreated: ",
  //   user.userApplicationVideoLinkCreated
  // );
  // console.log("user.role: ", user.role);
  // console.log("user.userApplicationStatus: ", user.userApplicationStatus);

  // //const recordWebcam = useRecordWebcam()

  // console.log("recordWebcam.status: ", recordWebcam.status);
  // console.log("recordWebcam.previewRef: ", recordWebcam.previewRef);
  // console.log("recordWebcam.webcamRef: ", recordWebcam.webcamRef);
  // console.log("user: ", user);

  return (
    <DashboardLayout>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        {/* <div className="bg-white py-12 px-12">
          <div className="space-y-8">
            {user.role === "prospect" &&
              user.userApplicationStatus === "not-applied" && (
                <div className="relative px-4 sm:px-6 lg:px-8">
                  <div className="text-lg max-w-prose mx-auto">
                    <div className="mb-4">
                      <h3 className="text-2xl leading-6 font-medium text-gray-900 mb-4">
                        Welcome to Nuine
                      </h3>
                      <p className="text-base text-gray-800 pt-2">
                        {`The first step to stream product pressenations for ${user.representsBrandName} is to submit
                      an video application.`}
                      </p>
                      <p className="text-base text-gray-800 pt-2">
                        {`After it is submitted, the team at
                      ${user.representsBrandName} will get back to you as soon as they have reviewd
                      your application.`}
                      </p>
                    </div>
                    <div className="mb-4">
                      <h4 className="text-l leading-6 font-medium text-gray-900 pt-4">
                        How to succeed with your application
                      </h4>
                      <p className="text-base text-gray-800 pt-2">
                        {`You are already invited, so chances are aready good.
                        Increase your chances of becoming an reprentant for ${user.representsBrandName} by pressenting the product as
                        you were talking to potential customers of the product.
                        We recomend you going thorugh these questions in your
                        application:`}
                      </p>
                      <ul className="text-base text-gray-800 pt-2 list-disc mx-12 my-2">
                        <li>Who are you - a short introduction</li>
                        <li>
                          What is the challenge of not having this product?
                        </li>
                        <li>
                          What makes this product great? (Talk about features
                          and how it fit into your life / how do you use it)
                        </li>
                        <li>Why do you recommend it to others?</li>
                        <li>What does reviews say about the product?</li>
                      </ul>
                      <p className="text-base text-gray-800 pt-2">
                        Do not forget to smile, be friendly and entusiastic. It
                        is usualy a good thing :)
                      </p>
                      <p className="text-base text-gray-800 pt-2">
                        Keep the application between 3 - 6 minutes long.
                      </p>
                    </div>
                    <div>
                      <h4 className="text-l leading-6 font-medium text-gray-900 pt-4">
                        Ready? Let's go!
                      </h4>
                      <p className="text-base text-gray-800 pt-2">
                        After you have recorded your application you can review
                        it. You can record your application as many times as you
                        want and send it in only when you are happy with it.
                      </p>
                    </div>
                    {isSubmitting ? (
                      <div className="flex justify-center items-center w-full h-96 bg-black mt-4">
                        <div className="flex justify-center items-center flex-col h-screen">
                          <svg
                            className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <p className="mt-4 text-white text-sm font-light">
                            Submitting application. Do not refresh.
                          </p>
                          <p className="text-white text-sm font-light">
                            {`Upload is ${uploadProgress}% complete.`}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-4">
                        {recordWebcam.status === "CLOSED" && (
                          <div className="flex justify-center items-center w-full h-96 bg-black">
                            <button
                              type="button"
                              className="px-6 h-12 py-2 my-4 text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={recordWebcam.open}
                            >
                              Open camera
                            </button>
                          </div>
                        )}

                        {recordWebcam.status !== "CLOSED" && (
                          <div className="flex items-center justify-center flex-grow h-full bg-gray-800">
                            <div
                              className="relative w-full h-0 flex justify-center"
                              style={{ paddingBottom: 55.25 + "%" }}
                            >
                              <video
                                className="w-full h-full transform scale-x-n100 absolute"
                                ref={recordWebcam.webcamRef}
                                autoPlay
                                muted
                              />

                              <video
                                className={
                                  recordWebcam.status === "PREVIEW"
                                    ? "w-full h-full transform scale-x-n100 absolute"
                                    : "hidden"
                                }
                                ref={recordWebcam.previewRef}
                                autoPlay
                                loop
                                controls
                              />

                              {recordWebcam.status === "OPEN" && (
                                <button
                                  type="button"
                                  onClick={recordWebcam.start}
                                  className="absolute z-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-50 bottom-4 self-end"
                                >
                                  Start recording
                                </button>
                              )}
                              {recordWebcam.status === "RECORDING" && (
                                <button
                                  type="button"
                                  onClick={recordWebcam.stop}
                                  className="absolute z-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-50 bottom-4 self-end"
                                >
                                  Stop recording
                                </button>
                              )}
                              {recordWebcam.status === "PREVIEW" && (
                                <div
                                  className={`flex justify-center space-x-4 absolute ${
                                    recordWebcam.status === "PREVIEW"
                                      ? "bottom-12"
                                      : "bottom-4"
                                  }`}
                                >
                                  <button
                                    type="button"
                                    onClick={recordWebcam.retake}
                                    className="z-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-50"
                                  >
                                    Retake
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => sendVideoLinkToDb()}
                                    className="z-10 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-50"
                                  >
                                    Submit application
                                  </button>
                                </div>
                              )}
                            </div>


                          </div>
                        )}
                      </div>
                    )}

                    
                  </div>
                </div>
              )}

            {user.role === "prospect" &&
              user.userApplicationVideoLink &&
              user.userApplicationVideoLink !== "na" && (
                <div className="relative px-4 sm:px-6 lg:px-8">
                  <ProspectApplicationVideoModal
                    modalOpen={modalOpen}
                    modalVideoLink={modalVideoLink}
                    closeModal={closeModal}
                  />
                  <div className="text-lg max-w-prose mx-auto">
                    <h1>
                      <span className="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
                        Success
                      </span>
                      <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Your application is being reviewed
                      </span>
                    </h1>
                    <p className="mt-8 text-xl text-gray-500 leading-8">
                      {`The ${user.representsBrandName} team has received your application. You will
                  receive an email as soon as they have reviewed your
                  application. Below you can see the current status of your
                  application.`}
                    </p>

                    <div>
                      
                      <div className="shadow sm:hidden">
                        <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                          <li>
                            <a
                              href="/#"
                              className="block px-4 py-4 bg-white hover:bg-gray-50"
                            >
                              <span className="flex items-center space-x-4">
                                <span className="flex-1 flex space-x-2 truncate">
                                  

                                  <span className="flex flex-col text-gray-500 text-sm truncate">
                                    <span className="truncate">
                                      {user.userApplicationVideoLinkCreated ===
                                      "now"
                                        ? "Just now"
                                        : new Date(
                                            user.userApplicationVideoLinkCreated
                                              ._seconds * 1000
                                          ).toLocaleDateString()}
                                    </span>
                                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 capitalize">
                                      {user.userApplicationVideoLinkStatus}
                                    </span>
                                    <button
                                      onClick={() => {}}
                                      type="button"
                                      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      Play
                                    </button>
                                  </span>
                                </span>
                                
                                <svg
                                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </a>
                          </li>
                          
                        </ul>
                      </div>
                      
                      <div className="hidden sm:block mt-8">
                        <div className="max-w-6xl mx-auto">
                          <div className="flex flex-col mt-2">
                            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                  <tr>
                                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      Date
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      Status
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      
                                    </th>
                                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  <tr className="bg-white">
                                    <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                      <div className="flex">
                                        <a
                                          href="/#"
                                          className="group inline-flex space-x-2 truncate text-sm"
                                        >
                                          
                                          <p className="text-gray-500 truncate group-hover:text-gray-900">
                                            {user.userApplicationVideoLinkCreated ===
                                            "now"
                                              ? "Just now"
                                              : new Date(
                                                  user
                                                    .userApplicationVideoLinkCreated
                                                    ._seconds * 1000
                                                ).toLocaleDateString()}
                                          </p>
                                        </a>
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 capitalize">
                                        {user.userApplicationStatus}
                                      </span>
                                    </td>

                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                      <button
                                        onClick={() => setModalOpen(true)}
                                        type="button"
                                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        disabled={isDeletingVideoApplication}
                                      >
                                        Play
                                      </button>
                                    </td>
                                    <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                      <button
                                        onClick={deleteVideoApplication}
                                        type="button"
                                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        disabled={isDeletingVideoApplication}
                                      >
                                        {isDeletingVideoApplication ? (
                                          <svg
                                            className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <circle
                                              className="opacity-25"
                                              cx="12"
                                              cy="12"
                                              r="10"
                                              stroke="currentColor"
                                              strokeWidth="4"
                                            ></circle>
                                            <path
                                              className="opacity-75"
                                              fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                          </svg>
                                        ) : (
                                          "Delete"
                                        )}
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div> */}
      </div>
      {/*<button
        style={isDarkMode ? { background: 'black', color: 'white' } : null}
        onClick={() => dispatch(toggleDarkMode(!isDarkMode))}
      >
        Dark mode {isDarkMode ? 'on' : 'off'}{' '}
      </button>*/}
    </DashboardLayout>
  );
};

export default connect(
  (state) => ({
    isDarkMode: state.app.isDarkMode,
    user: state.app.user,
  }),
  null
)(Apply);
