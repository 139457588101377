import React, { useState, useEffect } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { RadioGroup } from '@headlessui/react'

import { connect } from "react-redux";

import firebaseFunction from "./../utilities/firebaseFunction.js";

const ChangePaymentMethod = ({ reduxState, props }) => {
  const [stripeCard, setStripeCard] = useState("");
  const [isTalkingToStripe, setIsTalkingToStripe] = useState(false);
  const [stripeElementError, setStripeElementError] = useState(false);
  const [isStripeElementCompleted, setIsStripeElementCompleted] =
    useState(false);
  const [updatingPaymentDetails, setUpdatingPaymentDetails] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('newCard')
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(false)

  console.log("customerPaymentMethods: ", customerPaymentMethods)

  useEffect(() => {
    const getPaymentMethods = async () => {
      let customerPaymentMethodsResponse = await firebaseFunction("getCustomersPaymentMethods", {
        brandId: reduxState.brandId,
        stripeCustomerId: reduxState.stripeCustomerId
      })
      if (customerPaymentMethodsResponse.data) {
        setCustomerPaymentMethods(customerPaymentMethodsResponse.data)
        console.log("customerPaymentMethodsResponse.data::: ", customerPaymentMethodsResponse.data)

        customerPaymentMethodsResponse.data.map(method => {
          if (method.default) {
            setSelectedPaymentMethod(method.id)
          }
        })
      } else {
        setCustomerPaymentMethods(false)
      }
    }

    getPaymentMethods()

  }, [])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const stripe = useStripe();
  const elements = useElements();



  const updateCreditCardDetails = async () => {
    setUpdatingPaymentDetails(true)
    // Block native form submission.
    //event.preventDefault();

    let newPaymentMethod = selectedPaymentMethod
    let newCard = false

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Create payment method and confirm payment intent.
    if (selectedPaymentMethod === "newCard") {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      newPaymentMethod = paymentMethod.id
      newCard = true
    }

    let setDefaultPaymentMethod = await firebaseFunction("setDefaultPaymentMethod", {
      brandId: reduxState.brandId,
      stripeCustomerId: reduxState.stripeCustomerId,
      paymentMethodId: newPaymentMethod,
      newCard: newCard
    })
    console.log("PYMNT: ", setDefaultPaymentMethod)
    setUpdatingPaymentDetails(false)
  }

  const stripeElementStatus = (event) => {
    console.log(event);
    setIsStripeElementCompleted(event.complete);
    if (event.error) {
      setStripeElementError(event.error.message);
    } else {
      setStripeElementError(false);
    }
  };

  return (
    <>
      <div>
        <h2>Change card details</h2>
      </div>
      <div className="col-span-6 sm:col-span-6 mb-8">
        <RadioGroup value={selectedPaymentMethod} onChange={setSelectedPaymentMethod}>
          <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
          <div className="bg-white rounded-md -space-y-px">
            {customerPaymentMethods && customerPaymentMethods.map((method, i) => {
              return (
                <RadioGroup.Option
                  key={i}
                  value={method.id}
                  className={({ checked }) =>
                    classNames(
                      i === 0 && 'rounded-tl-md rounded-tr-md',
                      //settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                      checked ? 'bg-coral-50 border-coral-500 z-10' : 'border-gray-200',
                      'relative border p-4 flex cursor-pointer focus:outline-none'
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span
                        className={classNames(
                          checked ? 'bg-coral-500 border-transparent' : 'bg-white border-gray-300',
                          active ? '' : '',
                          'h-4 w-4 min-w-[16px] mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                        )}
                        aria-hidden="true"
                      >
                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                      </span>
                      <div className="ml-3 flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className={classNames(checked ? 'text-black' : 'text-gray-900', 'block text-sm font-medium')}
                        >
                          {method.brand.charAt(0).toUpperCase() + method.brand.slice(1)}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={classNames(checked ? 'text-black' : 'text-gray-500', 'block text-sm')}
                        >
                          **** {method.last4}, {method.expMonth}/{method.expYear}
                        </RadioGroup.Description>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              )

            })}

            <RadioGroup.Option
              key='newCard'
              value={'newCard'}
              className={({ checked }) =>
                classNames(
                  (customerPaymentMethods === false || customerPaymentMethods === undefined || customerPaymentMethods.length == 0) ? 'rounded-md' : 'rounded-bl-md rounded-br-md',
                  checked ? 'bg-coral-50 border-coral-500 z-10' : 'border-gray-200',
                  'relative border p-4 flex cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={classNames(
                      checked ? 'bg-coral-500 border-transparent' : 'bg-white border-gray-300',
                      active ? '' : '',
                      'h-4 w-4 min-w-[16px] mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={classNames(checked ? 'text-black' : 'text-gray-900', 'block text-sm font-medium')}
                    >
                      Add a new credit card
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={classNames(checked ? 'text-black' : 'text-gray-500', 'block text-sm')}
                    >
                    </RadioGroup.Description>
                    <div className={classNames(checked ? 'flex flex-col pt-2 w-[330px]' : 'hidden')}>
                      <div
                        className={`${stripeElementError
                          ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                          : "border border-gray-300 focus:ring-black focus:border-coral-500"
                          } appearance-none block w-full px-3 py-3 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-coral-500 sm:text-base bg-white mt-1`}
                      >
                        <CardElement
                          onChange={(event) => {
                            stripeElementStatus(event);
                          }}
                          options={{
                            style: {
                              base: {
                                "fontSize": "14px",
                                "fontFamily":
                                  'monospace, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
                                "color": "black",
                                "::placeholder": {
                                  color: "#9ca3af",
                                },
                              },
                              invalid: {
                                color: "#991b1b",
                              },
                            },
                          }}
                        />
                      </div>
                      {stripeElementError && (
                        <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800 w-fit">
                          {stripeElementError}
                        </span>
                      )}

                    </div>
                  </div>
                </>
              )}
            </RadioGroup.Option>

          </div>
        </RadioGroup>

      </div>
      <div className="text-right">
        <button onClick={() => { updateCreditCardDetails() }} className="primary-button min-h-[42px] min-w-[84px]">
          {updatingPaymentDetails ?

            <svg
              className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            :
            "Save"
          }

        </button>
      </div>
    </>
  )
}


export default connect(
  (state, props) => ({
    reduxState: state.app,
    props: props,
  }),
  null
)(ChangePaymentMethod)