import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";

import DashboardLayout from "../components/dashboardLayout";

import StreamSteps from "../components/streamSteps";
import { navigate, Link } from "gatsby";
import { DuplicateIcon } from "@heroicons/react/outline";
import { addInitialData } from "../state/app";

const StreamDetails = ({ state, stream, dispatch }) => {
  const [streamError, setStreamError] = useState(false);
  const [stepStatus, setStepStatus] = useState({
    status: "idle",
    step1: "current",
    step2: "upcoming",
    step3: "upcoming",
  });
  const [processingStreamDetails, setProcessingStreamDetails] = useState(false)

  useEffect(() => {
    console.log("YEEEEY: ", state.streamStatus)
    if (state.streamStatus === "preview") {
      navigate("/app/stream/preview")
    } else if (state.streamStatus === "active") {
      navigate("/app/stream/active")
    } else if (state.streamStatus === "ended") {
      navigate("/app/stream/customize")
    } else if (state.streamStatus === undefined || state.streamStatus === "canceled") {
      navigate("/app/stream/customize")
    }
  }, [state.streamStatus]);

  const copyCredentialToClipboard = (credentialType, credential) => {

    navigator.clipboard.writeText(credential).then(
      () => {
        console.log("Copying to clipboard was successful: ", credential);
        dispatch(
          addInitialData({
            notification: { showNotification: true, type: "success", header: `${credentialType} is copied`, message: false }
          })
        );
      },
      (error) => {
        console.error("Could not copy text: ", error);
      }
    );
  };

  const cancelCurrentStreamSetup = () => {
    setProcessingStreamDetails(true)
    var cancelCurrentStreamSetup = firebase
      .functions()
      .httpsCallable("cancelCurrentStreamSetup");

    cancelCurrentStreamSetup({
      brandId: state.brandId,
      streamId: state.streamId
    }).then((result) => {
      console.log("Yesy!")
    });
  }

  return (
    <>
      <DashboardLayout>
        <StreamSteps stepStatus={stepStatus} />
        <div
          className={
            stream.isStreaming
              ? "space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-gray-900 h-full"
              : "space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-gray-900"
          }
        >
          <div
            className={
              stream.isStreaming
                ? "cardContainerOuter h-full"
                : "cardContainerOuter"
            }
          >
            <div
              className={
                stream.isStreaming
                  ? "cardContainerInner h-full"
                  : "cardContainerInner"
              }
            >
              {streamError && (
                <div className="absolute z-10 rounded-md bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      {/* Heroicon name: solid/information-circle */}
                      <svg
                        className="h-5 w-5 text-yellow-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-yellow-700">{`${streamError}`}</p>
                    </div>
                  </div>
                </div>
              )}
              <div className="">

                <div className="grid space-x-8">
                  <div className="">
                    <h2 className="mb-16">Initiate the stream</h2>

                    <div className="flex flex-row mb-16">
                      <div className="h-8 w-8 min-w-[32px] mr-6 bg-coral-500 flex justify-center items-center font-bold text-xl text-whiteish antialiased">
                        1
                      </div>
                      <div>
                        <h3 className="">Open OBS</h3>
                        <p className="mt-4">Open OBS from your computer</p>
                        <p>( New to <b>nuine</b>? Download, configure and learn why you need OBS <Link to="/app/docs/stream" className="text-coral-500 hover:text-coral-600">here</Link> )</p>
                      </div>
                    </div>
                    <div className="flex flex-row mb-16">
                      <div className="h-8 w-8 min-w-[32px] mr-6 bg-coral-500 flex justify-center items-center font-bold text-xl text-whiteish antialiased">
                        2
                      </div>
                      <div>
                        <h3 className="">Credentials</h3>
                        <p className="mt-4">Insert the following credentials into OBS</p>
                        <p>Stream URL: <b>{state.streamURL}</b>
                          <button
                            type="button"
                            onClick={() => {
                              copyCredentialToClipboard("Stream URL", state.streamURL);
                            }}
                          >
                            <DuplicateIcon className="ml-2 h-6 w-6 mb-[-7px] hover:text-gray-600" />
                          </button>
                        </p>
                        <p>Stream key: <b>{state.streamKey}</b>
                          <button
                            type="button"
                            onClick={() => {
                              copyCredentialToClipboard("Stream key", state.streamKey);
                            }}
                          >
                            <DuplicateIcon className="ml-2 h-6 w-6 mb-[-7px] hover:text-gray-600" />
                          </button>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row mb-16">
                      <div className="h-8 w-8 min-w-[32px] mr-6 bg-coral-500 flex justify-center items-center font-bold text-xl text-whiteish antialiased">
                        3
                      </div>
                      <div>
                        <h3 className="">Preview stream</h3>
                        <p className="mt-4">A few seconds after you click “Start stream” in OBS, this screen is changed to a preview of the stream (we know - magic). In the preview you can see how the stream will look before it goes live on your webpage. This means that nothing is streamed to your webpage before you press the “Publish stream” button in the next step.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button onClick={() => { cancelCurrentStreamSetup() }} className="secondary-button">
                  {processingStreamDetails ? <svg
                    className="animate-spin h-5 w-5 flex justify-center align-middle text-coral-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg> : "Go back"}
                </button>

              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default connect(
  (state) => ({
    state: state.app,
    stream: state.app.stream,
  }),
  null
)(StreamDetails);
