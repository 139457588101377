import firebase from "firebase/app";
import "firebase/functions";

export default async function (functionName, data = {}) {
  let firebaseFunction = firebase
    .functions()
    .httpsCallable(functionName);
  try {
    const response = await firebaseFunction(data)
    //return response
    return { error: false, ...response }
  } catch (error) {
    console.error("Firebase function error: ", error.code);
    console.error("Firebase function error: ", error.message);
    return { error: true, data: { message: error.message } }
  }
}
