import React, { useState } from "react";
import { Link } from "gatsby";

import { connect } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StreamStep4 = ({ state, dispatch, props }) => {
  return (
    <div className="py-6">
      <h2>Your stream has ended</h2>
      <p>You successfully ended your stream</p>
      <Link to="/app/dashboard" className="primary-button mt-6">
        Go home
      </Link>
    </div>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(StreamStep4);
