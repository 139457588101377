import React, { useState } from "react";
import { useFormik } from "formik";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";
import SettingsMenu from "../components/settingsMenu";

import { addInitialData } from "../state/app";

const SettingsPassword = ({ dispatch }) => {
  const validatePasswordChange = (values) => {
    console.log(values);

    const errors = {};

    if (!values.currentPassword) {
      errors.currentPassword = "Required";
    }

    if (!values.newPassword) {
      errors.newPassword = "Required";
    } else if (values.newPassword.length < 12) {
      errors.newPassword = "Must be 12 characters or more";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.confirmPassword.length < 12) {
      errors.confirmPassword = "Must be 12 characters or more";
    }

    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = "Password not matched";
    }

    console.log(errors);
    return errors;
  };

  const formikPasswordChange = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: validatePasswordChange,
    onSubmit: (values, { resetForm }) => {
      console.log("PASSWORD CHANGE!!!!");

      let user = firebase.auth().currentUser;
      var credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        values.currentPassword
      );

      user
        .reauthenticateWithCredential(credential)
        .then(function () {
          user
            .updatePassword(values.newPassword)
            .then(function () {
              console.log("Password update successful");
              dispatch(
                addInitialData({
                  notification: { showNotification: true, type: "success", header: "Your password is changed", message: false }
                })
              );
            })
            .catch(function (error) {
              console.log("Password udate failed: ", error);
              // An error happened
            });
        })
        .catch(function (error) {
          // An error happened
        });
      resetForm({});
    },
  });

  return (
    <div>
      <DashboardLayout>
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <SettingsMenu page="password" />

          <form onSubmit={formikPasswordChange.handleSubmit}>
            <div className="cardContainerOuter">
              <div className="cardContainerInner">
                <div>
                  <h2>Change password</h2>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="currentPassword">Current password</label>
                    <div className="mt-1">
                      <input
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        autoComplete="current-password"
                        required
                        onChange={formikPasswordChange.handleChange}
                        onBlur={formikPasswordChange.handleBlur}
                        value={formikPasswordChange.values.currentPassword}
                        className={`${formikPasswordChange.errors.currentPassword &&
                          formikPasswordChange.touched.currentPassword
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formikPasswordChange.errors.currentPassword &&
                        formikPasswordChange.touched.currentPassword && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formikPasswordChange.errors.currentPassword}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3"></div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="newPassword">New password</label>
                    <p className="mb-2 text-sm">Minimum 12 characters</p>
                    <div className="mt-1">
                      <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        required
                        onChange={formikPasswordChange.handleChange}
                        onBlur={formikPasswordChange.handleBlur}
                        value={formikPasswordChange.values.newPassword}
                        className={`${formikPasswordChange.errors.newPassword &&
                          formikPasswordChange.touched.newPassword
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formikPasswordChange.errors.newPassword &&
                        formikPasswordChange.touched.newPassword && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formikPasswordChange.errors.newPassword}
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="confirmPassword">
                      Re-type new password
                    </label>
                    <p className="mb-2 text-sm">Minimum 12 characters</p>
                    <div className="mt-1">
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        type="password"
                        required
                        onChange={formikPasswordChange.handleChange}
                        onBlur={formikPasswordChange.handleBlur}
                        value={formikPasswordChange.values.confirmPassword}
                        className={`${formikPasswordChange.errors.confirmPassword &&
                          formikPasswordChange.touched.confirmPassword
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formikPasswordChange.errors.confirmPassword &&
                        formikPasswordChange.touched.confirmPassword && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formikPasswordChange.errors.confirmPassword}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr-12 pb-4 text-right sm:pr-12 sm:pb-6">
                <button type="submit" className="primary-button">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default connect(
  (state) => ({

  }),
  null
)(SettingsPassword);
