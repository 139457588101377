import React, { useState } from "react";
import { useFormik } from "formik";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";
import SettingsMenu from "../components/settingsMenu";

import { addInitialData } from "../state/app";

const SettingsGeneral = ({ state, dispatch }) => {

  const validatePersonalDetails = (values) => {
    console.log(values);

    const errors = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 200) {
      errors.firstName = "Must be 200 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 200) {
      errors.lastName = "Must be 200 characters or less";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.securityPassword) {
      errors.securityPassword = "Required";
    }

    console.log(errors);
    return errors;
  };

  const validateBusinessDetails = (values) => {
    console.log(values);

    const errors = {};

    if (!values.brandName) {
      errors.brandName = "Required";
    } else if (values.brandName.length > 200) {
      errors.brandName = "Must be 200 characters or less";
    }

    if (values.supportsiteURL !== "") {
      if (
        !/^([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))?$/i.test(
          values.supportsiteURL
        )
      ) {
        errors.supportsiteURL =
          'Invalid URL. Should be in the form "www.example.com"';
      }
    }

    console.log(errors);
    return errors;
  };

  const formikPersonalDetails = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: state.userFirstName || "",
      lastName: state.userLastName || "",
      email: state.userEmail || "",
      securityPassword: "",
    },
    validate: validatePersonalDetails,
    onSubmit: (values, { resetForm }) => {
      console.log("UPDATE USER DETAILS!!!!");
      let user = firebase.auth().currentUser;
      console.log("UPDATE USER DETAILS!!!!: ", user);
      console.log("UPDATE USER DETAILS!!!!: ", state.userId);
      console.log("UPDATE USER DETAILS!!!!: ", state.userEmail);
      console.log("UPDATE USER DETAILS!!!!: ", values.email);

      if (state.userEmail === values.email) {
        console.log("NO EMAIL");
        var userRef = firebase.firestore().collection("users").doc(state.userId);

        // Set the "capital" field of the city 'DC'
        userRef
          .update({
            firstName: values.firstName,
            lastName: values.lastName,
          })
          .then(() => {
            console.log("Document successfully updated!");
            dispatch(
              addInitialData({
                notification: { showNotification: true, type: "success", header: "Your personal details is updated", message: false }
              })
            );
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
          });
      } else {
        console.log("EMAIL UPDATE");
        var credential = firebase.auth.EmailAuthProvider.credential(
          state.userEmail,
          values.securityPassword
        );

        user
          .reauthenticateWithCredential(credential)
          .then(function () {
            user
              .updateEmail(values.email)
              .then(function () {
                console.log("Email update successful");

                var userRef = firebase
                  .firestore()
                  .collection("users")
                  .doc(state.userId);

                // Set the "capital" field of the city 'DC'
                userRef
                  .update({
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                  })
                  .then(() => {
                    console.log("Document successfully updated!");
                    dispatch(
                      addInitialData({
                        notification: { showNotification: true, type: "success", header: "Your personal details is updated", message: false }
                      })
                    );
                  })
                  .catch((error) => {
                    // The document probably doesn't exist.
                    console.error("Error updating document: ", error);
                  });
              })
              .catch(function (error) {
                console.log("Email update error: ", error);
              });
          })
          .catch(function (error) {
            // An error happened.
          });
      }
      //resetForm({ securityPassword: "" });
    },
  });

  const formikBusinessDetails = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandName: state.brandName || "",
      supportsiteURL: state.supportsiteURL || "",
    },
    validate: validateBusinessDetails,
    onSubmit: (values, { resetForm }) => {
      var updateBrand = firebase.functions().httpsCallable("updateBrand");

      updateBrand({
        brandId: state.brandId,
        formValues: values,
      })
        .then((result) => {
          if (result.data.code === 200) {
            dispatch(
              addInitialData({
                notification: { showNotification: true, type: "success", header: "Your business details is updated", message: false }
              })
            );
          } else {
            console.log("Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <div>
      <DashboardLayout>
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <SettingsMenu page="general" />
          <form onSubmit={formikPersonalDetails.handleSubmit}>
            <div className="cardContainerOuter border-b pb-12">
              <div className="cardContainerInner">
                <div>
                  <h2>Personal information</h2>
                  <p className="mt-4">
                    Only your firstname will be shown in live streams
                  </p>
                </div>
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="firstName">First name</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        autoComplete="given-name"
                        required
                        onChange={formikPersonalDetails.handleChange}
                        onBlur={formikPersonalDetails.handleBlur}
                        value={formikPersonalDetails.values.firstName}
                        className={`${formikPersonalDetails.errors.firstName &&
                          formikPersonalDetails.touched.firstName
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formikPersonalDetails.errors.firstName &&
                        formikPersonalDetails.touched.firstName && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formikPersonalDetails.errors.firstName}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="lastName">Last name</label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        autoComplete="family-name"
                        required
                        onChange={formikPersonalDetails.handleChange}
                        onBlur={formikPersonalDetails.handleBlur}
                        value={formikPersonalDetails.values.lastName}
                        className={`${formikPersonalDetails.errors.lastName &&
                          formikPersonalDetails.touched.lastName
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formikPersonalDetails.errors.lastName &&
                        formikPersonalDetails.touched.lastName && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formikPersonalDetails.errors.lastName}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="email">Email address</label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        onChange={formikPersonalDetails.handleChange}
                        onBlur={formikPersonalDetails.handleBlur}
                        value={formikPersonalDetails.values.email}
                        className={`${formikPersonalDetails.errors.email &&
                          formikPersonalDetails.touched.email
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formikPersonalDetails.errors.email &&
                        formikPersonalDetails.touched.email && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formikPersonalDetails.errors.email}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3"></div>
                  <div className="col-span-6 sm:col-span-3 mt-12">
                    <label htmlFor="securityPassword">Password</label>
                    <p className="mb-2 text-sm">
                      To update personal details you need to provide your
                      password
                    </p>
                    <div className="mt-1">
                      <input
                        type="password"
                        name="securityPassword"
                        id="securityPassword"
                        autoComplete="current-password"
                        required
                        onChange={formikPersonalDetails.handleChange}
                        onBlur={formikPersonalDetails.handleBlur}
                        value={formikPersonalDetails.values.securityPassword}
                        className={`${formikPersonalDetails.errors.securityPassword &&
                          formikPersonalDetails.touched.securityPassword
                          ? "border-red-800"
                          : "border-gray-300"
                          } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                      />
                      {formikPersonalDetails.errors.securityPassword &&
                        formikPersonalDetails.touched.securityPassword && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formikPersonalDetails.errors.securityPassword}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr-12 pb-4 text-right sm:pr-12 sm:pb-6">
                <button type="submit" className="primary-button">
                  Save
                </button>
              </div>
            </div>
          </form>

          {state.userRole === "admin" && (
            <>
              <form onSubmit={formikBusinessDetails.handleSubmit}>
                <div className="cardContainerOuter pb-12">
                  <div className="cardContainerInner">
                    <div>
                      <h2>Business information</h2>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="brandName">Brand name</label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="brandName"
                            id="brandName"
                            autoComplete="given-name"
                            required
                            onChange={formikBusinessDetails.handleChange}
                            onBlur={formikBusinessDetails.handleBlur}
                            value={formikBusinessDetails.values.brandName}
                            className={`${formikBusinessDetails.errors.brandName &&
                              formikBusinessDetails.touched.brandName
                              ? "border-red-800"
                              : "border-gray-300"
                              } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                          />
                          {formikBusinessDetails.errors.brandName &&
                            formikBusinessDetails.touched.brandName && (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {formikBusinessDetails.errors.brandName}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label htmlFor="supportsiteURL">Support site URL</label>
                        <div className="mt-1">
                          <input
                            name="supportsiteURL"
                            id="supportsiteURL"
                            autoComplete="url"
                            onChange={formikBusinessDetails.handleChange}
                            onBlur={formikBusinessDetails.handleBlur}
                            value={formikBusinessDetails.values.supportsiteURL}
                            className={`${formikBusinessDetails.errors.supportsiteURL &&
                              formikBusinessDetails.touched.supportsiteURL
                              ? "border-red-800"
                              : "border-gray-300"
                              } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                          />
                          {formikBusinessDetails.errors.supportsiteURL &&
                            formikBusinessDetails.touched.supportsiteURL && (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {formikBusinessDetails.errors.supportsiteURL}
                              </span>
                            )}
                        </div>
                        <p className="mt-2 label-description">
                          If a viewer ask a support question, the sales
                          representative can refer them to your support pages
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="pr-12 pb-4 text-right sm:pr-12 sm:pb-6">
                    <button type="submit" className="primary-button">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </DashboardLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    state: state.app
  }),
  null
)(SettingsGeneral);
