import React, { useState } from "react";
// import { useFormik } from "formik";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";
import SettingsMenu from "../components/settingsMenu";

// import { updateUserDetails } from "../state/app";
import ChangePaymentMethod from "../components/changePaymentMethod"

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { countries } from "../utilities/countries";
import { usStates } from "../utilities/usStates";
import { caStates } from "../utilities/caStates";

import { ChevronDownIcon } from "@heroicons/react/solid";

//const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const SettingsBilling = ({ reduxState }) => {
  const [updateDetails] = useState("");
  const [country, setCountry] = useState("US");
  const [state, setState] = useState("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [updatingBillingDetails, setUpdatingBillingDetails] = useState(false);
  const [stripePromise, setStripePromise] = useState(loadStripe(process.env.STRIPE_PUBLIC_KEY))

  const updateBillingAddress = (formValues) => {
    setUpdatingBillingDetails(true)
    var cfUpdateBillingAddress = firebase
      .functions()
      .httpsCallable("updateBillingAddress");

    cfUpdateBillingAddress({
      brandId: reduxState.brandId,
      customerId: reduxState.stripeCustomerId,
      formValues: formValues,
    })
      .then((result) => {
        setUpdatingBillingDetails(false)

      })
      .catch((error) => {
        setUpdatingBillingDetails(false)
        console.log(error);
      });
  };

  return (
    reduxState.userRole === "admin" && (
      <div>
        <DashboardLayout>
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <SettingsMenu page="billing" />

            <Formik
              initialValues={{
                firstName: reduxState.billingAddress.firstName || "",
                lastName: reduxState.billingAddress.lastName || "",
                email: reduxState.billingAddress.email || "",
                companyName: reduxState.billingAddress.companyName || "",
                taxId: reduxState.billingAddress.taxId || "",
                country: reduxState.billingAddress.country || "US",
                state: reduxState.billingAddress.state || "",
                addressLine1: reduxState.billingAddress.addressLine1 || "",
                addressLine2: reduxState.billingAddress.addressLine2 || "",
                postalCode: reduxState.billingAddress.postalCode || "",
                city: reduxState.billingAddress.city || "",
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Required"),
                companyName: Yup.string().required("Required"),
                country: Yup.string().required("Required"),
                addressLine1: Yup.string().required("Required"),
                postalCode: Yup.string().required("Required"),
                city: Yup.string().required("Required"),
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setFormSubmitting(true);
                updateBillingAddress(values);
                setSubmitting(false);
                setFormSubmitting(false);
              }}
            >
              {({ errors, touched, setFieldValue, isValidating }) => (
                <Form>
                  <div className="cardContainerOuter pb-6">
                    <div className="cardContainerInner">
                      <div>
                        <h2>Billing address</h2>
                      </div>
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="firstName">First name</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="text"
                              name="firstName"
                              autoComplete="given-name"
                              placeholder="Buzz"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.firstName && touched.firstName
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="firstName">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="lastName">Last name</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="text"
                              name="lastName"
                              autoComplete="family-name"
                              required
                              placeholder="Lightyear"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.lastName && touched.lastName
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="lastName">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-6 sm:col-span-6">
                          <label htmlFor="email">Email Address</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              name="email"
                              placeholder="buzz@lightyear.com"
                              type="email"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.email && touched.email
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="email">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="companyName">Company name</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="text"
                              name="companyName"
                              autoComplete="organization"
                              placeholder="Moonshot company"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.companyName && touched.companyName
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="companyName">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="taxId">Tax ID #</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="text"
                              name="taxId"
                              autoComplete=""
                              placeholder="i.e. xx-xxxxxxx"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.taxId && touched.taxId
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="taxId">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div
                          className={`${country === "US" || country === "CA"
                            ? "col-span-6 sm:col-span-3"
                            : "col-span-6 sm:col-span-6"
                            }`}
                        >
                          <label htmlFor="country">Country</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              id="country"
                              name="country"
                              as="select"
                              className={`${errors.country && touched.country
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              /*onChange={(e) => {
                            
                          }}*/
                              onChange={(e) => {
                                console.log("e.target.value: ", e.target.value);
                                setCountry(e.target.value);
                                setFieldValue("country", e.target.value);
                              }}
                            >
                              <option value="None">Select country</option>
                              {countries.map((country, i) => {
                                return (
                                  <option value={country.abbreviation} key={i}>
                                    {country.name}
                                  </option>
                                );
                              })}
                              {/*<option value="United States">
                          United States
                        </option>
                      <option value="Canada">Canada</option>*/}
                            </Field>
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <ErrorMessage name="country">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        {(country === "US" || country === "CA") && (
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="state">State</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                id="state"
                                name="state"
                                as="select"
                                className={`${errors.state && touched.state
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                                onChange={(e) => {
                                  setState(e.target.value);
                                  setFieldValue("state", e.target.value);
                                }}
                              >
                                <option value="None">Select state</option>
                                {country === "US"
                                  ? usStates.map((state, i) => {
                                    return (
                                      <option
                                        value={state.abbreviation}
                                        key={i}
                                      >
                                        {state.name}
                                      </option>
                                    );
                                  })
                                  : caStates.map((state, i) => {
                                    return (
                                      <option
                                        value={state.abbreviation}
                                        key={i}
                                      >
                                        {state.name}
                                      </option>
                                    );
                                  })}
                                {/*<option value="United States">
                          United States
                        </option>
                      <option value="Canada">Canada</option>*/}
                              </Field>
                              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ChevronDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                            <ErrorMessage name="state">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                        )}

                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="addressLine1">Address line 1</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="text"
                              name="addressLine1"
                              autoComplete="address-line1"
                              placeholder="i.e. xx-xxxxxxx"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.addressLine1 && touched.addressLine1
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="addressLine1">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="addressLine2">Address line 2</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="text"
                              name="addressLine2"
                              autoComplete="address-line2"
                              placeholder="i.e. xx-xxxxxxx"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.addressLine2 && touched.addressLine2
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="addressLine2">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="postalCode">Postal code</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="text"
                              name="postalCode"
                              autoComplete="postal-code"
                              placeholder="i.e. xx-xxxxxxx"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.postalCode && touched.postalCode
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="postalCode">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label htmlFor="city">City</label>
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="text"
                              name="city"
                              autoComplete="city"
                              placeholder="i.e. xx-xxxxxxx"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.city && touched.city
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="city">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>

                        {/*<button type="submit">Subscribe</button> */}
                      </div>
                    </div>
                    <div className="pr-12 pb-4 text-right sm:pr-12 sm:pb-6">
                      <button type="submit" className="primary-button min-h-[42px] min-w-[84px]">
                        {updatingBillingDetails ?

                          <svg
                            className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          :
                          "Save"
                        }

                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="cardContainerOuter pb-12 pt-6 border-t">
              <div className="cardContainerInner">

                <Elements stripe={stripePromise}>
                  <ChangePaymentMethod />
                </Elements>
              </div>
            </div>
          </div>
        </DashboardLayout>
      </div>
    )
  );
};

export default connect(
  (state) => ({
    reduxState: state.app,
  }),
  null
)(SettingsBilling);
