import React from 'react'
import { navigate } from 'gatsby'
import firebase from "./../utilities/firebase.js";
import { connect } from 'react-redux'

const PrivateRoute = ({
  appIsReady,
  dispatch,
  component: Component,
  location,
  ...rest
}) => {
  if (!appIsReady && location.pathname !== `/app/login`) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("Sign out")
      })
      .catch((error) => {
        console.log(error);
      });
    return null
  }

  return <Component {...rest} />
}

export default connect(
  state => ({
    appIsReady: state.app.appIsReady,
  }),
  null
)(PrivateRoute)
