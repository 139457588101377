import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";

import DashboardLayout from "../components/dashboardLayout";

import StreamSteps from "../components/streamSteps";
import { navigate } from "gatsby";
import { PlayerSdk } from "@api.video/player-sdk";

import hls from 'hls.js';

import firebaseFunction from "./../utilities/firebaseFunction.js";

const StreamPreview = ({ state, stream, dispatch }) => {
  const [streamError, setStreamError] = useState(false);
  const [apiVideoSDK, setApiVideoSDK] = useState("");
  const [videoSize, setVideoSize] = useState(100);
  const [isActivating, setIsActivating] = useState(false);
  const [stepStatus, setStepStatus] = useState({
    status: "idle",
    step1: "current",
    step2: "upcoming",
    step3: "upcoming",
  });

  useEffect(() => {
    console.log("YEEEEY: ", state.streamStatus)
    if (state.streamStatus === "idle") {
      navigate("/app/stream/details")
    } else if (state.streamStatus === "active") {
      navigate("/app/stream/active")
    } else if (state.streamStatus === "ended") {
      navigate("/app/stream/customize")
    } else if (state.streamStatus === undefined || state.streamStatus === "canceled") {
      navigate("/app/stream/customize")
    }
  }, [state.streamStatus]);

  useEffect(() => {

    if (!apiVideoSDK) {
      const sdk = new PlayerSdk("#nuine-live-stream", {
        id: state.streamId,
        live: true,
        muted: true,
        hideControls: true,
        hideTitle: true,
        autoPlay: false,
      });
      setApiVideoSDK(sdk);
    }
  }, []);

  useEffect(() => {
    console.log("SDKKKK::: ", apiVideoSDK);
    if (apiVideoSDK) {
      apiVideoSDK.addEventListener('ready', function () {
        console.log('play event received');
        var video = document.getElementById('video2');
        if (hls.isSupported()) {
          let hLS = new hls()

          hLS.loadSource(state.streamHLS);
          console.log(hLS)
          hLS.attachMedia(video);

        } else {
          console.log("NOp")
        }

        console.log("NABBA")
        // Get the video resolution
        // I know, a hacky way to get the quality on stream in the player
        video.addEventListener("canplay", () => {
          console.log("YABBADABBA2222")
          console.log(video.videoWidth)
          console.log(video.videoHeight)
          const vs = ((video.videoWidth / video.videoHeight) / (16 / 9)) * 100
          setVideoSize(vs)
        })

        setTimeout(async () => {
          console.log(video.videoWidth)
          console.log(video.videoHeight)

          if (video.videoWidth !== 0) {

            await firebaseFunction("setVideoRatio", {
              brandId: state.brandId,
              streamId: state.streamId,
              videoRatio: video.videoWidth / video.videoHeight
            })



            let vs = (((video.videoWidth / video.videoHeight) / (16 / 9)) * 100) + 1
            setVideoSize(vs)
            setTimeout(() => {
              vs = (((video.videoWidth / video.videoHeight) / (16 / 9)) * 100)
              setVideoSize(vs)
            }, 1000)
          }
        }, 2000)
      });
    }
    /*if (apiVideoSDK && apiVideoSDK.iframe.clientHeight !== 0 && apiVideoSDK.iframe.clientWidth !== 0) {
      console.log("sdk H:: ", apiVideoSDK.iframe.clientHeight) //486x864
      console.log("sdk W:: ", apiVideoSDK.iframe.clientWidth)
      const vs = ((apiVideoSDK.iframe.clientWidth / apiVideoSDK.iframe.clientHeight) / (16 / 9)) * 100
      setVideoSize(vs)
    }*/
    console.log("hey")
    // if (hls.isSupported()) {
    //   console.log('hello hls.js!');
    //   console.log(state.streamHLS);
    //   let hLS = new hls()

    //   hLS.loadSource(state.streamHLS);
    //   console.log(hLS)
    //   console.log("Levels: ", hLS.levels)
    //   var video = document.getElementById('video2');
    //   console.log(video.videoWidth)
    //   console.log(video.videoHeight)
    //   hLS.attachMedia(video);
    //   console.log(video.videoWidth)
    //   console.log(video.videoHeight)
    //   setTimeout(() => {
    //     console.log(video.videoWidth)
    //     console.log(video.videoHeight)
    //   }, 4000)
    //   console.log("Levels2: ", hLS.levels)
    //   console.log(hLS)
    // } else {
    //   console.log("NOp")
    // }
  }, [apiVideoSDK])

  useEffect(() => {
    // var video = document.getElementById('video2');
    // if (hls.isSupported()) {
    //   let hLS = new hls()

    //   hLS.loadSource(state.streamHLS);
    //   console.log(hLS)
    //   hLS.attachMedia(video);

    // } else {
    //   console.log("NOp")
    // }

    // console.log("NABBA")
    // // Get the video resolution
    // // I know, a hacky way to get the quality on stream in the player
    // video.addEventListener("loadeddata", () => {
    //   console.log("YABBADABBA2222")
    //   console.log(video.videoWidth)
    //   console.log(video.videoHeight)
    //   const vs = ((video.videoWidth / video.videoHeight) / (16 / 9)) * 100
    //   setVideoSize(vs)
    // })

    // setTimeout(async () => {
    //   console.log(video.videoWidth)
    //   console.log(video.videoHeight)

    //   if (video.videoWidth !== 0) {

    //     await firebaseFunction("setVideoRatio", {
    //       brandId: state.brandId,
    //       streamId: state.streamId,
    //       videoRatio: video.videoWidth / video.videoHeight
    //     })



    //     let vs = (((video.videoWidth / video.videoHeight) / (16 / 9)) * 100) + 1
    //     setVideoSize(vs)
    //     setTimeout(() => {
    //       vs = (((video.videoWidth / video.videoHeight) / (16 / 9)) * 100)
    //       setVideoSize(vs)
    //     }, 1000)
    //   }
    // }, 6000)

  }, [])

  console.log("apiVideoSDK::: ", apiVideoSDK)

  const activateLiveStream = () => {
    console.log("TEST");
    setIsActivating(true);
    var activateLiveStream = firebase
      .functions()
      .httpsCallable("activateLiveStream");

    activateLiveStream({
      brandId: state.brandId,
      streamId: state.streamId,
    }).then((result) => {
      /*if (result.data.code === 200) {
        console.log("SUCCESS");
      }*/
      setIsActivating(false);
      console.log("RESULT: ", result);
    });
  };

  return (
    <DashboardLayout>
      <StreamSteps stepStatus={stepStatus} />
      <div
        className={
          stream.isStreaming
            ? "space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-gray-900 h-full"
            : "space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-gray-900"
        }
      >
        <div
          className={
            stream.isStreaming
              ? "cardContainerOuter h-full"
              : "cardContainerOuter"
          }
        >
          <div
            className={
              stream.isStreaming
                ? "cardContainerInner h-full"
                : "cardContainerInner"
            }
          >
            {streamError && (
              <div className="absolute z-10 rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    {/* Heroicon name: solid/information-circle */}
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-yellow-700">{`${streamError}`}</p>
                  </div>
                </div>
              </div>
            )}
            <video id="video2" className="hidden" />
            <div className="">

              <div className="flex flex-col items-center justify-center flex-grow">
                <div style={{ width: `${videoSize}%` }} className={`relative h-0 pb-562`}>
                  <div
                    id="nuine-live-stream"
                    className="absolute h-full w-full flex justify-center items-end top-0 left-0"
                  ></div>
                </div>

                <button
                  onClick={() => activateLiveStream()}
                  disabled={isActivating}
                  className="primary-button mt-6"
                >
                  {isActivating ? (
                    <svg
                      className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Publish stream to webpage"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(
  (state) => ({
    state: state.app,
    stream: state.app.stream,
  }),
  null
)(StreamPreview);
