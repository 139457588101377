import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { PlayerSdk } from "@api.video/player-sdk";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import DashboardLayout from "../components/dashboardLayout";
import { navigate } from "gatsby";

const LiveStreamDetails = ({ state, dispatch, props }) => {
  const [apiVideoSDK, setApiVideoSDK] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [buttonProcessingId, setButtonProcessingId] = useState(false);
  const [openOptionsMenu, setOpenOptionsMenu] = useState([]);
  const [toggleShowLiveStream, setToggleShowLiveStream] = useState(false);
  const [streamId, setStreamId] = useState("");

  useEffect(() => {
    //Gets the pathname from URL
    let pathname = window.location.pathname;
    //Extracts ID
    let streamId = pathname.split("live-streams/").pop();

    console.log("STREAM ID:::::: ", streamId)

    //Removes any slashes, if any
    streamId = streamId.replace(/\//g, "");

    setStreamId(streamId);

    if (!apiVideoSDK) {
      const sdk = new PlayerSdk("#nuine-live-stream", {
        id: streamId,
        live: true,
        muted: true,
        hideControls: true,
        hideTitle: true,
        autoPlay: false,
      });
      setApiVideoSDK(sdk);
    }
    console.log("Before moderation")
    initiateModeration(streamId)

    //listenForQuestions(streamId);
    //listenForViewers(streamId);
    //listenForSales(streamId);
  }, []);

  const initiateModeration = (streamId) => {
    var initiateModeration = firebase.functions().httpsCallable("initiateModeration");

    console.log("Before moderation 2")
    initiateModeration({
      streamId: streamId,
      brandId: state.brandId
    }).then((result) => {
      console.log("AFTER moderation 2")
      listenForQuestions(streamId);
      //listenForViewers(streamId);
      //listenForSales(streamId);
      console.log("X: ", result);
    });
  }

  const listenForQuestions = (streamId) => {
    //Listen for changes in the question sub collection
    let tempQuestionsArray = [];
    let tempArray = openOptionsMenu;
    console.log("streamId!!!!!!!!: ", streamId);

    firebase
      .firestore()
      .collection("questions")
      .doc(streamId)
      .collection("moderatorQuestions")
      .where("status", "==", "idle")
      .where("moderatorId", "==", state.userId)
      .orderBy("createdAt")
      .onSnapshot(
        function (snapshot) {
          snapshot.forEach(function (doc) {
            /*snapshot.docChanges().forEach(function (change) {
          if (change.type === 'added') {
            console.log('New city: ', change.doc.data())
          }
          if (change.type === 'modified') {
            console.log('Modified city: ', change.doc.data())
          }
          if (change.type === 'removed') {
            console.log('Removed city: ', change.doc.data())
          }
        })*/

            console.log("question:: ", doc.data());

            let newObject = doc.data();
            newObject.questionId = doc.id;
            newObject.menuOpen = false;

            tempQuestionsArray.push(newObject);

            tempArray.push(false);
            //newTodos[index].isCompleted = !newTodos[index].isCompleted;
          });
          setButtonProcessingId(false);
          setOpenOptionsMenu(tempArray);
          setQuestionList(tempQuestionsArray);

          tempQuestionsArray = [];
          tempArray = [];
        },
        (error) => {
          console.log("snapshot error: ", error);
        }
      );
  };

  //TODO: Set databaserule on Orders to avid user to access other records than he affect. Mov this to backend.

  const sendQuestionToStreamer = (
    questionDocumentId,
    questionIndex,
  ) => {
    setButtonProcessingId(questionIndex);

    var sendQuestionToStreamer = firebase
      .functions()
      .httpsCallable("sendQuestionToStreamer");

    sendQuestionToStreamer({
      questionId: questionDocumentId,
      brandId: state.brandId,
      streamId: streamId,
    }).then((result) => {
      console.log(result);
      setButtonProcessingId(false);
    });
  };

  const setQuestionStatus = (
    questionDocumentId,
    questionStatus,
    questionIndex,
    questionViwerId
  ) => {
    //openCloseOptionsMenu(questionIndex);
    setButtonProcessingId(questionIndex);
    var changeQuestionStatus = firebase
      .functions()
      .httpsCallable("changeQuestionStatus");

    changeQuestionStatus({
      questionId: questionDocumentId,
      newStatus: questionStatus,
      brandId: state.brandId,
      streamId: streamId,
      viewerId: questionViwerId,
    }).then((result) => {
      console.log(result);
      setButtonProcessingId(false);
    });
  };

  const banUser = (viewerId) => {
    console.log("BAN USER: ", viewerId);
    var banUser = firebase.functions().httpsCallable("banUser");

    banUser({
      viewerId: viewerId,
    }).then((result) => {
      console.log(result);
    });
  };

  const removeModerator = () => {
    var removeModerator = firebase
      .functions()
      .httpsCallable("removeModerator");

    removeModerator({
      brandId: state.brandId,
      streamId: streamId,
    }).then((result) => {
      console.log(result);
      navigate("/app/live-streams")
    });
  }

  const openCloseOptionsMenu = (index) => {
    const tempArray = questionList;
    const tempArrayAttribute = questionList[index].menuOpen;

    tempArray.map((i) => {
      i.menuOpen = false;
    });

    tempArray[index].menuOpen = !tempArrayAttribute;

    setQuestionList([...tempArray]);
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col">
        <div className="py-6 h-full rounded-md sm:overflow-hidden">
          <div className="flex flex-row justify-center flex-grow py-12 px-12">
            <div className="flex-none h-full w-3/6 pr-8">
              <div className="pb-5">
                <h2 className="mt-4">Questions</h2>
              </div>

              <ul className="-my-5 divide-y divide-gray-200 overflow-scroll h-full pb-96">
                {questionList.map((listItem, i) => {
                  return (
                    <li className="py-5" key={i}>
                      {/*<p className="text-sm mb-0.5">
                            {`User ID: ${listItem.viewerId}`}
                    </p>*/}
                      <p className="font-base text-gray-500 text-xs">
                        {new Date(listItem.createdAt * 1000).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </p>
                      <p className="text-base font-base mb-3">
                        {listItem.question}
                      </p>
                      {listItem.sentToStreamerAt &&
                        <span
                          style={{
                            display: "inline-flex",
                            paddingTop: "0.125rem",
                            paddingBottom: "0.125rem",
                            paddingLeft: "0.625rem",
                            paddingRight: "0.625rem",
                            backgroundColor: "#D1FAE5",
                            color: "#065F46",
                            fontSize: "0.85rem",
                            lineHeight: "1.25rem",
                            fontWeight: "400",
                            alignItems: "center",
                            borderRadius: "15px",
                          }}
                        >
                          Sent to streamer
                        </span>
                      }
                      <div className="flex justify-between mt-2">
                        <div>
                          {!listItem.sentToStreamerAt &&
                            <button
                              type="button"
                              onClick={() =>
                                sendQuestionToStreamer(
                                  listItem.questionId,
                                  i,
                                  listItem.viewerId
                                )
                              }
                              className="primary-button-sm mr-2"
                            >
                              {buttonProcessingId === i ? (
                                <svg
                                  className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : (
                                "Send to streamer"
                              )}
                            </button>
                          }
                          <button
                            type="button"
                            onClick={() =>
                              setQuestionStatus(
                                listItem.questionId,
                                "answered",
                                i,
                                listItem.viewerId
                              )
                            }
                            className="secondary-button-sm"
                          >
                            {buttonProcessingId === i ? (
                              <svg
                                className="animate-spin h-5 w-5 flex justify-center align-middle text-coral-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            ) : (
                              "Answered"
                            )}
                          </button>

                        </div>

                        <div className="relative inline-block text-left">
                          <div>
                            <button
                              type="button"
                              className="inline-flex items-center py-2 px-4 text-sm font-normal rounded-full border border-gray-300 text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 bg-white focus:ring-indigo-500"
                              id="options-menu"
                              aria-expanded="true"
                              aria-haspopup="true"
                              onClick={() => {
                                openCloseOptionsMenu(i);
                              }}
                            >
                              {buttonProcessingId === i ? (
                                <svg
                                  className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : (
                                <>
                                  Options
                                  <svg
                                    className="-mr-1 ml-2 h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </>
                              )}
                            </button>
                          </div>
                          <Transition
                            show={listItem.menuOpen}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            className="origin-top-right absolute right-0 mt-2 rounded-lg bg-white divide-y divide-gray-100 focus:outline-none z-10 w-64"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <div className="py-1" role="none">
                              <button
                                className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                role="menuitem"
                                onClick={() => {
                                  setQuestionStatus(
                                    listItem.questionId,
                                    "skipped",
                                    i,
                                    listItem.viewerId
                                  );
                                }}
                              >
                                <svg
                                  className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Skip question
                              </button>
                              <button
                                className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                role="menuitem"
                                onClick={() => {
                                  setQuestionStatus(
                                    listItem.questionId,
                                    "support",
                                    i,
                                    listItem.viewerId
                                  );
                                }}
                              >
                                <svg
                                  className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                  <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                                </svg>
                                Mark as support question
                              </button>
                            </div>
                            <div className="py-1" role="none">
                              <button
                                className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                role="menuitem"
                                onClick={() => {
                                  banUser(listItem.viewerId);
                                }}
                              >
                                <svg
                                  className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Ban user
                              </button>
                            </div>
                          </Transition>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={
                toggleShowLiveStream
                  ? "flex flex-col w-3/6"
                  : "flex justify-center flex-col w-3/6 h-14"
              }
            >
              <div
                className={
                  toggleShowLiveStream
                    ? "relative w-full pb-562 mb-5"
                    : "relative w-full p-0 mb-5"
                }
              >
                <div
                  id="nuine-live-stream"
                  className={
                    toggleShowLiveStream
                      ? "absolute h-full w-full flex justify-center items-end top-0 left-0"
                      : "hidden absolute h-full w-full justify-center items-end top-0 left-0"
                  }
                ></div>
              </div>
              <button
                className="secondary-button z-50"
                onClick={() => setToggleShowLiveStream(!toggleShowLiveStream)}
              >
                {toggleShowLiveStream ? "Hide livestream" : "Show livestream"}
              </button>
            </div>
          </div>
          <div className="pr-12 pb-4 text-right sm:pr-12 sm:pb-6">
            <button className="primary-button" onClick={() => removeModerator()}>
              Leave
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(LiveStreamDetails);