import React, { useEffect } from "react";
import { Link, navigate } from "gatsby";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";

const StreamEnded = ({ state, dispatch, props }) => {
  useEffect(() => {
    console.log("YEEEEY: ", state.streamStatus)
    if (state.streamStatus === "idle") {
      navigate("/app/stream/details")
    } else if (state.streamStatus === "preview") {
      navigate("/app/stream/preview")
    } else if (state.streamStatus === "active") {
      navigate("/app/stream/active")
    } else if (state.streamStatus === undefined || state.streamStatus === "canceled") {
      navigate("/app/stream/customize")
    }
  }, [state.streamStatus]);

  return (
    <DashboardLayout>

      <div className="py-12 px-12">
        <h2>Your stream has ended</h2>
        {/*<p>You successfully ended your stream</p>*/}
        <Link to="/app/stream/customize" className="primary-button mt-6">
          Start a new stream
        </Link>
        <Link to="/app/dashboard" className="secondary-button ml-2 mt-6">
          Go home
        </Link>
      </div>
    </DashboardLayout>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(StreamEnded);
