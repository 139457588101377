import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
//import stripe from 'stripe'
import { loadStripe } from "@stripe/stripe-js";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { connect } from "react-redux";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import { updateUserDetails } from "../state/app";

import { countries } from "../utilities/countries";
import { usStates } from "../utilities/usStates";
import { caStates } from "../utilities/caStates";

const PaymentAndBilling = ({ user, dispatch, props }) => {
  const [stripe, setStripe] = useState("");
  //const [stripeElements, setStripeElements] = useState('')
  const [stripeCard, setStripeCard] = useState("");
  const [isTalkingToStripe, setIsTalkingToStripe] = useState(false);
  const [stripeError, setStripeError] = useState(false);
  const [user2, setUser] = useState(user);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [country, setCountry] = useState("United States");
  const [state, setState] = useState("");

  console.log("PRRRRRRRRPX: ", props);

  useEffect(() => {
    if (user && stripeCard === "") {
      async function setupStripe() {
        let stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);

        setStripe(stripe);
        let elements = stripe.elements();
        //setStripeElements(elements)

        let card = elements.create("card", {
          style: { color: "blue", fontSize: "24px", width: "400px" },
        });
        setStripeCard(card);
        card.mount("#card-element");
        card.on("change", function (event) {
          displayError(event);
        });
        function displayError(event) {
          //changeLoadingStatePrices(false)

          //let displayError = document.getElementById('card-element-errors')
          if (event.error) {
            //displayError.textContent = event.error.message
            setStripeError(event.error.message);
          } else {
            //displayError.textContent = ''
            setStripeError(false);
          }
        }
      }
      setupStripe();
    }
  });

  const stripeSubscriptionDetails = async () => {
    const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
    console.log("Inne");
    var stripeCreateCheckoutSession = firebase
      .functions()
      .httpsCallable("stripeCreateCheckoutSession");

    stripeCreateCheckoutSession({
      priceId: "price_1IUyCcKMHpboZAw4u5QrFGDw",
    }).then((result) => {
      stripe
        .redirectToCheckout({
          sessionId: result.data.sessionId,
        })
        .then(console.log("sdfsdfds"));
    });
  };

  //////////////////NEW SUBSCRIPTION////////////////////

  function createStripeCustomer() {
    var createStripeCustomer = firebase
      .functions()
      .httpsCallable("createStripeCustomer");

    createStripeCustomer({
      email: user.email,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log("NEXT 5: ", result);
        // result.customer.id is used to map back to the customer object
        // result.setupIntent.client_secret is used to create the payment method
        return result;
      });
  }

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setIsTalkingToStripe(true);
    console.log("user.stripeCustomerId: ", user.stripeCustomerId);
    let test = await createPaymentMethod(
      stripeCard,
      user.stripeCustomerId,
      "price_1IVisQKMHpboZAw47OiOWLe3"
    );

    console.log("TEST: ", test);
  };

  const createPaymentMethod = (cardElement, customerId, priceId) => {
    return stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then((result) => {
        if (result.error) {
          //displayError(error)
          console.log("ERROR 1: ", result.error);
        } else {
          console.log("NEXT 1");
          createStripeSubscription(
            customerId,
            result.paymentMethod.id,
            priceId
          );
        }
      });
  };

  const createStripeSubscription = async (
    customerId,
    paymentMethodId,
    priceId
  ) => {
    console.log("customerId: ", customerId);
    var createStripeSubscription = firebase
      .functions()
      .httpsCallable("createStripeSubscription");

    return createStripeSubscription({
      customerId: customerId,
      paymentMethodId: paymentMethodId,
      priceId: priceId,
    })
      .then((response) => {
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.

        handlePaymentThatRequiresCustomerAction(
          response.data,
          paymentMethodId,
          priceId
        );

        return response;
      })
      .then((response) => onSubscriptionComplete(response.data, customerId))
      .catch((error) => {
        //DO SOMETHNG WITH ERROR
      });

    /*
        .then(response => {
          console.log('NEXT 2')
          console.log('NEXT 2: ', response)
          return response
        })
        // If the card is declined, display an error to the user.
        .then(result => {
          if (result.error) {
            console.log('ERROR 2: ', result.error)
            // The card had an error when trying to attach it to a customer.
            throw result
          }
          console.log('NEXT 3')
          console.log('NEXT 3: ', result)
          return result
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then(result => {
          console.log('NEXT 4')
          console.log(result)
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          }
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(result =>
          handlePaymentThatRequiresCustomerAction(
            result,
            paymentMethodId,
            priceId
          )
        ) //THIS COULD BE JUST result
        // No more actions required. Provision your service for the user.
        .then(result => {
          console.log('RESULT!!!: ', result)
          onSubscriptionComplete(result.subscription.subscription, customerId)
        }) //THIS COULD BE JUST result
        .catch(error => {
          console.log('ERROR 3: ', error)
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          //showCardError(error)
        })*/
  };

  const handlePaymentThatRequiresCustomerAction = (
    subscription,
    paymentMethodId,
    priceId
  ) => {
    let setupIntent = subscription.pending_setup_intent;

    console.log("NEXT 5");

    if (setupIntent && setupIntent.status === "requires_action") {
      return stripe
        .confirmCardSetup(setupIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.setupIntent.status === "succeeded") {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to setup_intent.succeeded.
              return {
                priceId: priceId,
                subscription: subscription,
                //invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  };

  const onSubscriptionComplete = (subscription, stripeCustomerId) => {
    // Payment was successful.
    console.log("NEXT 6: ", subscription);
    //setIsTalkingToStripe(true);

    if (subscription.status === "active") {
      console.log("NEXT 7 ACTIVE");
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      var addSubscriptionToBrand = firebase
        .functions()
        .httpsCallable("addSubscriptionToBrand");

      addSubscriptionToBrand({
        subscriptionId: subscription.id,
        subscriptionStatus: subscription.status,
        stripeCustomerId: stripeCustomerId,
        subscriptionListId: subscription.items.data[0].id,
        subscriptionProduct: subscription.items.data[0].price.product,
        brandId: user.representsBrandId,
      }).then((response) => {
        console.log("NEXT 8: ", response);
        /*dispatch(
          updateUserDetails({
            subscriptionStatus: "active",
            subscriptionId: subscription.id,
          })
        );*/
        setIsTalkingToStripe(false);
        return response;
      });

      // `subscription.items.data[0].price.product` the customer subscribed to.
    }
  };

  const cancelSubscription = () => {
    setIsTalkingToStripe(true);
    var cancelSubscription = firebase
      .functions()
      .httpsCallable("cancelSubscription");

    cancelSubscription({
      brandId: user.representsBrandId,
      subscriptionId: user.subscriptionId,
    }).then((response) => {
      console.log("Cancel subscription: ", response);
      /*dispatch(
        updateUserDetails({
          subscriptionStatus: "canceled",
        })
      );*/
      setIsTalkingToStripe(false);
      return response;
    });
  };

  console.log("country: ", country);

  return (
    <div>
      {user.role === "admin" && (
        <div>
          <div>
            <p className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Billing and payment details
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500">
              The billing details will be stated on the invoice
            </p>
          </div>
          {user.subscriptionStatus === "trial" ||
            user.subscriptionStatus === "canceled" ? (
            <div>
              <div className="py-5">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    companyName: "",
                    taxIdNumber: "",
                    country: "United States",
                    state: "",
                    addressLine1: "",
                    addressLine2: "",
                    postalCode: "",
                    city: "",
                  }}
                  validationSchema={Yup.object({
                    firstName: Yup.string().required("Required"),
                    lastName: Yup.string().required("Required"),
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Required"),
                    companyName: Yup.string().required("Required"),
                    country: Yup.string().required("Required"),
                    addressLine1: Yup.string().required("Required"),
                    postalCode: Yup.string().required("Required"),
                    city: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    handleSubscribe();
                    setFormSubmitting(true);
                    setSubmitting(false);
                    setFormSubmitting(false);
                    resetForm();
                  }}
                >
                  {({ errors, touched, setFieldValue, isValidating }) => (
                    <Form>
                      <div className="text-base space-y-2">
                        <h3 className="my-2 max-w-2xl text-lg font-bold text-gray-800">
                          Billing address
                        </h3>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="firstName"
                            >
                              First name
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                type="text"
                                name="firstName"
                                autoComplete="given-name"
                                placeholder="Buzz"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.firstName && touched.firstName
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="firstName">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="lastName"
                            >
                              Last name
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                type="text"
                                name="lastName"
                                autoComplete="family-name"
                                required
                                placeholder="Lightyear"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.lastName && touched.lastName
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="lastName">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-6">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="email"
                            >
                              Email Address
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                name="email"
                                placeholder="buzz@lightyear.com"
                                type="email"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.email && touched.email
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="email">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="companyName"
                            >
                              Company name
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                type="text"
                                name="companyName"
                                autoComplete="organization"
                                placeholder="Moonshot company"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.companyName && touched.companyName
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="companyName">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="taxIdNumber"
                            >
                              Tax ID #
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                type="text"
                                name="taxIdNumber"
                                autoComplete=""
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.taxIdNumber && touched.taxIdNumber
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="taxIdNumber">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div
                            className={`${country === "United States" ||
                              country === "Canada"
                              ? "col-span-6 sm:col-span-3"
                              : "col-span-6 sm:col-span-6"
                              }`}
                          >
                            <label
                              htmlFor="country"
                              className="block text-sm  mb-2 font-medium text-gray-700"
                            >
                              Country
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                id="country"
                                name="country"
                                as="select"
                                className={`${errors.country && touched.country
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                                /*onChange={(e) => {
                                  
                                }}*/
                                onChange={(e) => {
                                  console.log(
                                    "e.target.value: ",
                                    e.target.value
                                  );
                                  setCountry(e.target.value);
                                  setFieldValue("country", e.target.value);
                                }}
                              >
                                <option value="None">Select country</option>
                                {countries.map((country, i) => {
                                  return (
                                    <option value={country.name} key={i}>
                                      {country.name}
                                    </option>
                                  );
                                })}
                                {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                              </Field>
                            </div>
                            <ErrorMessage name="country">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          {(country === "United States" ||
                            country === "Canada") && (
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="state"
                                  className="block text-sm  mb-2 font-medium text-gray-700"
                                >
                                  State
                                </label>
                                <div className="relative rounded-md">
                                  <Field
                                    id="state"
                                    name="state"
                                    as="select"
                                    className={`${errors.state && touched.state
                                      ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                      : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                      } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                                    onChange={(e) => {
                                      setState(e.target.value);
                                    }}
                                  >
                                    <option value="None">Select state</option>
                                    {country === "United States"
                                      ? usStates.map((state, i) => {
                                        return (
                                          <option value={state.name} key={i}>
                                            {state.name}
                                          </option>
                                        );
                                      })
                                      : caStates.map((state, i) => {
                                        return (
                                          <option value={state.name} key={i}>
                                            {state.name}
                                          </option>
                                        );
                                      })}
                                    {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                                  </Field>
                                </div>
                                <ErrorMessage name="country">
                                  {(error) => (
                                    <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                      {error}
                                    </span>
                                  )}
                                </ErrorMessage>
                              </div>
                            )}

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="addressLine1"
                            >
                              Address line 1
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                type="text"
                                name="addressLine1"
                                autoComplete="address-line1"
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.addressLine1 && touched.addressLine1
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="addressLine1">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="addressLine2"
                            >
                              Address line 2
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                type="text"
                                name="addressLine2"
                                autoComplete="address-line2"
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.addressLine2 && touched.addressLine2
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="addressLine2">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="postalCode"
                            >
                              Postal code
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                type="text"
                                name="postalCode"
                                autoComplete="postal-code"
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.postalCode && touched.postalCode
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="postalCode">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="city"
                            >
                              City
                            </label>
                            <div className="relative rounded-md">
                              <Field
                                type="text"
                                name="city"
                                autoComplete="city"
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.city && touched.city
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 rounded-md placeholder-gray-400 focus:outline-none sm:text-sm`}
                              />
                            </div>
                            <ErrorMessage name="city">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div
                            className="col-span-6 mt-4"
                            style={{ marginBottom: "-15px" }}
                          >
                            <h3 className="text-lg font-bold text-gray-800">
                              Payment
                            </h3>
                          </div>
                          <div className="col-span-6 sm:col-span-3 mb-8">
                            <label
                              className="block text-sm  mb-2 font-medium text-gray-700"
                              htmlFor="city"
                            >
                              Credit card
                            </label>

                            <div
                              id="card-element"
                              className={`${stripeError
                                ? "border-red-800"
                                : "border-gray-300"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                            ></div>
                            {stripeError && (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {stripeError}
                              </span>
                            )}
                          </div>

                          {/*<button type="submit">Subscribe</button> */}
                        </div>
                        <button
                          type="submit"
                          //disabled={formSubmitting}
                          disabled={isTalkingToStripe}
                          className="flex items-center justify-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {isTalkingToStripe ? (
                            <svg
                              className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            "Subscribe"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Plan and payment details
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    You are on the Beta plan
                  </p>
                </div>
              </div>

              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  onClick={() => cancelSubscription()}
                  className="disabled:opacity-50 bg-indigo-600 border border-transparent rounded-md py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled={isTalkingToStripe}
                >
                  {isTalkingToStripe ? (
                    <svg
                      className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "End subscription"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(
  (state, props) => ({
    user: state.app.user,
    props: props,
  }),
  null
)(PaymentAndBilling);
