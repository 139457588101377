import React from "react";
import { Link } from "gatsby";

import { connect } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SettingsMenu = ({ state, dispatch, props }) => {
  console.log("PROOOOOPS: ", props);
  console.log("PROOOOOPS: ", props.page === "general");

  let tabs = [
    {
      name: "General",
      href: "/app/settings/general",
      current: props.page === "general",
    },
  ];

  if (state.userRole === "admin") {
    tabs = [
      {
        name: "General",
        href: "/app/settings/general",
        current: props.page === "general",
      },
      {
        name: "Password",
        href: "/app/settings/password",
        current: props.page === "password",
      },
      {
        name: "Widget",
        href: "/app/settings/widget",
        current: props.page === "widget",
      },
      //{
      //  name: "Team",
      //  href: "/app/settings/team",
      //  current: props.page === "team",
      //},
      {
        name: "Plan",
        href: "/app/settings/plan",
        current: props.page === "plan",
      },
      {
        name: "Billing",
        href: "/app/settings/billing",
        current: props.page === "billing",
      },
    ];
  } else if (state.userRole === "manager") {
    tabs = [
      {
        name: "General",
        href: "/app/settings/general",
        current: props.page === "general",
      },
      {
        name: "Password",
        href: "/app/settings/password",
        current: props.page === "password",
      },
      {
        name: "Widget",
        href: "/app/settings/widget",
        current: props.page === "widget",
      },
      //{
      //  name: "Team",
      //  href: "/app/settings/team",
      //  current: props.page === "team",
      //},
    ];
  } else if (state.userRole === "advocate" || state.userRole === "sales-rep") {
    tabs = [
      {
        name: "General",
        href: "/app/settings/general",
        current: props.page === "general",
      },
      {
        name: "Password",
        href: "/app/settings/password",
        current: props.page === "password",
      },
    ];
  }

  return (
    <div className="">
      {/* Tabs */}
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-violet-500 focus:border-violet-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? "border-black text-black" //"border-violet-500 text-violet-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-base"
                )}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(SettingsMenu);
