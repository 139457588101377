import React, { useState } from "react";
import { Transition } from "@headlessui/react";

const AreYouSureModal = (props) => {
  const [modalClosed, setModalClosed] = useState(true);

  return (
    <div>
      <div
        className={`${
          modalClosed && "hidden"
        } fixed z-10 inset-0 overflow-y-auto`}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition
            show={props.modalOpen}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 transition-opacity"
            aria-hidden="true"
            beforeEnter={() => {
              setModalClosed(false);
            }}
            afterLeave={() => {
              setModalClosed(true);
            }}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </Transition>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          ></span>
          <Transition
            show={props.modalOpen}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5 mb-12">
                <p id="modal-headline">
                  Are you sure you want to end the stream?
                </p>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:flex sm:flex-row sm:space-x-3">
              <button
                type="button"
                onClick={props.end}
                className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:col-start-2 sm:text-sm"
              >
                Yes, end the stream
              </button>
              <button
                type="button"
                onClick={props.notEnd}
                className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              >
                No, take me back
              </button>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default AreYouSureModal;
