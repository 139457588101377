import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { PlayerSdk } from "@api.video/player-sdk";
import { InformationCircleIcon, ThumbUpIcon, UserGroupIcon, ShoppingBagIcon, PaperAirplaneIcon } from "@heroicons/react/solid";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import { navigate } from "gatsby";

import SelectMenuWithCheck from "../components/selectMenuWithCheck";

import DashboardLayout from "../components/dashboardLayout";
import StreamSteps from "../components/streamSteps";

import { addInitialData } from "../state/app";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const StreamActive = ({ state, dispatch, props }) => {
  const [apiVideoSDK, setApiVideoSDK] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [buttonProcessingId, setButtonProcessingId] = useState(false);
  const [numbersOfCurrentViewers, setNumbersOfCurrentViewers] = useState(0);
  const [salesGenerated, setSalesGenerated] = useState(0);
  const [reactions, setReactions] = useState(0);
  const [openOptionsMenu, setOpenOptionsMenu] = useState([]);
  const [toggleShowLiveStream, setToggleShowLiveStream] = useState(false);
  const [listOfTeamMembers, setListOfTeamMembers] = useState(false);
  const [moderator, setModerator] = useState(false)
  const [stepStatus] = useState({
    status: "idle",
    step1: "current",
    step2: "upcoming",
    step3: "upcoming",
  });
  //const [previousModeratorState, setPreviousModeratorState] = useState(false)
  //const [unsubscribeToQuestionsListener, setUnsubscribeToQuestionsListener] = useState()
  //let unsubscribeToQuestionsListener

  useEffect(() => {
    console.log("YEEEEY: ", state.streamStatus)
    if (state.streamStatus === "idle") {
      navigate("/app/stream/details")
    } else if (state.streamStatus === "preview") {
      navigate("/app/stream/preview")
    } else if (state.streamStatus === "ended") {
      navigate("/app/stream/ended")
    } else if (state.streamStatus === undefined || state.streamStatus === "canceled") {
      navigate("/app/stream/customize")
    }
  }, [state.streamStatus]);

  useEffect(() => {
    console.log("state.streamId!!!!!! ", state.streamId)
    if (state.streamId) {
      if (!apiVideoSDK) {
        const sdk = new PlayerSdk("#nuine-live-stream", {
          id: state.streamId,
          live: true,
          muted: true,
          hideControls: true,
          hideTitle: true,
          autoPlay: false,
        });
        setApiVideoSDK(sdk);
      }

      var getListOfTeamMembers = firebase
        .functions()
        .httpsCallable("getListOfTeamMembers");

      getListOfTeamMembers({
        brandId: state.brandId,
      }).then((result) => {
        setListOfTeamMembers(result.data);
        console.log("LIST OF TEAM MEMEBERS: ", result.data);
        /*if (result.data.code === 200) {
        console.log("SUCCESS");
      }*/

        console.log("RESULT: ", result);
      });

      //Listen for changes in the question sub collection
      let tempQuestionsArray = [];
      let tempArray = openOptionsMenu;

      let unsubscribeListenForQuestions = firebase
        .firestore()
        .collection("questions")
        .doc(state.streamId)
        .collection("moderatorQuestions")
        .where("status", "==", "idle")
        .where("streamUserId", "==", state.uid)
        .orderBy("orderByThisDate")
        .onSnapshot(
          function (snapshot) {
            snapshot.forEach(function (doc) {
              /*snapshot.docChanges().forEach(function (change) {
            if (change.type === 'added') {
              console.log('New city: ', change.doc.data())
            }
            if (change.type === 'modified') {
              console.log('Modified city: ', change.doc.data())
            }
            if (change.type === 'removed') {
              console.log('Removed city: ', change.doc.data())
            }
          })*/

              console.log("question:: ", doc.data());
              console.log("ONE:: ", moderator);

              if (doc.data().moderatorId) {
                console.log("TWO:: ", doc.data().sentToStreamerAt);
                if (doc.data().sentToStreamerAt) {
                  console.log("THREE:: ");
                  let newObject = doc.data();
                  newObject.questionId = doc.id;
                  newObject.menuOpen = false;

                  tempQuestionsArray.push(newObject);

                  tempArray.push(false);
                  //newTodos[index].isCompleted = !newTodos[index].isCompleted;
                }
                console.log("FOUR:: ");
              } else {
                console.log("FIVE:: ");
                let newObject = doc.data();
                newObject.questionId = doc.id;
                newObject.menuOpen = false;

                tempQuestionsArray.push(newObject);

                tempArray.push(false);
                //newTodos[index].isCompleted = !newTodos[index].isCompleted;
              }
              console.log("SIX:: ");


            });
            console.log("SEVEN:: ");
            setButtonProcessingId(false);
            setOpenOptionsMenu(tempArray);
            setQuestionList(tempQuestionsArray);

            tempQuestionsArray = [];
            tempArray = [];
          },
          (error) => {
            console.log("snapshot error: ", error);
          }
        );

      //Listen for viewers
      let numbersOfCurrentViewers = 0;
      let unsubscribeListenForViewers = firebase
        .firestore()
        .collection("views")
        .where("streamId", "==", state.streamId)
        .where("userId", "==", state.uid)
        .onSnapshot(function (snapshot) {
          console.log("inne");
          numbersOfCurrentViewers = 0;
          snapshot.forEach(function (doc) {
            if (doc.data().endTime === null) {
              numbersOfCurrentViewers++;
              console.log("more");
              /*} else {
              numbersOfCurrentViewers--;
              console.log("less");
   
              if (numbersOfCurrentViewers < 0) {
                numbersOfCurrentViewers = 0;
              }
            */
            }
            if (numbersOfCurrentViewers < 0) {
              numbersOfCurrentViewers = 0;
            }
          });
          console.log("cv: ", numbersOfCurrentViewers);
          setNumbersOfCurrentViewers(numbersOfCurrentViewers);
        });

      //TODO: Set databaserule on Orders to avid user to access other records than he affect. Mov this to backend.

      let salesGenerated = 0;
      let unsubscribeListenForSales = firebase
        .firestore()
        .collection("orders")
        .where("attributedToStreamId", "==", state.streamId)
        .where("attributedToUserId", "==", state.userId)
        .onSnapshot(function (snapshot) {
          console.log("inne sales");
          salesGenerated = 0;
          snapshot.forEach(function (doc) {
            salesGenerated++;
            console.log("salesGenerated: ", salesGenerated)
          });
          console.log("cv sales: ", salesGenerated);
          setSalesGenerated(salesGenerated);
        });

      //Listen for moderators
      let unsubscribeListenForModerators = firebase
        .firestore()
        .collection("streams")
        .doc(state.streamId)
        .collection("privateDetails")
        .doc(state.streamId)
        .onSnapshot((doc) => {
          console.log("inne moderator");
          console.log("MODERATIONS!!!! ", doc.data())
          if (doc.data().moderatorId) {
            if (doc.data().moderatorAcceptedAt) {
              console.log("inne moderatorId: true");
              //unsubscribeToQuestionsListener()
              let moderatorName = doc.data().moderatorFirstName + " " + doc.data().moderatorLastName
              //setPreviousModeratorState(moderator)
              dispatch(
                addInitialData({
                  notification: { showNotification: true, type: "success", header: "Moderator accepted", message: `${moderatorName} is now moderating incoming questions` }
                })
              );
              setModerator(moderatorName)
            }
          } else {
            console.log("inne moderatorId: false");
            //TODO: Display that Moderator left the stream
            setModerator(false)
          }

        });

      //Listen for reactions

      console.log("listenForReactions: XXX")
      let unsubscribeListenForReactions = firebase
        .firestore()
        .collection("streams")
        .doc(state.streamId)
        .collection("privateDetails")
        .doc(state.streamId)
        .collection("reactions")
        .where("streamId", "==", state.streamId)
        .onSnapshot(function (snapshot) {
          console.log("inne sales");
          //likes = 0;
          let tempReactions = 0
          snapshot.forEach(function (doc) {
            //console.log(change.type)
            /*if (change.type === 'added') {
              console.log('New: ', change.doc.data())
              console.log('Reaction type: ', change.doc.data().reactionType)
            }*/
            tempReactions++
          });
          setReactions(tempReactions);

        })


      return () => {
        unsubscribeListenForQuestions()
        unsubscribeListenForViewers()
        unsubscribeListenForSales()
        unsubscribeListenForModerators()
        unsubscribeListenForReactions()
      }
    }
  }, []);

  console.log("moderatorId::::: ", moderator)

  const removeModerator = () => {
    var removeModerator = firebase
      .functions()
      .httpsCallable("removeModerator");

    removeModerator({
      brandId: state.brandId,
      streamId: state.streamId,
    }).then((result) => {
      console.log(result);
      //unsubscribeToQuestionsListener()
      //setPreviousModeratorState(moderator)
      setModerator(false)
    });
  }


  const answerQuestionWithText = (questionDocumentId, questionIndex, questionViwerId, answer) => {

    setButtonProcessingId(questionIndex);

    var answerQuestionWithText = firebase
      .functions()
      .httpsCallable("answerQuestionWithText");

    answerQuestionWithText({
      questionId: questionDocumentId,
      brandId: state.brandId,
      streamId: state.streamId,
      viewerId: questionViwerId,
      answer: answer
    }).then((result) => {
      console.log(result);
      setButtonProcessingId(false);
    });
  }

  const setQuestionStatus = (
    questionDocumentId,
    questionStatus,
    questionIndex,
    questionViwerId
  ) => {
    //openCloseOptionsMenu(questionIndex);
    setButtonProcessingId(questionIndex);
    var changeQuestionStatus = firebase
      .functions()
      .httpsCallable("changeQuestionStatus");

    changeQuestionStatus({
      questionId: questionDocumentId,
      newStatus: questionStatus,
      brandId: state.brandId,
      streamId: state.streamId,
      viewerId: questionViwerId,
    }).then((result) => {
      console.log(result);
      setButtonProcessingId(false);
    });
  };

  const banUser = (viewerId) => {
    console.log("BAN USER: ", viewerId);
    var banUser = firebase.functions().httpsCallable("banUser");

    banUser({
      viewerId: viewerId,
    }).then((result) => {
      console.log(result);
    });
  };

  const openCloseOptionsMenu = (index) => {
    const tempArray = questionList;
    const tempArrayAttribute = questionList[index].menuOpen;

    tempArray.map((i) => {
      i.menuOpen = false;
    });

    tempArray[index].menuOpen = !tempArrayAttribute;

    setQuestionList([...tempArray]);
  };

  return (
    <DashboardLayout>
      <StreamSteps stepStatus={stepStatus} />
      <div className="h-full bg-whiteish z-50 relative">
        <div className="rounded-md bg-gray-100 p-4">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-gray-700"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="mx-px text-sm text-gray-700">
                When you are done streaming, just stop the stream in OBS
              </p>
            </div>
          </div>
        </div>
        {moderator &&
          <div className="rounded-md bg-gray-50 p-4 mt-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-gray-700">{`${moderator} is moderating the questions`}</p>
                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                  <button className="whitespace-nowrap font-medium text-gray-700 hover:text-gray-600" onClick={() => removeModerator()}>Remove moderator</button>
                </p>
              </div>
            </div>
          </div>
        }

        <div className="flex w-full place-content-between mt-6">
          <div className="flex justify-center min-w-[80px]">
            <UserGroupIcon className="h-7 w-7 text-black" />
            <p className="pl-1 pt-1 ml-0">{numbersOfCurrentViewers}</p>
          </div>
          <div className="flex justify-center min-w-[80px]">
            <ThumbUpIcon className="h-7 w-7 text-black" />
            <p className="pl-1 pt-1 ml-0">{reactions}</p>
          </div>
          <div className="flex justify-center">
            <ShoppingBagIcon className="h-7 w-7 text-black" />
            <p className="pl-1 pt-1 ml-0">{salesGenerated}</p>
          </div>
        </div>


        <div className="py-6">
          <div className="flex flex-row justify-center flex-grow">
            <div className="flex-none w-3/6 pr-8">
              <div className="pb-5">
                <h2 className="leading-6">Questions</h2>
              </div>

              <ul className="-my-5 divide-y divide-gray-200 overflow-scroll h-[75vh]">
                {questionList.map((listItem, i) => {
                  return (
                    <li className="py-5" key={i}>
                      {/*<p className="text-sm mb-0.5">
                            {`User ID: ${listItem.viewerId}`}
                    </p>*/}
                      <p className="font-base text-gray-500 text-xs">
                        {new Date(listItem.orderByThisDate * 1000).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </p>
                      <p className="text-base font-base mb-2">
                        {listItem.question}
                      </p>












                      <div className="pb-2">
                        <Formik
                          initialValues={{
                            answer: "",
                          }}
                          validationSchema={Yup.object({
                            answer: Yup.string().required("Required"),
                          })}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            //setFormSubmitting(true);
                            console.log("values.answer::: ", values.answer);
                            answerQuestionWithText(listItem.questionId, i, listItem.viewerId, values.answer)


                            //setSubmitting(false);

                            //setFormSubmitting(false);
                            resetForm();
                          }}
                        >
                          {({ errors, touched, setFieldValue, isValidating }) => (
                            <Form className="relative">



                              <div className="relative rounded-md mt-1">
                                <Field
                                  type="text"
                                  name="answer"
                                  placeholder="(Optional) Reply with text here..."
                                  //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                  className={`${errors.answer && touched.answer
                                    ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                    : ""
                                    } border border-gray-300 focus:ring-0 focus:border-black appearance-none block w-full px-3 py-2 placeholder-gray-400 focus:outline-none pr-12 rounded-full text-sm`}
                                />
                              </div>
                              <ErrorMessage name="answer">
                                {(error) => (
                                  <span className="inline-flex items-center px-2 py-0.5 mt-2 ml-5 rounded text-xs font-medium bg-red-100 text-red-800">
                                    {error}
                                  </span>
                                )}
                              </ErrorMessage>

                              {/*<button type="submit">Subscribe</button> */}

                              <div className="absolute top-[4px] right-3 rotate-90 ">
                                <button type="submit" className="text-coral-500 hover:text-coral-700 h-6 w-6">
                                  {buttonProcessingId === i ? (
                                    <svg
                                      className="animate-spin h-6 w-6 flex justify-center align-middle text-coral-500"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                  ) : (
                                    <PaperAirplaneIcon />
                                  )}

                                </button>
                              </div>

                            </Form>
                          )}
                        </Formik>
                      </div>
















                      <div className="flex justify-between mt-2">
                        <button
                          type="button"
                          onClick={() =>
                            setQuestionStatus(
                              listItem.questionId,
                              "answered",
                              i,
                              listItem.viewerId
                            )
                          }
                          className="primary-button-sm"
                        >
                          {buttonProcessingId === i ? (
                            <svg
                              className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            "Answered"
                          )}
                        </button>

                        <div className="relative inline-block text-left">
                          <div>
                            <button
                              type="button"
                              className="inline-flex items-center py-2 px-4 text-sm font-normal rounded-full border border-gray-300 text-gray-900 hover:bg-gray-50 focus:outline-none bg-white"
                              id="options-menu"
                              aria-expanded="true"
                              aria-haspopup="true"
                              onClick={() => {
                                openCloseOptionsMenu(i);
                              }}
                            >
                              {buttonProcessingId === i ? (
                                <svg
                                  className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : (
                                <>
                                  Options
                                  <svg
                                    className="-mr-1 ml-2 h-5 w-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </>
                              )}
                            </button>
                          </div>
                          <Transition
                            show={listItem.menuOpen}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                            className="origin-top-right absolute right-0 mt-2 rounded-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10 w-64"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                          >
                            <div className="py-1" role="none">
                              <button
                                className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                role="menuitem"
                                onClick={() => {
                                  setQuestionStatus(
                                    listItem.questionId,
                                    "skipped",
                                    i,
                                    listItem.viewerId
                                  );
                                }}
                              >
                                <svg
                                  className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Skip question
                              </button>
                              <button
                                className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                role="menuitem"
                                onClick={() => {
                                  setQuestionStatus(
                                    listItem.questionId,
                                    "support",
                                    i,
                                    listItem.viewerId
                                  );
                                }}
                              >
                                <svg
                                  className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                  <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                                </svg>
                                Mark as support question
                              </button>
                            </div>
                            <div className="py-1" role="none">
                              <button
                                className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                role="menuitem"
                                onClick={() => {
                                  banUser(listItem.viewerId);
                                }}
                              >
                                <svg
                                  className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Ban user
                              </button>
                            </div>
                          </Transition>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="w-full">
              {(listOfTeamMembers && !moderator) &&
                <div className="pb-4">
                  <span className="block text-sm font-normal text-gray-700 pb-2">
                    Ask a team member to moderate the questions:
                  </span>

                  <SelectMenuWithCheck
                    brandId={state.brandId}
                    streamId={state.streamId}
                    listOfTeamMembers={listOfTeamMembers}
                    brandName={state.brandName}
                  />
                </div>
              }
              <div
                className={
                  toggleShowLiveStream
                    ? "flex flex-col border-t pt-4 items-center"
                    : "flex justify-center flex-col h-10"
                }
              >
                <div
                  style={{ width: `${(state.videoRatio / (16 / 9) * 100)}%` }}
                  className={
                    toggleShowLiveStream
                      ? "relative w-full pb-562 mb-5"
                      : "relative w-full p-0"
                  }
                >
                  <div
                    id="nuine-live-stream"
                    className={
                      toggleShowLiveStream
                        ? "absolute h-full w-full flex justify-center items-end top-0 left-0"
                        : "hidden absolute h-full w-full justify-center items-end top-0 left-0"
                    }
                  ></div>
                </div>
                <button
                  className="secondary-button-sm w-full"
                  onClick={() => setToggleShowLiveStream(!toggleShowLiveStream)}
                >
                  {toggleShowLiveStream ? "Hide livestream" : "Show livestream"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(StreamActive);
