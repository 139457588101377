import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import { connect } from "react-redux";
import { navigate } from "gatsby";

const SalesrepAndAdvocateDashboard = ({ user }) => {
  useEffect(() => {
    getAdvocateOverviewStats();
    //getNumberOfSales();
    //getTimeStreamedByStreamer();
    //getQuestionsAnswered();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [numberOfSales, setNumberOfSales] = useState("-");
  const [questionAnswered, setQuestionAnswered] = useState("-");
  const [timeStreamed, setTimeStreamed] = useState("-");
  const [numberOfStreams, setNumberOfStreams] = useState("-");

  console.log("USERRRR: ", user);
  const getAdvocateOverviewStats = () => {
    let getAdvocateOverviewStats = firebase
      .functions()
      .httpsCallable("getAdvocateOverviewStats");

    getAdvocateOverviewStats({ brandId: user.representsBrandId }).then(
      (result) => {
        console.log("Advocate stats res: ", result);
        setNumberOfSales(result.data.numberOfSales);
        setQuestionAnswered(result.data.questionsAnswered);
        setTimeStreamed(result.data.timeStreamed);
        setNumberOfStreams(result.data.numberOfStreams);
      }
    );
  };

  /*
  const getNumberOfSales = () => { 
    let getNumberOfSales = firebase
      .functions()
      .httpsCallable("getNumberOfSales");

    getNumberOfSales().then((result) => {
      setNumberOfSales(result.data);
    });
  };

  const getQuestionsAnswered = () => {
    console.log("Question !!!!! ", user.representsBrandId);
    let getQuestionsAnswered = firebase
      .functions()
      .httpsCallable("getQuestionsAnswered");

    getQuestionsAnswered({
      brandId: user.representsBrandId,
    }).then((result) => {
      console.log("Question DATA!!!!! ", result.data);
      setQuestionAnswered(result.data.data.questionsAnswered);
    });
  };

  const getTimeStreamedByStreamer = () => {
    let getTimeStreamedByStreamer = firebase
      .functions()
      .httpsCallable("getTimeStreamedByStreamer");

    getTimeStreamedByStreamer({
      brandId: user.representsBrandId,
    }).then((result) => {
      console.log("TOTAL TIME STREAMED!! ", result.data);
      setTimeStreamed(result.data);
    });
  };
  */

  const secondsToHms = (seconds) => {
    if (seconds > 0) {
      seconds = Number(seconds);
      var h = Math.floor(seconds / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor((seconds % 3600) % 60);
      /*
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    */
      var hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
      var mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
      var sDisplay = s > 0 ? s + (s === 1 ? "s " : "s ") : "";

      return hDisplay + mDisplay + sDisplay;
    } else {
      return "0h 0m 0s";
    }
  };

  const stats = [
    {
      name: "Sales generated",
      stat: numberOfSales,
      currency: "",
    },
    {
      name: "Question answered",
      stat: questionAnswered,
      currency: "",
    },
    {
      name: "Number of streams",
      stat: numberOfStreams,
      currency: "",
    },
    {
      name: "Total time streamed",
      stat: secondsToHms(timeStreamed),
      currency: "",
    },
    {
      name: "Avg. stream time",
      stat: secondsToHms(timeStreamed / numberOfStreams),
      currency: "",
    },
    {
      name: "Avg. sales per stream",
      stat: (numberOfSales / numberOfStreams).toFixed(2),
      currency: "",
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="bg-indigo-100 py-12 px-12 mb-8 rounded-md">
        <div>
          <div>
            <h2>Start a stream</h2>
            <div className="flex flex-col">
              <p className="pt-2">
                Start a live stream, answer visitors of the ecommerce store
                questions and get rewarded for each sale you generate.
              </p>
              <button
                type="button"
                onClick={() => navigate("/app/stream")}
                className="w-36 mt-6 flex justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {/* Heroicon name: solid/mail */}
                <svg
                  className="-ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                </svg>
                Start stream
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-12 px-12 rounded-md">
        <div>
          <div>
            <h2>Your performance</h2>
            <div>
              <p className="mt-2 font-medium">Lifetime</p>
              <dl className="mt-5 grid grid-cols-1 bg-white overflow-hidden md:grid-cols-2">
                {stats.map((item) => (
                  <div key={item.name} className="px-4 py-5 sm:py-6 sm:px-0">
                    <dt className="text-base font-normal text-gray-900">
                      {item.name}
                    </dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                      <div className="flex items-baseline text-2xl font-semibold text-black">
                        {item.currency}
                        {item.stat}
                      </div>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    user: state.app.user,
  }),
  null
)(SalesrepAndAdvocateDashboard);
