import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Transition } from "@headlessui/react";

import firebase from "firebase/app";

import "firebase/functions";
import "firebase/auth";

import DashboardLayout from "../components/dashboardLayout";
import AreYouSureModal from "../components/areYouSureModal";

import { addStreamDetails } from "../state/app";

import { io } from "socket.io-client";

//const socket = io("http://localhost:3036");

const Stream = ({ user, dispatch }) => {
  const [rmtpURL, setRmptURL] = useState(
    "rtmp://broadcast.api.video/s/46c7b22b-d295-486d-9ee0-2c65fdc2b1a6"
  );

  const [liveURL, setLiveURL] = useState(
    "rtmp://broadcast.api.video/s" //"https://embed.api.video/live/liqHiqZ8KXIW63tLHHAbRDL"
  );

  const [delegatedToken, setDelegatedToken] = useState(
    "46c7b22b-d295-486d-9ee0-2c65fdc2b1a6"
  );

  const [streamState, setStreamState] = useState();
  const [mediaRecorderState, setMediaRecorderState] = useState();
  const [isStreaming, setIsStreaming] = useState(false);
  const [socket, setSocket] = useState();
  const [streamId, setStreamId] = useState("");
  //const [stream, setStream] = useState();

  const [questionList, setQuestionList] = useState([]);
  const [buttonProcessingId, setButtonProcessingId] = useState(false);
  const [numbersOfCurrentViewers, setNumbersOfCurrentViewers] = useState(0);
  const [salesGenerated, setSalesGenerated] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [openOptionsMenu, setOpenOptionsMenu] = useState([]);
  const [streamError, setStreamError] = useState(false);
  const [initiatingStream, setInitiatingStream] = useState(false);
  const [loadingSocket, setLoadingSocket] = useState(true);

  const streamStateRef = useRef();
  const mediaRecorderStateRef = useRef();
  const isStreamingRef = useRef();

  let mediaRecorder;
  console.log("isStreaming: ", isStreaming);
  useEffect(() => {
    /*let socket = io("http://localhost:3036", {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });*/
    /*let socket = io("https://ffmpeg-test-2ubc4lbrfa-uc.a.run.app", {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });*/
    let socket = io("https://nuine-development.ew.r.appspot.com/", {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });

    setSocket(socket);
    window.addEventListener("beforeunload", handleTabClosing);

    return () => {
      setIsStreaming(false);
      const tracks = streamStateRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      //handleTabClosing();
      window.removeEventListener("beforeunload", handleTabClosing);
      //mediaRecorderStateRef.current.stop();
      //socket.disconnect();
    };
  }, []);

  useEffect(() => {
    startCamera();
  }, [socket]);

  const handleTabClosing = () => {
    setIsStreaming(false);
    const tracks = streamStateRef.current.getTracks();
    tracks.forEach((track) => track.stop());
  };

  useEffect(() => {
    console.log("STREAM STATE: ", streamState);
    streamStateRef.current = streamState;
  }, [streamState]);

  useEffect(() => {
    mediaRecorderStateRef.current = mediaRecorderState;
  }, [mediaRecorderState]);

  useEffect(() => {
    isStreamingRef.current = isStreaming;
  }, [isStreaming]);

  const test = async () => {};

  const startCamera = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("getUserMedia supported.");
      navigator.mediaDevices
        .getUserMedia(
          // constraints - only audio needed for this app
          {
            video: {
              width: { min: 1024, ideal: 1280, max: 1920 },
              height: { min: 576, ideal: 720, max: 1080 },
              frameRate: { ideal: 25, max: 50 },
            },
            audio: true,
          }
        )

        // Success callback
        .then(function (stream) {
          setStreamState(stream);
          console.log("STREAM 1: ", stream);
          console.log("success");
          const video = document.getElementById("video");
          video.srcObject = stream;

          /*var options = {
            video: {
              width: { min: 100, ideal: 1280, max: 1920 },
              height: { min: 100, ideal: 720, max: 1080 },
              frameRate: { ideal: 25 },
            },
          };*/
          //mediaRecorder = new MediaRecorder(stream, options);
          var options = {
            audioBitsPerSecond: 128000,
            videoBitsPerSecond: 10000000,
            //mimeType: "video/mp4",
          };
          console.log("mediaRecorder: ", mediaRecorder);
          console.log("mediaRecorderState: ", mediaRecorderState);
          mediaRecorder = new MediaRecorder(stream, options);

          setMediaRecorderState(mediaRecorder);
          console.log(mediaRecorder.state);
          console.log("mediaRecorder.mimeType: ", mediaRecorder.mimeType);

          socket.emit("config_vcodec", mediaRecorder.mimeType);
          //socket.emit("config_vcodec", "video/webm;codecs=vp8,opus");

          mediaRecorder.ondataavailable = (event) => {
            /*if (event.data) {
              blobs.push(event.data);
            }*/
            if (event.data) {
              //console.log("isStreaming INSIDE: ", isStreamingRef.current);
              if (isStreamingRef.current) {
                socket.emit("binarystream", event.data);
              }

              //const video = document.getElementById("video");
              //video.setAttribute("controls", "");
              //const blob = new Blob(chunks, { type: "video/mpeg4; codecs=opus" });
              //chunks = [];
              //const videoURL = window.URL.createObjectURL(blob);
              //video.srcObject = event.data;
            }
          };

          connect_server();

          /*mediaRecorder.onstart = () => {
            recording = true;
          };

          mediaRecorder.onstop = () => {
            recording = false;
          };*/

          //video.setAttribute("controls", "");
          //const blob = new Blob(chunks, { type: "video/mpeg4; codecs=opus" });
          //chunks = [];
          //const videoURL = window.URL.createObjectURL(blob);
        })

        // Error callback
        .catch(function (err) {
          console.log("The following getUserMedia error occurred: " + err);
        });
    } else {
      console.log("getUserMedia not supported on your browser!");
    }
  };

  const startRecord = () => {
    setInitiatingStream(true);
    var initiateLiveStream = firebase
      .functions()
      .httpsCallable("initiateLiveStream");

    initiateLiveStream({
      streamId: streamId,
    }).then((result) => {
      if (result.data.code === 200) {
        console.log("INSIDE");
        setIsStreaming(true);
        mediaRecorderState.start(10);
        console.log("start");
        setStreamError(false);
        setInitiatingStream(false);
        dispatch(
          addStreamDetails({
            isStreaming: true,
          })
        );
        //TODO: Uncomment the 3 lines below
        /*listenForQuestions(result.data.data.documentId);
        listenForViewers(result.data.data.documentId);
        listenForSales(result.data.data.documentId);*/
        /*
        setStreamError(false);
        startStream(result.data.data);
        
        
        console.log("streamId2!!!!", result.data.data.documentId);
        setStreamId(result.data.data.documentId);
        */
      } else if (result.data.code === 402) {
        console.log("STREAM ERROR!!!");
        setStreamError(
          `Trial has ended. Please let ${user.representsBrandName} know that they have to update their plan.`
        );
      }
    });

    //TODO: Update stream instance with startTime and status
    /*console.log(mediaRecorder.state);
    connect_server();

    mediaRecorder.start(10);

    //connect_server();

    //mediaRecorder.start(100);

    let chunks = [];

    //mediaRecorder.ondataavailable = function (e) {
    //chunks.push(e.data);
    //Thsi is sent to the stream (socket)
    //const video = document.getElementById("video");
    //video.setAttribute("controls", "");
    //const blob = new Blob(chunks, { type: "video/mpeg4; codecs=opus" });
    //chunks = [];
    //const videoURL = window.URL.createObjectURL(blob);
    //video.src = e.data;
    //};

    //mediaRecorder.start(1000);*/
  };

  const stopRecord = () => {
    setIsStreaming(false);
    setModalOpen(false);
    console.log("stop");
    //console.log(mediaRecorder.state);
    console.log("STREAM 2: ", streamState);

    //const tracks = streamState.getTracks();
    //tracks.forEach((track) => track.stop());
    mediaRecorderState.stop();
    // TOTO: Commented out the "endLiveStream" call as it will be called with the webhook.
    /*
    var endLiveStream = firebase.functions().httpsCallable("endLiveStream");

    endLiveStream({ streamId: streamId, brandId: user.representsBrandId }).then(
      (result) => {
        console.log("RESULT: ", result.data);
      }
    );
    */
    dispatch(
      addStreamDetails({
        isStreaming: false,
      })
    );
  };

  async function connect_server() {
    /*if (!stream) {
      fail("No getUserMedia() available.");
    }*/
    if (!MediaRecorder) {
      //fail("No MediaRecorder available.");
    }

    var socketOptions = {
      secure: true,
      reconnection: true,
      reconnectionDelay: 1000,
      timeout: 10000,
      pingTimeout: 10000,
      pingInterval: 2000,
    };

    //start socket connection
    //socket = io.connect("/", socketOptions);
    // console.log("ping interval =", socket.pingInterval, " ping TimeOut" = socket.pingTimeout);
    //output_message.innerHTML=socket;

    socket.on("connect_timeout", (timeout) => {
      console.log("state on connection timeout= " + timeout);
      // output_message.innerHTML="Connection timed out";
      // recordingCircle.style.fill='gray';
    });
    socket.on("error", (error) => {
      console.log("state on connection error= " + error);
      //   output_message.innerHTML="Connection error";
      //    recordingCircle.style.fill='gray';
    });

    socket.on("connect_error", function () {
      console.log("state on connection error= " + state);
      //   output_message.innerHTML="Connection Failed";
      //   recordingCircle.style.fill='gray';
    });

    socket.on("message", function (m) {
      console.log("state on message= " + state);
      console.log("recv server message", m);
      //  show_output('SERVER:'+m);
    });

    socket.on("fatal", function (m) {
      // show_output('Fatal ERROR: unexpected:'+m);
      //alert('Error:'+m);
      console.log("fatal socket error!!", m);
      console.log("state on fatal error= " + state);
      //already stopped and inactive
      console.log("media recorder restarted");
      // recordingCircle.style.fill='gray';
    });

    socket.on("ffmpeg_stderr", function (m) {
      //this is the ffmpeg output for each frame
      console.log("FFMPEG:" + m);
    });

    socket.on("disconnect", function (reason) {
      console.log("state disconnect= " + state);
      // show_output('ERROR: server disconnected!');
      console.log("ERROR: server disconnected!" + reason);
      //  recordingCircle.style.fill='gray';
      //reconnect the server
      // connect_server();
    });

    let state = "ready";
    console.log("state = " + state);
    console.log("config_rtmpDestination", rmtpURL);
    await socket.emit(
      "config_rtmpDestination",
      {
        userId: user.id,
        userFirstName: user.firstName,
        brandId: user.representsBrandId,
        brandName: user.representsBrandName,
      },
      (cb) => {
        setStreamId(cb.streamId);
        console.log("YOYOYOYO");
        console.log(cb);
        socket.emit("start", "start");
        setLoadingSocket(false);
      }
    );
  }

  /*function connect_server() {
    if (!stream) {
      fail("No getUserMedia() available.");
    }
    if (!MediaRecorder) {
      fail("No MediaRecorder available.");
    }

    var socketOptions = {
      secure: true,
      reconnection: true,
      reconnectionDelay: 1000,
      timeout: 10000,
      pingTimeout: 10000,
      pingInterval: 2000,
    };

    //start socket connection
    socket = io.connect("/", socketOptions);
    // console.log("ping interval =", socket.pingInterval, " ping TimeOut" = socket.pingTimeout);
    //output_message.innerHTML=socket;

    socket.on("connect_timeout", (timeout) => {
      console.log("state on connection timeout= " + timeout);
      // output_message.innerHTML="Connection timed out";
      // recordingCircle.style.fill='gray';
    });
    socket.on("error", (error) => {
      console.log("state on connection error= " + error);
      //   output_message.innerHTML="Connection error";
      //    recordingCircle.style.fill='gray';
    });

    socket.on("connect_error", function () {
      console.log("state on connection error= " + state);
      //   output_message.innerHTML="Connection Failed";
      //   recordingCircle.style.fill='gray';
    });

    socket.on("message", function (m) {
      console.log("state on message= " + state);
      console.log("recv server message", m);
      //  show_output('SERVER:'+m);
    });

    socket.on("fatal", function (m) {
      // show_output('Fatal ERROR: unexpected:'+m);
      //alert('Error:'+m);
      console.log("fatal socket error!!", m);
      console.log("state on fatal error= " + state);
      //already stopped and inactive
      console.log("media recorder restarted");
      // recordingCircle.style.fill='gray';
    });

    socket.on("ffmpeg_stderr", function (m) {
      //this is the ffmpeg output for each frame
      console.log("FFMPEG:" + m);
    });

    socket.on("disconnect", function (reason) {
      console.log("state disconec= " + state);
      // show_output('ERROR: server disconnected!');
      console.log("ERROR: server disconnected!" + reason);
      //  recordingCircle.style.fill='gray';
      //reconnect the server
      // connect_server();
    });

    state = "ready";
    console.log("state = " + state);
    console.log("config_rtmpDestination", rmtpURL);
    socket.emit("config_rtmpDestination", rmtpURL);
    socket.emit("start", "start");
  }*/

  const listenForQuestions = (streamId) => {
    //Listen for changes in the question sub collection
    let tempQuestionsArray = [];
    let tempArray = openOptionsMenu;
    console.log("streamId!!!!!: ", streamId);

    firebase
      .firestore()
      .collection("questions")
      .where("streamId", "==", streamId)
      .where("status", "==", "idle")
      .where("viewerIsBanned", "==", false)
      .orderBy("createdAt")
      .onSnapshot(
        function (snapshot) {
          snapshot.forEach(function (doc) {
            /*snapshot.docChanges().forEach(function (change) {
          if (change.type === 'added') {
            console.log('New city: ', change.doc.data())
          }
          if (change.type === 'modified') {
            console.log('Modified city: ', change.doc.data())
          }
          if (change.type === 'removed') {
            console.log('Removed city: ', change.doc.data())
          }
        })*/

            console.log("question:: ", doc.data());

            let newObject = doc.data();
            newObject.questionId = doc.id;
            newObject.menuOpen = false;

            tempQuestionsArray.push(newObject);

            tempArray.push(false);
            //newTodos[index].isCompleted = !newTodos[index].isCompleted;
          });
          setOpenOptionsMenu(tempArray);
          setQuestionList(tempQuestionsArray);

          tempQuestionsArray = [];
          tempArray = [];
        },
        (error) => {
          console.log("snapshot error: ", error);
        }
      );
  };

  const listenForViewers = (streamId) => {
    let numbersOfCurrentViewers = 0;
    firebase
      .firestore()
      .collection("views")
      .where("streamId", "==", streamId)
      .where("userId", "==", user.id)
      .onSnapshot(function (snapshot) {
        console.log("inne");
        numbersOfCurrentViewers = 0;
        snapshot.forEach(function (doc) {
          if (doc.data().endTime === null) {
            numbersOfCurrentViewers++;
            console.log("more");
            /*} else {
            numbersOfCurrentViewers--;
            console.log("less");

            if (numbersOfCurrentViewers < 0) {
              numbersOfCurrentViewers = 0;
            }
          */
          }
          if (numbersOfCurrentViewers < 0) {
            numbersOfCurrentViewers = 0;
          }
        });
        console.log("cv: ", numbersOfCurrentViewers);
        setNumbersOfCurrentViewers(numbersOfCurrentViewers);
      });
  };

  //TODO: Set databaserule on Orders to avid user to access other records than he affect. Mov this to backend.
  const listenForSales = (streamId) => {
    let salesGenerated = 0;
    firebase
      .firestore()
      .collection("orders")
      .where("attributedToStreamId", "==", streamId)
      .where("attributedToUserId", "==", user.id)
      .onSnapshot(function (snapshot) {
        console.log("inne sales");
        salesGenerated = 0;
        snapshot.forEach(function (doc) {
          salesGenerated++;
        });
        console.log("cv sales: ", salesGenerated);
        setSalesGenerated(salesGenerated);
      });
  };

  const setQuestionStatus = (
    questionDocumentId,
    questionStatus,
    questionIndex
  ) => {
    //openCloseOptionsMenu(questionIndex);
    setButtonProcessingId(questionIndex);
    var changeQuestionStatus = firebase
      .functions()
      .httpsCallable("changeQuestionStatus");

    changeQuestionStatus({
      questionId: questionDocumentId,
      newStatus: questionStatus,
      brandId: user.representsBrandId,
    }).then((result) => {
      console.log(result);
      setButtonProcessingId(false);
    });
  };

  const banUser = (viewerId) => {
    console.log("BAN USER: ", viewerId);
    var banUser = firebase.functions().httpsCallable("banUser");

    banUser({
      viewerId: viewerId,
    }).then((result) => {
      console.log(result);
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openCloseOptionsMenu = (index) => {
    const tempArray = questionList;
    const tempArrayAttribute = questionList[index].menuOpen;

    tempArray.map((i) => {
      i.menuOpen = false;
    });

    tempArray[index].menuOpen = !tempArrayAttribute;

    setQuestionList([...tempArray]);
  };

  return (
    <DashboardLayout>
      <AreYouSureModal
        modalOpen={modalOpen}
        notEnd={closeModal}
        end={stopRecord}
      />
      <div className="flex flex-row h-screen">
        <div className="flex items-center justify-center flex-grow h-full bg-gray-900">
          <div
            className="relative w-10/12 h-0"
            style={{ paddingBottom: 44.5 + "%" }}
          >
            {/*<div
              id="local-player"
              className="h-full w-full top-0 left-0 absolute"
            ></div>*/}
            <video
              id="video"
              className="h-full w-full top-0 left-0 absolute transform scale-x-n100"
              autoPlay
              muted
              loop
            ></video>
          </div>
          {streamError ? (
            <div className="absolute z-10 rounded-md bg-yellow-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  {/* Heroicon name: solid/information-circle */}
                  <svg
                    className="h-5 w-5 text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-yellow-700">{`${streamError}`}</p>
                </div>
              </div>
            </div>
          ) : !isStreaming ? (
            <button
              type="button"
              disabled={initiatingStream}
              onClick={() => {
                startRecord();
              }}
              className="absolute inline-flex primary-button bottom-8 self-end"
            >
              {!initiatingStream && !loadingSocket ? (
                "Start stream"
              ) : (
                <svg
                  className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </button>
          ) : (
            <button
              type="button"
              onClick={() => setModalOpen(true)}
              className="absolute inline-flex primary-button text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 bottom-8 self-end"
            >
              End stream
            </button>
          )}
        </div>
        {isStreaming && (
          <div className="flex-none h-full w-80 px-8 overflow-scroll bg-white">
            <div className="pb-5 mt-8">
              <div className="pb-8 border-b">
                <p className="text-base font-medium text-gray-900">
                  {`Current viewers: ${numbersOfCurrentViewers}`}
                </p>
                <p className="text-base font-medium text-gray-900">
                  {`Sales generated: ${salesGenerated}`}
                </p>
              </div>
              <h3 className="mt-8">Questions</h3>
            </div>
            <div>
              <div className="flow-root">
                <ul className="-my-5 divide-y divide-gray-200">
                  {questionList.map((listItem, i) => {
                    return (
                      <li className="py-5" key={i}>
                        <div className="relative">
                          <p className="text-sm mb-0.5">
                            {/* Extend touch target to entire panel */}
                            {`User ID: ${listItem.viewerId}`}
                          </p>
                          <p className="font-semibold text-gray-900">
                            {new Date(
                              listItem.createdAt * 1000
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </p>
                          <p className="text-base font-medium mb-3">
                            {listItem.question}
                          </p>
                          <div className="flex justify-between mt-2">
                            <button
                              type="button"
                              onClick={() =>
                                setQuestionStatus(
                                  listItem.questionId,
                                  "answered",
                                  i
                                )
                              }
                              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              {buttonProcessingId === i ? (
                                <svg
                                  className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : (
                                "Answered"
                              )}
                            </button>
                            {/* This example requires Tailwind CSS v2.0+ */}
                            <div className="relative inline-block text-left">
                              <div>
                                <button
                                  type="button"
                                  className="inline-flex items-center px-2.5 py-1.5 rounded border border-gray-300 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 shadow-sm bg-white focus:ring-indigo-500"
                                  id="options-menu"
                                  aria-expanded="true"
                                  aria-haspopup="true"
                                  onClick={() => {
                                    openCloseOptionsMenu(i);
                                  }}
                                >
                                  {buttonProcessingId === i ? (
                                    <svg
                                      className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                  ) : (
                                    <>
                                      Options
                                      <svg
                                        className="-mr-1 ml-2 h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </>
                                  )}
                                </button>
                              </div>
                              <Transition
                                show={listItem.menuOpen}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                                className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10 w-64"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                              >
                                <div className="py-1" role="none">
                                  <button
                                    className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                    role="menuitem"
                                    onClick={() => {
                                      setQuestionStatus(
                                        listItem.questionId,
                                        "skipped",
                                        i
                                      );
                                    }}
                                  >
                                    {/* Heroicon name: solid/pencil-alt */}
                                    <svg
                                      className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    Skip question
                                  </button>
                                  <button
                                    className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                    role="menuitem"
                                    onClick={() => {
                                      setQuestionStatus(
                                        listItem.questionId,
                                        "support",
                                        i
                                      );
                                    }}
                                  >
                                    {/* Heroicon name: solid/duplicate */}
                                    <svg
                                      className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                      <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                                    </svg>
                                    Mark as support question
                                  </button>
                                </div>
                                <div className="py-1" role="none">
                                  <button
                                    className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                                    role="menuitem"
                                    onClick={() => {
                                      banUser(listItem.viewerId);
                                    }}
                                  >
                                    {/* Heroicon name: solid/archive */}
                                    <svg
                                      className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                                      <path
                                        fillRule="evenodd"
                                        d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    Ban user
                                  </button>
                                </div>
                              </Transition>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
    /*<DashboardLayout>
      <div className="flex flex-row h-screen">
        <div className="flex items-center justify-center flex-grow h-full bg-gray-900">
          <button className="text-lg bg-white" onClick={startCamera}>
            Initiate
          </button>
          <button className="text-lg bg-white" onClick={startRecord}>
            Start
          </button>
          <button className="text-lg bg-white" onClick={stopRecord}>
            Stop
          </button>
          <div className="bg-red-500 p-11 ">
            <video id="video" className="w-36 h-36" autoPlay muted loop></video>
          </div>
        </div>
      </div>
    </DashboardLayout>*/
  );
};

export default connect(
  (state) => ({
    user: state.app.user,
  }),
  null
)(Stream);
