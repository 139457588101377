import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";

import DashboardLayout from "../components/dashboardLayout";
import { addStreamDetails, addInitialData } from "../state/app";

import StreamSteps from "../components/streamSteps";
import StreamStep1 from "../components/streamStep1";
import StreamStep2 from "../components/streamStep2";
import StreamStep3 from "../components/streamStep3";
import StreamStep4 from "../components/streamStep4";
import { navigate } from "gatsby";

const Stream2 = ({ state, stream, dispatch }) => {
  const [streamError, setStreamError] = useState(false);
  const [streamURL, setStreamURL] = useState("");
  const [streamKey, setStreamKey] = useState("");
  const [streamId, setStreamId] = useState("");
  const [fromPreview, setFromPreview] = useState(false);
  const [streamDetails, setStreamDetails] = useState(false);
  const [stepStatus, setStepStatus] = useState({
    status: "idle",
    step1: "current",
    step2: "upcoming",
    step3: "upcoming",
  });

  useEffect(() => {
    switch (state.streamStatus) {
      case "idle":

        break;
      case "preview":
        break;
      case "active":
        setStepStatus({
          status: "active",
          step1: "complete",
          step2: "complete",
          step3: "current",
        });
        dispatch(
          addStreamDetails({
            isStreaming: true,
          })
        );
        break;
      case "ended":
        setStepStatus({
          status: "ended",
          step1: "upcoming",
          step2: "upcoming",
          step3: "upcoming",
        });
        dispatch(
          addStreamDetails({
            isStreaming: false,
          })
        );
        break;
      default:
        setStepStatus({
          status: "idle",
          step1: "current",
          step2: "upcoming",
          step3: "upcoming",
        });
    }
  }, [state.streamStatus]);

  const createLiveStream = (streamLanguage, widgetCategories) => {
    var createLiveStream = firebase
      .functions()
      .httpsCallable("createLiveStream");

    createLiveStream({
      userFirstName: state.userFirstName,
      brandId: state.brandId,
      brandName: state.brandName,
      streamLanguage: streamLanguage,
      widgetCategories: widgetCategories,
    }).then((result) => {
      if (result.data.code === 200) {
        dispatch(
          addInitialData({
            streamId: result.data.streamId
          })
        );
        setStreamURL(result.data.streamURL);
        setStreamKey(result.data.streamKey);
        setStreamId(result.data.streamId);
        listenForStreamStatusUpdates(result.data.streamId);
        /*if (result.data.code === 200) {
        console.log("SUCCESS");
      }*/
        console.log("RESULT: ", result);
      }
      // TODO: Fix so that this is sent from the backend in case trial has ended
      /* else if (result.data.code === 402) {
        console.log("STREAM ERROR!!!");
        setStreamError(
          `Trial has ended. Please let ${state.brandName} know that they have to update their plan.`
        );
      } */
    });
  };

  const listenForStreamStatusUpdates = async (streamId) => {
    firebase
      .firestore()
      .collection("streams")
      .doc(streamId)
      .collection("publicDetails")
      .onSnapshot(function (snapshot) {
        snapshot.forEach(
          function (doc) {
            //if (change.type === 'modified') {
            //console.log('Modified city: ', change.doc.data())
            //}
            /*switch (doc.data().status) {
              case "idle":
                setStepStatus({
                  status: "idle",
                  step1: "current",
                  step2: "upcoming",
                  step3: "upcoming",
                });
                break;
              case "preview":
                setStepStatus({
                  status: "preview",
                  step1: "complete",
                  step2: "current",
                  step3: "upcoming",
                });
                break;
              case "active":
                setStepStatus({
                  status: "active",
                  step1: "complete",
                  step2: "complete",
                  step3: "current",
                });
                dispatch(
                  addStreamDetails({
                    isStreaming: true,
                  })
                );
                break;
              case "ended":
                setStepStatus({
                  status: "ended",
                  step1: "upcoming",
                  step2: "upcoming",
                  step3: "upcoming",
                });
                dispatch(
                  addStreamDetails({
                    isStreaming: false,
                  })
                );
                break;
              default:
                setStepStatus({
                  status: "idle",
                  step1: "current",
                  step2: "upcoming",
                  step3: "upcoming",
                });
            }*/

            console.log("status:: ", doc.data());
          },
          (error) => {
            console.log("snapshot error: ", error);
          }
        );
      });
  };

  return (
    <DashboardLayout>
      <div
        className={
          stream.isStreaming
            ? "space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-gray-900 h-full"
            : "space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-gray-900"
        }
      >
        <div
          className={
            stream.isStreaming
              ? "cardContainerOuter h-full"
              : "cardContainerOuter"
          }
        >
          <div
            className={
              stream.isStreaming
                ? "cardContainerInner h-full"
                : "cardContainerInner"
            }
          >
            <StreamSteps stepStatus={stepStatus} />
            {streamError && (
              <div className="absolute z-10 rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    {/* Heroicon name: solid/information-circle */}
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-yellow-700">{`${streamError}`}</p>
                  </div>
                </div>
              </div>
            )}
            {stepStatus.status === "idle" && (
              <StreamStep1
                streamURL={streamURL}
                streamKey={streamKey}
                createLiveStream={createLiveStream}
                setStreamDetails={() => setStreamDetails(true)}
                streamDetails={streamDetails}
              />
            )}
            {stepStatus.status === "preview" && (
              <StreamStep2 streamId={streamId} />
            )}
            {!streamError && stepStatus.status === "active" && (
              <StreamStep3 streamId={streamId} />
            )}
            {!streamError && stepStatus.status === "ended" && (
              <StreamStep4 streamId={streamId} />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(
  (state) => ({
    state: state.app,
    stream: state.app.stream,
  }),
  null
)(Stream2);
