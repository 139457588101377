import React, { useEffect, useState } from "react";
import { navigate, Link } from "gatsby";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { ChevronDownIcon } from "@heroicons/react/solid";
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon } from "@heroicons/react/outline";

import { connect } from "react-redux";

import { addInitialData } from "../state/app";

const AdminAndManagerDashboard = ({ state, dispatch }) => {
  /*const [sharableAdvocateLink, setSharableAdvocateLink] = useState({
    sharableAdvocateLink: false,
  });*/
  const [invitationId, setInvitationId] = useState(state.brandInvitationId ? state.brandInvitationId : false);
  const [minutesLeftOfTrial, setMinutesLeftOfTrial] = useState(state.secondsLeftOfTrial > 60 ? Math.floor(state.secondsLeftOfTrial / 60) : false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [toggleLearnMoreAbout, setToggleLearnMoreAbout] = useState(0);

  const [role, setRole] = useState("manager");
  const [stats, setStats] = useState([]);

  let roles = [];

  useEffect(() => {
    if (state.streamStats) {
      setStats([
        {
          name: "Revenue",
          stat: state.streamStats.total.revenueGenerated,
          currency: "$",
        },
        {
          name: "Avg. Order value",
          stat:
            state.streamStats.total.revenueGenerated &&
            state.streamStats.total.numberOfSales &&
            (state.streamStats.total.revenueGenerated / state.streamStats.total.numberOfSales).toFixed(2),
          currency: "$",
        },
        {
          name: "Avg. revenue per stream",
          stat: state.streamStats.total.numberOfStreams !== 0 ? (state.streamStats.total.revenueGenerated / state.streamStats.total.numberOfStreams).toFixed(2) : 0,
          currency: "$",
        },
        {
          name: "Orders",
          stat: state.streamStats.total.numberOfSales,
          currency: "",
        },
        {
          name: "Avg. orders per stream",
          stat: state.streamStats.total.numberOfStreams !== 0 ? (state.streamStats.total.numberOfSales / state.streamStats.total.numberOfStreams).toFixed(2) : 0,
          currency: "",
        },
        {
          name: "Questions answered",
          stat: state.streamStats.total.questionsAnswered,
          currency: "",
        },
        {
          name: "Number of streams",
          stat: state.streamStats.total.numberOfStreams,
          currency: "",
        },
        {
          name: "Time streamed",
          stat: secondsToHms(state.streamStats.total.timeStreamed),
          currency: "",
        },
        {
          name: "Avg. stream time",
          stat: secondsToHms(state.streamStats.total.timeStreamed / state.streamStats.total.numberOfStreams),
          currency: "",
        },
      ]);
    }

  }, [state.streamStats])

  switch (state.userRole) {
    case "admin":
      roles = [
        { abbreviation: "admin", name: "Admin" },
        { abbreviation: "manager", name: "Manager" },
        { abbreviation: "sales-rep", name: "Sales representative" },
      ];
      break;
    case "manager":
      roles = [{ abbreviation: "advocate", name: "Advocate" }];
      break;

    default:
      roles = [{ abbreviation: "advocate", name: "Advocate" }];
  }



  const copySharableLinkToClipboard = () => {
    const link = `https://nuine.co/invite/${invitationId}`;

    navigator.clipboard.writeText(link).then(
      () => {
        console.log("Copying to clipboard was successful: ", link);
        dispatch(
          addInitialData({
            notification: { showNotification: true, type: "success", header: "Invitation link is copied", message: false }
          })
        );
      },
      (error) => {
        console.error("Could not copy text: ", error);
      }
    );
  };

  const sendSalesRepInvitationEmail = async (emailAddress) => {
    var sendSalesRepInvitationEmail = firebase
      .functions()
      .httpsCallable("sendSalesRepInvitationEmail");

    await sendSalesRepInvitationEmail({
      emailAddress: emailAddress,
      brandName: state.brandName,
      brandId: state.brandId,
    })
      .then((result) => {
        console.log("RESULT: ", result);
        dispatch(
          addInitialData({
            notification: { showNotification: true, type: "success", header: `Invitation sent to ${emailAddress}`, message: false }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });

    return;
  };

  const sendTeamMemberInvitationEmail = async (email, role) => {
    var sendTeamMemberInvitationEmail = firebase
      .functions()
      .httpsCallable("sendTeamMemberInvitationEmail");

    await sendTeamMemberInvitationEmail({
      email: email,
      role: role,
      brandName: state.brandName,
      brandId: state.brandId,
    })
      .then((result) => {
        console.log("RESULT: ", result);
        dispatch(
          addInitialData({
            notification: { showNotification: true, type: "success", header: `Invitation sent to ${email}`, message: false }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });

    return;
  };

  /*if (user && !numberOfSales && !isFetchingData) {
    //setIsFetchingData(true)
    getNumberOfSalesPerBrand()
    getRevenueGeneratedPerBrand()
    getDaysLeftOfTrial()
  }*/

  const secondsToHms = (seconds) => {
    if (seconds > 0) {
      seconds = Number(seconds);
      var h = Math.floor(seconds / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor((seconds % 3600) % 60);
      /*
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    */
      var hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
      var mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
      var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";

      return hDisplay + mDisplay + sDisplay;
    } else {
      return "0h 0m 0s";
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const learnMore = (learnMoreAbout) => {
    console.log(learnMoreAbout);
    setToggleLearnMoreAbout(learnMoreAbout);
  };

  return (
    <>
      {/*<button
        style={isDarkMode ? { background: 'black', color: 'white' } : null}
        onClick={() => dispatch(toggleDarkMode(!isDarkMode))}
      >
        Dark mode {isDarkMode ? 'on' : 'off'}{' '}
      </button>*/}
      {(state.userRole === "admin" || state.userRole === "manager") && (
        <div>
          <div className="mb-8">
            {!state.widgetIsInitializedOnBrandPage && (
              <div className="rounded-md bg-honeyYellow-50 px-4 py-3 mb-2">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-honeyYellow-800" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="mx-px text-base text-honeyYellow-800">
                      Setup{" "}
                      <span className="font-bold">nuine</span>{" "}
                      on your webpage with a few easy steps
                    </p>
                    <p className="mx-px mt-3 md:mt-0 md:ml-6 text-base">
                      <Link
                        to="/app/docs/setup"
                        className="whitespace-nowrap font-medium text-honeyYellow-800 hover:text-yellow-600"
                      >
                        Setup <span aria-hidden="true">→</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {!state.supportsiteURL && (
              <div className="rounded-md bg-honeyYellow-50 px-4 py-3 mb-2">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-honeyYellow-800" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="mx-px text-base text-honeyYellow-800">
                      Add a link to your support pages
                    </p>
                    <p className="mx-px mt-3 md:mt-0 md:ml-6 text-base">
                      <Link
                        to="/app/settings/general"
                        className="whitespace-nowrap font-medium text-honeyYellow-800 hover:text-yellow-600"
                      >
                        Add <span aria-hidden="true">→</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {state.subscriptionStatus === "trial" && (
              <div className="rounded-md bg-honeyYellow-50 px-4 py-3 p-4">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-honeyYellow-800" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="mx-px text-base text-honeyYellow-800">
                      {minutesLeftOfTrial
                        ? `${minutesLeftOfTrial} minutes left of your trial`
                        : `Please choose a plan before you start to stream`}
                    </p>
                    <p className="mx-px mt-3 md:mt-0 md:ml-6 text-base">
                      <Link
                        to="/app/settings/plan"
                        className="whitespace-nowrap font-medium text-honeyYellow-800 hover:text-yellow-600"
                      >
                        Choose plan <span aria-hidden="true">→</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="py-16 px-12 mb-2 mt-16 border-y">
            <div>
              <div>
                <h2>Add team members</h2>
                <div className="grid lg:grid-cols-6 gap-4">
                  <div className="col-span-6">
                    <p className="pt-4 font-medium text-gray-900">
                      Invite directly
                      <span className="pl-2 text-gray-600">
                        {/*<QuestionMarkCircleIcon className="group w-5 h-5 inline-block mb-1" />*/}
                        <button
                          className="underline text-sm focus:outline-none"
                          onClick={() => learnMore(1)}
                        >
                          Learn more
                        </button>
                      </span>
                    </p >
                    {toggleLearnMoreAbout === 1 && (
                      <div className="py-2">
                        <div class="bg-white text-white text-xs rounded px-4 py-4">
                          <p className="text-sm text-gray-600">
                            Invite people that already is a part of your
                            company; employees or sales representatives.
                          </p>
                          <p className="text-sm text-gray-600 pt-2">
                            They will receive a link where they can sign up and
                            start to stream right away.
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="py-2">
                      <Formik
                        initialValues={{ email: "", role: "manager" }}
                        validationSchema={Yup.object({
                          email: Yup.string()
                            .email("Invalid email address")
                            .required("Required"),
                          role: Yup.string().required("Required"),
                        })}
                        onSubmit={async (
                          values,
                          { setSubmitting, resetForm }
                        ) => {
                          setFormSubmitting(true);
                          //await sendSalesRepInvitationEmail(values.email);
                          await sendTeamMemberInvitationEmail(
                            values.email,
                            values.role
                          );
                          setSubmitting(false);
                          setFormSubmitting(false);
                          resetForm();
                        }}
                      >
                        {({ errors, touched, setFieldValue, isValidating }) => (
                          <Form>
                            <div className="text-base flex justify-between flex-col space-y-3">
                              <div className="grid lg:grid-cols-6 gap-4">
                                <div className="col-span-4">
                                  <div className="relative rounded-md">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                      {/* Heroicon name: solid/mail */}
                                      <svg
                                        className={`${errors.email && touched.email
                                          ? "text-red-800"
                                          : "text-gray-400"
                                          } h-5 w-5 text-gray-400`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                    </div>
                                    <Field
                                      name="email"
                                      placeholder="any@email.com"
                                      type="email"
                                      //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                      className={`${errors.email && touched.email
                                        ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                        : "border focus:ring-black focus:border-black"
                                        } appearance-none block w-full px-3 py-2 rounded-md border-gray-200 placeholder-gray-400 focus:outline-none sm:text-sm lg:text-base pl-10 text-gray-900`}
                                    />
                                  </div>

                                  <ErrorMessage name="email">
                                    {(error) => (
                                      <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                        {error}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </div>
                                <div className="col-span-2">
                                  <div className="relative rounded-md">
                                    <Field
                                      id="role"
                                      name="role"
                                      as="select"
                                      className={`${errors.role && touched.role
                                        ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                        : "focus:ring-black focus:border-black"
                                        } appearance-none block w-full px-3 py-2 border rounded-md border-gray-200 placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                                      /*onChange={(e) => {
                                  
                                }}*/
                                      onChange={(e) => {
                                        console.log(
                                          "e.target.value: ",
                                          e.target.value
                                        );
                                        setRole(e.target.value);
                                        setFieldValue("role", e.target.value);
                                      }}
                                    >
                                      {roles.map((role, i) => {
                                        return (
                                          <option
                                            value={role.abbreviation}
                                            key={i}
                                          >
                                            {role.name}
                                          </option>
                                        );
                                      })}
                                      {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                                    </Field>
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                      <ChevronDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>
                                  <ErrorMessage name="language">
                                    {(error) => (
                                      <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                        {error}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </div>
                              </div>
                              <button
                                type="submit"
                                disabled={formSubmitting}
                                className="w-32 primary-button"
                              >
                                {formSubmitting ? (
                                  <svg
                                    className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                ) : (
                                  "Invite"
                                )}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div >
                  {/*<div className="col-span-6"> //TODO: Uncomment this to enable invitation of sales-reps
                    <p className="pt-4 font-medium text-gray-900">
                      Invite through application
                      <span className="pl-2 text-gray-600">
                        <button
                          className="underline text-sm focus:outline-none"
                          onClick={() => learnMore(2)}
                        >
                          Learn more
                        </button>
                      </span>
                    </p>
                    {toggleLearnMoreAbout === 2 && (
                      <div className="py-2">
                        <div class="bg-white text-white text-xs rounded px-4 py-4">
                          <p className="text-sm text-gray-600">
                            Share this link with customers, or others, you think
                            will be a good representative for your brand.
                          </p>
                          <p className="text-sm text-gray-600 pt-2">
                            The link will take them to a page whre they record a
                            video application that you can accept or decline.
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="py-2">
                      <div className="text-base flex justify-between flex-col space-y-3">
                        <p className="appearance-none bg-white block w-full px-3 py-2 border rounded-md sm:text-sm lg:text-base text-gray-900">
                          {invitationId &&
                            invitationId !== "error" &&
                            `nuine.co/invite/${invitationId}`}
                        </p>
                        <button
                          type="button"
                          onClick={() => {
                            copySharableLinkToClipboard();
                          }}
                          className="w-32 primary-button"
                        >
                          Copy link
                        </button>
                      </div>
                    </div>
                  </div>*/}
                </div >
              </div >
            </div >
          </div >

          <div className="pt-16 pb-8 px-12 rounded-md">
            <div>
              <div>
                <h2>Performance overview</h2>
                <div>
                  <p className="mt-2 font-medium text-gray-900">Total</p>
                  <dl className="mt-5 grid grid-cols-1 overflow-hidden md:grid-cols-3">
                    {stats && stats.map((item) => (
                      <div
                        key={item.name}
                        className="px-4 py-5 sm:py-6 sm:px-0"
                      >
                        <dt className="text-gray-600 text-base font-normal">
                          {item.name}
                        </dt>
                        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                          <div className="flex items-baseline text-2xl text-gray-900">
                            {item.currency}
                            {item.stat}
                          </div>
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default connect(
  (state) => ({
    state: state.app
  }),
  null
)(AdminAndManagerDashboard);
