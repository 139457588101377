import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { PlayerSdk } from "@api.video/player-sdk";
//const ApiVideoClient = require("@api.video/nodejs-client");
import axios from "axios";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";

const LiveStreams = ({ state, dispatch }) => {
  const [streamsIsLoaded, setStreamsIsLoaded] = useState(false);
  const [streams, setStreams] = useState(false);
  const [apiVideoSDK, setApiVideoSDK] = useState(false);

  useEffect(() => {
    console.log("STREAMS: ", streams);
    if (streams && !apiVideoSDK) {
      streams.map((stream, i) => {
        console.log("WEFSGDDFGDF: ", stream);
        console.log("WEFSGDDFGDF: ", stream.streamId);
        const sdk = new PlayerSdk(`#live-stream-${stream.streamId}`, {
          id: stream.streamId,
          live: true,
          muted: false,
          hideControls: false,
          hideTitle: true,
          autoPlay: true,
        });
        setApiVideoSDK(sdk);
        console.log("state.moderatorStreamId: ", state.moderatorStreamId)
        console.log("stream.streamId:::: ", stream.streamId)
        console.log("state.moderatorStreamId === stream.streamId:::: ", state.moderatorStreamId === stream.streamId)
      });
    }

  }, [streams]);



  if ((state.userRole === "manager" || state.userRole === "admin") && !streamsIsLoaded) {
    console.log("1");

    var getStreamDetailsToDashboard = firebase
      .functions()
      .httpsCallable("getStreamDetailsToDashboard");

    getStreamDetailsToDashboard({ brandId: state.brandId }).then(
      (result) => {
        setStreamsIsLoaded(true);

        setStreams(result.data);
        console.log("STREAMS!!!!!!!!!: ", result.data);
      }
    );
  }

  const moderateStream = (streamId) => {
    console.log("moderate: ", streamId);
    navigate(`${streamId}`);
  };

  const terminateStream = () => {
    var getApiVideoToken = firebase
      .functions()
      .httpsCallable("getApiVideoToken");

    getApiVideoToken({ brandId: state.brandId }).then((result) => {
      console.log("API VIDEO RESULT: ".result);
      /*(async () => {
        try {
          const client = new ApiVideoClient({ apiKey: result.data.apiToken });

          const liveStreamId = streams.streamId; // The unique ID for the live stream that you want to remove.

          // void
          const endLiveStreamResult = await client.liveStreams.delete(
            liveStreamId
          );
          console.log(endLiveStreamResult);
        } catch (e) {
          console.error(e);
        }
      })();*/
      axios.delete(`https://ws.api.video/live-streams/${streams.streamId}`, {
        headers: {
          Authorization: result.authorizationToken,
        },
      });
      setStreams(false);
    });
  };

  return (
    <div>
      <DashboardLayout>
        {(state.userRole === "manager" || state.userRole === "admin") && (
          <div className="flex flex-col">

            {streamsIsLoaded ? (
              <div>
                <div className="sm:rounded-md sm:overflow-hidden">
                  <div className="py-12 px-12">
                    <div className="pb-6">
                      <div>
                        <h2>Active live streams</h2>
                      </div>
                    </div>
                    {streams ? (
                      streams.map((stream, i) => {
                        return (

                          <div
                            className="grid grid-flow-col grid-cols-2 gap-4"
                            key={i}
                          >
                            <div className="flex flex-col items-start pr-5 pb-10">
                              <div
                                id={`live-stream-${stream.streamId}`}
                                className="t4445 relative w-full h-0"
                                style={{ paddingBottom: 56 + "%" }}
                              ></div>
                              {stream.moderatorId === state.userId && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    moderateStream(stream.streamId)
                                  }
                                  className="primary-button w-full mt-2"
                                >
                                  Moderate stream
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={() => terminateStream(stream.streamId)}
                                className="secondary-button w-full mt-2"
                              >
                                Terminate stream
                              </button>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <p>There are currently no active streams.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center h-screen">
                <svg
                  className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {/*<p className="ml-2">Loading...</p>*/}
              </div>
            )}
          </div>
        )}
      </DashboardLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    state: state.app
  }),
  null
)(LiveStreams);
