import React from "react";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import firebase from "firebase/app";

import { addInitialData } from "../state/app";

const people = [
  { id: 1, name: "Wade Cooper" },
  { id: 2, name: "Arlene Mccoy" },
  { id: 3, name: "Devon Webb" },
  { id: 4, name: "Tom Cook" },
  { id: 5, name: "Tanya Fox" },
  { id: 6, name: "Hellen Schmidt" },
  { id: 7, name: "Caroline Schultz" },
  { id: 8, name: "Mason Heaney" },
  { id: 9, name: "Claudie Smitham" },
  { id: 10, name: "Emil Schaefer" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectMenuWithCheck({ props, dispatch }) {
  const [listOfTeamMembers, setListOfTeamMembers] = useState(
    props.listOfTeamMembers
  );
  const [requestingModeratorToJoin, setRequestingModeratorToJoin] = useState(false)
  const [selected, setSelected] = useState(listOfTeamMembers[0]);

  const sendModeratorRequest = () => {
    setRequestingModeratorToJoin(true)

    var askTeamMemberToModerateQuestions = firebase
      .functions()
      .httpsCallable("askTeamMemberToModerateQuestions");

    askTeamMemberToModerateQuestions({
      brandId: props.brandId,
      streamId: props.streamId,
      moderatorUserId: selected.userId,
      moderatorFirstName: selected.firstName,
      moderatorLastName: selected.lastName,
      brandName: props.brandName,
    }).then((result) => {
      console.log("inside")
      setRequestingModeratorToJoin(false)
      dispatch(
        addInitialData({
          notification: { showNotification: true, type: "success", header: "Sent", message: `Request for moderation is sent to ${selected.firstName + " " + selected.lastName}` }
        })
      );
      /*if (result.data.code === 200) {
      console.log("SUCCESS");
    }*/

      console.log("RESULT: ", result);
      console.log("stramId: ", props.streamId);
    });
  };

  return (
    <div className="flex flex-row place-content-between">
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <div className="relative w-full pr-2">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-lg pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-black focus:border-black sm:text-sm h-10">
                <span className="block truncate">
                  {selected.firstName + " " + selected.lastName}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {listOfTeamMembers.map((listOfTeamMembers) => (
                    <Listbox.Option
                      key={listOfTeamMembers.userId}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-black" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={listOfTeamMembers}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {listOfTeamMembers.firstName +
                              " " +
                              listOfTeamMembers.lastName}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-black",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <button
        className="primary-button min-w-[132px] text-sm h-10"
        onClick={sendModeratorRequest}
      >
        {requestingModeratorToJoin ? (
          <svg
            className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          "Send request"
        )}

      </button>
    </div>
  );
}
