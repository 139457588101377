import React, { useState } from "react";
import { useFormik } from "formik";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";
import DocumentationMenu from "../components/documentationMenu";

const SettingsGeneral = ({ state }) => {

  const attributes = [
    {
      attribute: "totalValue",
      description: "The total order value.",
      type: "integer or double",
      example: "858 or 858.17",
    },
    {
      attribute: "currency",
      description: "The currency code of the order.",
      type: "string",
      example: '"USD", "EUR", "GBP", other',
    },
    {
      attribute: "id",
      description:
        "An unique order id. It is recommended to use the order id from your ecommerce platform.",
      type: "string",
      example: '"s4CLg0nHZ8EvNCtmUEaX"',
    },
    {
      attribute: "items",
      description: "List of all the items in the order.",
      type: "array of object(s)",
      example: "-",
    },
    {
      attribute: "sku",
      description: "The item SKU or product id.",
      type: "string",
      example: '"P6466", "V-93757", "NILE-HUG-TY"',
    },
    {
      attribute: "qunatity",
      description: "The number of items of the specific SKU.",
      type: "integer",
      example: "1, 3, 17",
    },
    {
      attribute: "itemValue",
      description:
        "Price of the item. Do not multiply the quantity with the item price. Send only in the price for one single item.",
      type: "integer or double",
      example: "38, 156.37",
    },
  ];

  return (
    <div>
      <DashboardLayout>
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <DocumentationMenu page="event-listeners" />
          <div className="cardContainerOuter border-b">
            <div className="cardContainerInner">
              <div>
                <h2 className="">Add event listeners</h2>
                <p className="text-gray-800 pt-4">
                  The <b>nuine</b> event listeners lets you check if a stream is active or not. They can be used to display certain elements on your page.
                  For example you may wish to show a module, a menu element, or a button if there is an ongong stream and to hide those if the stream is no longer active.
                </p>
                <p className="text-gray-800 pt-4">
                  They are easy to implement and use.
                </p>
              </div>
              <div className="flex flex-row pb-16 pt-10">
                <div>
                  <h3 className="">How to use</h3>
                  <p className="text-gray-800 pt-4">
                    We currently offer two events; <code className="bg-gray-100 p-1 text-sm">nuineIsActive</code> and <code className="bg-gray-100 p-1 text-sm">nuineIsNotActive</code>. They can be used on all pages where the <b>nuine</b> script is added and works like:
                  </p>
                  <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                    <code>
                      {'window.addEventListener("nuineIsActive", () => {'}<br />
                      <span className="pl-6">{'// do something, like display a module'}</span><br />
                      {'});'}
                    </code>
                  </div>
                  <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                    <code>
                      {'window.addEventListener("nuineIsNotActive", () => {'}<br />
                      <span className="pl-6">{'// do something, like remove a module'}</span><br />
                      {'});'}
                    </code>
                  </div>
                  <p className="text-gray-800 pt-8">
                    And the events can be removed like this:
                  </p>
                  <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                    <code>
                      {'window.removeEventListener("nuineIsActive", () => {'}{'});'}
                    </code>
                  </div>
                  <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                    <code>
                      {'window.removeEventListener("nuineIsNotActive", () => {'}{'});'}
                    </code>
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>
      </DashboardLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    state: state.app
  }),
  null
)(SettingsGeneral);
