import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { PlayerSdk } from "@api.video/player-sdk";
import { InformationCircleIcon } from "@heroicons/react/solid";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";

import SelectMenuWithCheck from "./selectMenuWithCheck";

import { addInitialData } from "../state/app";

const StreamStep3 = ({ state, dispatch, props }) => {
  const [apiVideoSDK, setApiVideoSDK] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [buttonProcessingId, setButtonProcessingId] = useState(false);
  const [numbersOfCurrentViewers, setNumbersOfCurrentViewers] = useState(0);
  const [salesGenerated, setSalesGenerated] = useState(0);
  const [openOptionsMenu, setOpenOptionsMenu] = useState([]);
  const [toggleShowLiveStream, setToggleShowLiveStream] = useState(false);
  const [listOfTeamMembers, setListOfTeamMembers] = useState(false);
  const [moderator, setModerator] = useState(false)
  //const [previousModeratorState, setPreviousModeratorState] = useState(false)
  //const [unsubscribeToQuestionsListener, setUnsubscribeToQuestionsListener] = useState()
  //let unsubscribeToQuestionsListener


  useEffect(() => {
    if (!apiVideoSDK) {
      const sdk = new PlayerSdk("#nuine-live-stream", {
        id: props.streamId,
        live: true,
        muted: true,
        hideControls: true,
        hideTitle: true,
        autoPlay: false,
      });
      setApiVideoSDK(sdk);
    }

    getListOfTeamMembers();

    listenForQuestions(props.streamId);
    listenForViewers(props.streamId);
    listenForSales(props.streamId);
    listenForModerator(props.streamId);
    listenForReactions(props.streamId)
  }, []);

  /*useEffect(() => {
    console.log("inside MODERATORE EFFECT: ", moderator)
    console.log(previousModeratorState)
    if (moderator !== previousModeratorState) {
      console.log("inside MODERATORE EFFECT INSIDDEEEEE: ", moderator)
      console.log(previousModeratorState)
      //unsubscribeToQuestionsListener()
      setOpenOptionsMenu([]);
      setQuestionList([]);
      listenForQuestions(props.streamId)
    }
  }, [moderator])*/


  const getListOfTeamMembers = () => {
    var getListOfTeamMembers = firebase
      .functions()
      .httpsCallable("getListOfTeamMembers");

    getListOfTeamMembers({
      brandId: state.brandId,
    }).then((result) => {
      setListOfTeamMembers(result.data);
      /*if (result.data.code === 200) {
      console.log("SUCCESS");
    }*/

      console.log("RESULT: ", result);
    });
  };

  console.log("moderatorId::::: ", moderator)

  const listenForQuestions = (streamId) => {
    //Listen for changes in the question sub collection
    let tempQuestionsArray = [];
    let tempArray = openOptionsMenu;
    console.log("streamId!!!!!!!!: ", streamId);

    firebase
      .firestore()
      .collection("questions")
      .doc(streamId)
      .collection("moderatorQuestions")
      .where("status", "==", "idle")
      .where("streamUserId", "==", state.uid)
      .orderBy("orderByThisDate")
      .onSnapshot(
        function (snapshot) {
          snapshot.forEach(function (doc) {
            /*snapshot.docChanges().forEach(function (change) {
          if (change.type === 'added') {
            console.log('New city: ', change.doc.data())
          }
          if (change.type === 'modified') {
            console.log('Modified city: ', change.doc.data())
          }
          if (change.type === 'removed') {
            console.log('Removed city: ', change.doc.data())
          }
        })*/

            console.log("question:: ", doc.data());
            console.log("ONE:: ", moderator);

            if (doc.data().moderatorId) {
              console.log("TWO:: ", doc.data().sentToStreamerAt);
              if (doc.data().sentToStreamerAt) {
                console.log("THREE:: ");
                let newObject = doc.data();
                newObject.questionId = doc.id;
                newObject.menuOpen = false;

                tempQuestionsArray.push(newObject);

                tempArray.push(false);
                //newTodos[index].isCompleted = !newTodos[index].isCompleted;
              }
              console.log("FOUR:: ");
            } else {
              console.log("FIVE:: ");
              let newObject = doc.data();
              newObject.questionId = doc.id;
              newObject.menuOpen = false;

              tempQuestionsArray.push(newObject);

              tempArray.push(false);
              //newTodos[index].isCompleted = !newTodos[index].isCompleted;
            }
            console.log("SIX:: ");


          });
          console.log("SEVEN:: ");
          setButtonProcessingId(false);
          setOpenOptionsMenu(tempArray);
          setQuestionList(tempQuestionsArray);

          tempQuestionsArray = [];
          tempArray = [];
        },
        (error) => {
          console.log("snapshot error: ", error);
        }
      );
  };

  const listenForViewers = (streamId) => {
    let numbersOfCurrentViewers = 0;
    firebase
      .firestore()
      .collection("views")
      .where("streamId", "==", streamId)
      .where("userId", "==", state.uid)
      .onSnapshot(function (snapshot) {
        console.log("inne");
        numbersOfCurrentViewers = 0;
        snapshot.forEach(function (doc) {
          if (doc.data().endTime === null) {
            numbersOfCurrentViewers++;
            console.log("more");
            /*} else {
            numbersOfCurrentViewers--;
            console.log("less");

            if (numbersOfCurrentViewers < 0) {
              numbersOfCurrentViewers = 0;
            }
          */
          }
          if (numbersOfCurrentViewers < 0) {
            numbersOfCurrentViewers = 0;
          }
        });
        console.log("cv: ", numbersOfCurrentViewers);
        setNumbersOfCurrentViewers(numbersOfCurrentViewers);
      });
  };

  //TODO: Set databaserule on Orders to avid user to access other records than he affect. Mov this to backend.
  const listenForSales = (streamId) => {
    let salesGenerated = 0;
    firebase
      .firestore()
      .collection("orders")
      .where("attributedToStreamId", "==", streamId)
      .where("attributedToUserId", "==", state.uid)
      .onSnapshot(function (snapshot) {
        console.log("inne sales");
        salesGenerated = 0;
        snapshot.forEach(function (doc) {
          salesGenerated++;
        });
        console.log("cv sales: ", salesGenerated);
        setSalesGenerated(salesGenerated);
      });
  };

  const listenForModerator = (streamId) => {
    firebase
      .firestore()
      .collection("streams")
      .doc(streamId)
      .collection("privateDetails")
      .doc(streamId)
      .onSnapshot((doc) => {
        console.log("inne moderator");
        console.log("MODERATIONS!!!! ", doc.data())
        if (doc.data().moderatorId) {
          if (doc.data().moderatorAcceptedAt) {
            console.log("inne moderatorId: true");
            //unsubscribeToQuestionsListener()
            let moderatorName = doc.data().moderatorFirstName + " " + doc.data().moderatorLastName
            //setPreviousModeratorState(moderator)

            dispatch(
              addInitialData({
                notification: { showNotification: true, type: "success", header: "Moderator accepted", message: `${moderatorName} is now moderating incoming questions` }
              })
            );
            setModerator(moderatorName)
          }
        } else {
          console.log("inne moderatorId: false");
          //TODO: Display that Moderator left the stream
          setModerator(false)
        }

      });
    console.log("cv sales: ", salesGenerated);
  };

  const listenForReactions = (streamId) => {
    console.log("listenForReactions: XXX")
    firebase
      .firestore()
      .collection("streams")
      .doc(streamId)
      .collection("privateDetails")
      .doc(streamId)
      .collection("reactions")
      .where("streamId", "==", streamId)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(function (change) {
          console.log(change.type)
          if (change.type === 'added') {
            console.log('New: ', change.doc.data())
            console.log('Reaction type: ', change.doc.data().reactionType)
          }
        })
      })
  };

  const removeModerator = () => {
    var removeModerator = firebase
      .functions()
      .httpsCallable("removeModerator");

    removeModerator({
      brandId: state.brandId,
      streamId: props.streamId,
    }).then((result) => {
      console.log(result);
      //unsubscribeToQuestionsListener()
      //setPreviousModeratorState(moderator)
      setModerator(false)
    });
  }

  const setQuestionStatus = (
    questionDocumentId,
    questionStatus,
    questionIndex,
    questionViwerId
  ) => {
    //openCloseOptionsMenu(questionIndex);
    setButtonProcessingId(questionIndex);
    var changeQuestionStatus = firebase
      .functions()
      .httpsCallable("changeQuestionStatus");

    changeQuestionStatus({
      questionId: questionDocumentId,
      newStatus: questionStatus,
      brandId: state.brandId,
      streamId: props.streamId,
      viewerId: questionViwerId,
    }).then((result) => {
      console.log(result);
      setButtonProcessingId(false);
    });
  };

  const banUser = (viewerId) => {
    console.log("BAN USER: ", viewerId);
    var banUser = firebase.functions().httpsCallable("banUser");

    banUser({
      viewerId: viewerId,
    }).then((result) => {
      console.log(result);
    });
  };

  const openCloseOptionsMenu = (index) => {
    const tempArray = questionList;
    const tempArrayAttribute = questionList[index].menuOpen;

    tempArray.map((i) => {
      i.menuOpen = false;
    });

    tempArray[index].menuOpen = !tempArrayAttribute;

    setQuestionList([...tempArray]);
  };

  return (
    <div className="h-full">
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="mx-px text-sm text-blue-700">
              When you are done streaming, just stop the stream in OBS
            </p>
          </div>
        </div>
      </div>
      {moderator &&
        <div className="rounded-md bg-blue-50 p-4 mt-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">{`${moderator} is moderating the questions`}</p>
              <p className="mt-3 text-sm md:mt-0 md:ml-6">
                <button className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600" onClick={() => removeModerator()}>Remove moderator</button>
              </p>
            </div>
          </div>
        </div>
      }


      <div className="py-6 h-full">
        <div className="flex flex-row justify-center flex-grow  h-full">
          <div className="flex-none h-full w-3/6 pr-8 bg-white">
            <div className="pb-5">
              <div className="pb-4 border-b">
                <p className="text-sm font-normal text-gray-900">
                  {`Current viewers: ${numbersOfCurrentViewers}`}
                </p>
                <p className="text-sm font-normal text-gray-900">
                  {`Sales generated: ${salesGenerated}`}
                </p>
              </div>
              {(listOfTeamMembers && !moderator) &&
                <>
                  <span className="block text-sm font-normal text-gray-700 pt-4 pb-2">
                    Ask a team member to moderate the questions:
                  </span>

                  <SelectMenuWithCheck
                    brandId={state.brandId}
                    streamId={props.streamId}
                    listOfTeamMembers={listOfTeamMembers}
                    brandName={state.brandName}
                  />
                </>
              }
              <h3 className="mt-4">Questions</h3>
            </div>

            <ul className="-my-5 divide-y divide-gray-200 overflow-scroll h-full pb-96">
              {questionList.map((listItem, i) => {
                return (
                  <li className="py-5" key={i}>
                    {/*<p className="text-sm mb-0.5">
                            {`User ID: ${listItem.viewerId}`}
                    </p>*/}
                    <p className="font-base text-gray-500 text-xs">
                      {new Date(listItem.orderByThisDate * 1000).toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </p>
                    <p className="text-base font-base mb-3">
                      {listItem.question}
                    </p>
                    <div className="flex justify-between mt-2">
                      <button
                        type="button"
                        onClick={() =>
                          setQuestionStatus(
                            listItem.questionId,
                            "answered",
                            i,
                            listItem.viewerId
                          )
                        }
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {buttonProcessingId === i ? (
                          <svg
                            className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          "Answered"
                        )}
                      </button>

                      <div className="relative inline-block text-left">
                        <div>
                          <button
                            type="button"
                            className="inline-flex items-center px-2.5 py-1.5 rounded border border-gray-300 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 shadow-sm bg-white focus:ring-indigo-500"
                            id="options-menu"
                            aria-expanded="true"
                            aria-haspopup="true"
                            onClick={() => {
                              openCloseOptionsMenu(i);
                            }}
                          >
                            {buttonProcessingId === i ? (
                              <svg
                                className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  strokeWidth="4"
                                ></circle>
                                <path
                                  className="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            ) : (
                              <>
                                Options
                                <svg
                                  className="-mr-1 ml-2 h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </>
                            )}
                          </button>
                        </div>
                        <Transition
                          show={listItem.menuOpen}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                          className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10 w-64"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          <div className="py-1" role="none">
                            <button
                              className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                              role="menuitem"
                              onClick={() => {
                                setQuestionStatus(
                                  listItem.questionId,
                                  "skipped",
                                  i,
                                  listItem.viewerId
                                );
                              }}
                            >
                              <svg
                                className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path
                                  fillRule="evenodd"
                                  d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              Skip question
                            </button>
                            <button
                              className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                              role="menuitem"
                              onClick={() => {
                                setQuestionStatus(
                                  listItem.questionId,
                                  "support",
                                  i,
                                  listItem.viewerId
                                );
                              }}
                            >
                              <svg
                                className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                              </svg>
                              Mark as support question
                            </button>
                          </div>
                          <div className="py-1" role="none">
                            <button
                              className="group flex items-center px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-900 w-full"
                              role="menuitem"
                              onClick={() => {
                                banUser(listItem.viewerId);
                              }}
                            >
                              <svg
                                className="mr-3 h-5 w-5 text-gray-600 group-hover:text-gray-900"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                                <path
                                  fillRule="evenodd"
                                  d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              Ban user
                            </button>
                          </div>
                        </Transition>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className={
              toggleShowLiveStream
                ? "flex flex-col w-3/6"
                : "flex justify-center flex-col w-3/6 h-14"
            }
          >
            <div
              className={
                toggleShowLiveStream
                  ? "relative w-full pb-562 mb-5"
                  : "relative w-full p-0 mb-5"
              }
            >
              <div
                id="nuine-live-stream"
                className={
                  toggleShowLiveStream
                    ? "absolute h-full w-full flex justify-center items-end top-0 left-0"
                    : "hidden absolute h-full w-full justify-center items-end top-0 left-0"
                }
              ></div>
            </div>
            <button
              className="secondary-button z-50"
              onClick={() => setToggleShowLiveStream(!toggleShowLiveStream)}
            >
              {toggleShowLiveStream ? "Hide livestream" : "Show livestream"}
            </button>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <div className="w-10 h-10 bg-red-700 z-50"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(StreamStep3);
