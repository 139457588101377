import React from "react";

import DashboardLayout from "../components/dashboardLayout";

import { connect } from "react-redux";

import SalesrepAndAdvocateDashboard from "../components/salesrepAndAdvocateDashboard";
import AdminAndManagerDashboard from "../components/adminAndManagerDashboard";

const Dashboard = ({ state }) => {
  return (
    <DashboardLayout>
      {state.userRole === "admin" && <AdminAndManagerDashboard />}
      {state.userRole === "manager" && <AdminAndManagerDashboard />}
      {state.userRole === "sales-rep" && <SalesrepAndAdvocateDashboard />}
      {state.userRole === "advocate" && <SalesrepAndAdvocateDashboard />}
    </DashboardLayout>
  );
};

export default connect(
  (state) => ({
    state: state.app
  }),
  null
)(Dashboard);
