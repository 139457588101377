import React, { useState, useEffect, useRef } from "react";
import { useFormik, Formik, Field, Form, ErrorMessage } from "formik";
import { Switch } from "@headlessui/react";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";
import SettingsMenu from "../components/settingsMenu";
import DummyQuestionList from "../components/dummyQuestionList";

import { addInitialData } from "../state/app";

import * as Yup from "yup";

import ColorPicker from "../components/colorPicker";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SettingsWidget = ({ state, dispatch }) => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [colorState, setColorState] = useState(state.widgetColors);
  const [borderRadiusState, setBorderRadiusState] = useState(
    state.widgetBorderRadius
  );
  const [prodModeEnabled, setProdModeEnabled] = useState(
    state.widgetIsActivated
  );
  const [devModeEnabled, setDevModeEnabled] = useState(
    state.widgetIsInDevelopmentMode
  );


  console.log("state.widgetIsActivated: ", state.widgetIsActivated);
  console.log(
    "state.widgetIsInDevelopmentMode: ",
    state.widgetIsInDevelopmentMode
  );

  const updateSecurity = (inputParameter) => {
    let tempDevModeEnabled = devModeEnabled;
    let tempProdModeEnabled = prodModeEnabled;

    if (inputParameter === "dev") {
      tempDevModeEnabled = !tempDevModeEnabled;
      setDevModeEnabled(tempDevModeEnabled);
    } else if (inputParameter === "prod") {
      tempProdModeEnabled = !tempProdModeEnabled;
      setProdModeEnabled(tempProdModeEnabled);
    }

    var updateSecurity = firebase.functions().httpsCallable("updateSecurity");

    updateSecurity({
      brandId: state.brandId,
      widgetIsInDevelopmentMode: tempDevModeEnabled,
      widgetIsActivated: tempProdModeEnabled,
    })
      .then((result) => {
        console.log("result XXX: ", result);
      })
      .catch((error) => {
        console.log(error);
      });

    console.log("dwfdsfdsf");
  };

  useEffect(() => {
    console.log("FFFFFFFFF: ", state)
    setColorState(state.widgetColors)
    setBorderRadiusState(state.widgetBorderRadius)
  }, [state.widgetBorderRadius, state.widgetColors])

  const formRef = useRef()

  const validateAcceptedURLs = (values) => {
    console.log(values);

    const errors = {};

    if (!values.acceptedURLs) {
      errors.acceptedURLs = "Required";
    }

    console.log(errors);
    return errors;
  };

  const validateBrandCustomization = (values) => {
    console.log(values);

    const errors = {};

    if (!values.widgetCategories) {
      errors.widgetCategories = "Required";
    }

    console.log(errors);
    return errors;
  };

  const formikAcceptedURLs = useFormik({
    enableReinitialize: true,
    initialValues: {
      acceptedURLs: state.acceptedURLs && state.acceptedURLs.toString() || "",
    },
    validate: validateAcceptedURLs,
    onSubmit: (values, { resetForm }) => {
      console.log("VALUE: ", values.acceptedURLs);

      var acceptedURLsArray = values.acceptedURLs
        .split(",")
        .map((item) => item.trim().toLowerCase());

      console.log("VALUE: ", values);
      console.log("ARRRAY: ", acceptedURLsArray);

      var updateAcceptedURLs = firebase
        .functions()
        .httpsCallable("updateAcceptedURLs");

      updateAcceptedURLs({
        brandId: state.brandId,
        formValues: acceptedURLsArray,
      })
        .then((result) => {
          if (result.data.code === 200) {
            dispatch(
              addInitialData({
                notification: { showNotification: true, type: "success", header: "Your brand customization details was updated", message: false }
              })
            );
          } else {
            console.log("Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //resetForm({ widgetCategories: "" });
    },
  });

  const formikCustomizationDetails = useFormik({
    enableReinitialize: true,
    initialValues: {
      widgetCategories: state.widgetCategories && state.widgetCategories.toString() || "",
    },
    validate: validateBrandCustomization,
    onSubmit: (values, { resetForm }) => {
      console.log("VALUE: ", values.widgetCategories);

      var categoriesArray = values.widgetCategories
        .split(",")
        .map((item) => item.trim().toLowerCase());

      console.log("VALUE: ", values);
      console.log("ARRRAY: ", categoriesArray);

      var updateBrandCustomization = firebase
        .functions()
        .httpsCallable("updateBrandCustomization");

      updateBrandCustomization({
        brandId: state.brandId,
        formValues: categoriesArray,
      })
        .then((result) => {
          if (result.data.code === 200) {
            dispatch(
              addInitialData({
                notification: { showNotification: true, type: "success", header: "Your brand customization details was updated", message: false }
              })
            );
          } else {
            console.log("Error");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      //resetForm({ widgetCategories: "" });
    },
  });

  const setColor = (hexColor, colorLocation, displayName) => {
    setColorState({
      ...colorState,
      [colorLocation]: {
        hexColor: hexColor,
        displayName: displayName,
        variableName: colorLocation,
      },
    });
    console.log("colorState: ", colorState);
  };

  const setBorderRadius = (borderRadiusLocation, borderRadius) => {
    //const borderRadiusPx = borderRadius.toString() + "px";
    //console.log("borderRadiusPx:", borderRadiusPx);
    setBorderRadiusState({
      ...borderRadiusState,
      [borderRadiusLocation]: {
        borderRadius: borderRadius,
        variableName: borderRadiusLocation,
      },
    });
    console.log("colorState: ", borderRadiusState);
  };

  const updateCustomizedUI = (formValues) => {
    var updateWidgetUI = firebase.functions().httpsCallable("updateWidgetUI");

    updateWidgetUI({
      brandId: state.brandId,
      widgetColors: colorState,
      widgetBorderRadius: borderRadiusState,
    })
      .then((result) => {
        if (result.data.code === 200) {
          console.log("second: ", borderRadiusState);
          dispatch(
            addInitialData({
              notification: { showNotification: true, type: "success", header: "Widget customization was updated", message: false }
            })
          );
        } else {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    //resetForm({ widgetCategories: "" });
  };

  const resetCustomizations = () => {
    var resetWidgetCustomizations = firebase.functions().httpsCallable("resetWidgetCustomizations");

    resetWidgetCustomizations({
      brandId: state.brandId,
    })
      .then((result) => {
        if (result.data.code === 200) {
          console.log("second: ",);
          //window.location.reload()
        } else {
          console.log("Error");
          //window.location.reload()
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div>
      <DashboardLayout>
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <SettingsMenu page="widget" />
          {(state.userRole === "admin" || state.userRole === "manager") && (
            <>

              <div className="cardContainerOuter border-b">
                <div className="cardContainerInner">
                  <div>
                    <h2>Security</h2>
                  </div>
                  <div className="grid grid-cols-6 gap-6">
                    <form className="col-span-6">
                      <div>
                        <Switch.Group
                          as="div"
                          className="flex items-center justify-between"
                        >
                          <div className="flex-grow flex flex-col">
                            <Switch.Label
                              as="label"
                              passive
                            >
                              Activate{" "}
                              <span className="font-bold">
                                nuine
                              </span>{" "}
                              only on{" "}
                              accepted URLs
                            </Switch.Label>
                            <Switch.Description
                              as="p"
                              className="text-base text-gray-600 pr-48"
                            >
                              Turn this on when you are done testing. This will also prevent the stream to be used outside your domains.
                            </Switch.Description>
                          </div>
                          <Switch
                            checked={prodModeEnabled}
                            onChange={() => {
                              updateSecurity("prod");
                            }}
                            className={classNames(
                              prodModeEnabled ? "bg-coral-500" : "bg-gray-200",
                              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                prodModeEnabled
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              )}
                            />
                          </Switch>
                        </Switch.Group>
                      </div>
                      <div className="pt-4">
                        <Switch.Group
                          as="div"
                          className="flex items-center justify-between"
                        >
                          <span className="flex-grow flex flex-col">
                            <Switch.Label
                              as="label"
                              passive
                            >
                              Development mode
                            </Switch.Label>
                            <Switch.Description
                              as="p"
                              className="text-base text-gray-600 pr-48"
                            >
                              Turn this on under development to not be charged for test streams. This makes nuine available on all
                              URLs so you can test in your development environment.
                            </Switch.Description>
                          </span>
                          <Switch
                            checked={devModeEnabled}
                            onChange={() => {
                              updateSecurity("dev");
                            }}
                            className={classNames(
                              devModeEnabled ? "bg-coral-500" : "bg-gray-200",
                              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                devModeEnabled
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              )}
                            />
                          </Switch>
                        </Switch.Group>
                      </div>
                    </form>
                    <form onSubmit={formikAcceptedURLs.handleSubmit} className="col-span-6 sm:col-span-6 pt-8">
                      <label htmlFor="acceptedURLs">Accepted URLs</label>
                      <p className="label-description mb-2">URLs where you will use <b>nuine</b>. Add as many as you like. Separate by comma (",").</p>
                      <div className="mt-1">
                        <textarea
                          type="textarea"
                          rows="4"
                          name="acceptedURLs"
                          id="acceptedURLs"
                          onChange={formikAcceptedURLs.handleChange}
                          onBlur={formikAcceptedURLs.handleBlur}
                          value={
                            formikAcceptedURLs.values
                              .acceptedURLs
                          }
                          className={`${formikAcceptedURLs.errors
                            .acceptedURLs &&
                            formikAcceptedURLs.touched
                              .acceptedURLs
                            ? "border-red-800"
                            : "border-gray-300"
                            } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                        />
                        {formikAcceptedURLs.errors
                          .acceptedURLs &&
                          formikAcceptedURLs.touched
                            .acceptedURLs && (
                            <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                              {
                                formikAcceptedURLs.errors
                                  .acceptedURLs
                              }
                            </span>
                          )}
                      </div>
                      <div className="pb-4 text-right sm:pb-6 pt-12">
                        <button type="submit" className="primary-button">
                          Save
                        </button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>

              <form onSubmit={formikCustomizationDetails.handleSubmit}>
                <div className="cardContainerOuter border-b pb-12">
                  <div className="cardContainerInner">
                    <div>
                      <h2>Categories</h2>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6">
                        <label htmlFor="widgetCategories">Categories</label>
                        <p className="mb-2 text-sm">Add categories here</p>
                        <div className="mt-1">
                          <textarea
                            type="textarea"
                            rows="4"
                            name="widgetCategories"
                            id="widgetCategories"
                            onChange={formikCustomizationDetails.handleChange}
                            onBlur={formikCustomizationDetails.handleBlur}
                            value={
                              formikCustomizationDetails.values
                                .widgetCategories
                            }
                            className={`${formikCustomizationDetails.errors
                              .widgetCategories &&
                              formikCustomizationDetails.touched
                                .widgetCategories
                              ? "border-red-800"
                              : "border-gray-300"
                              } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                          />
                          {formikCustomizationDetails.errors
                            .widgetCategories &&
                            formikCustomizationDetails.touched
                              .widgetCategories && (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {
                                  formikCustomizationDetails.errors
                                    .widgetCategories
                                }
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pr-12 pb-4 text-right sm:pr-12 sm:pb-6">
                    <button type="submit" className="primary-button">
                      Save
                    </button>
                  </div>
                </div>
              </form>
              <div className="cardContainerOuter">
                <div className="cardContainerInner">
                  <div className="mb-4">
                    <h2>Customize</h2>
                    <h3 className="mt-8">Change colors</h3>
                  </div>
                  <div className=" flex flex-row ">
                    <div className="w-2/3">


                      {/*<div className="col-span-2">
                      <p>Q&A background color</p>
                    </div>
                    <div className="col-span-4">
                      <ColorPicker
                        setColor={setColor}
                        color={colorState.chatBackgroundColor}
                      />
                    </div>*/}
                      {Object.keys(colorState).map((key, i) => {
                        return (
                          <div className="grid grid-cols-6 gap-6 mb-4" key={i}>
                            <div className="col-span-3 flex items-center">
                              <label className="font-normal">{colorState[key].displayName}</label>
                            </div>
                            <div className="col-span-3">
                              <ColorPicker
                                setColor={setColor}
                                colorLocation={key}
                                displayName={colorState[key].displayName}
                                hexColor={colorState[key].hexColor}
                              />
                            </div>
                          </div>
                        );
                      })}

                      <div className="py-12 pb-0">
                        <h3 className="mb-8">Change border radius</h3>
                        <div>
                          <Formik
                            innerRef={formRef}
                            initialValues={{
                              submitButton:
                                borderRadiusState.submitButton.borderRadius || "",
                              formInput:
                                borderRadiusState.formInput.borderRadius || "",
                              questionBackground:
                                borderRadiusState.questionBackground
                                  .borderRadius || "",
                            }}
                            validationSchema={Yup.object({
                              submitButton: Yup.string().required("Required"),
                              formInput: Yup.string().required("Required"),
                              questionBackground:
                                Yup.string().required("Required"),
                            })}
                            onSubmit={async (
                              values,
                              { setSubmitting, resetForm }
                            ) => {
                              setFormSubmitting(true);
                              updateCustomizedUI(values);
                              //updateBillingAddress(values);
                              //setSubmitting(false);
                              setFormSubmitting(false);
                            }}
                          >
                            {({
                              errors,
                              touched,
                              setFieldValue,
                              isValidating,
                            }) => (
                              <Form className="grid grid-cols-6 gap-6">
                                <div className="col-span-3 flex items-center">
                                  <label className="font-normal" htmlFor="submitButton">
                                    Submit button
                                  </label>
                                </div>
                                <div className="col-span-2 flex flex-row items-center justify-center">
                                  <div className="relative rounded-md mt-1 mr-2">
                                    <Field
                                      type="number"
                                      name="submitButton"
                                      value={
                                        borderRadiusState.submitButton
                                          .borderRadius
                                      }
                                      onChange={(e) => {
                                        setBorderRadius(
                                          "submitButton",
                                          e.target.value
                                        );
                                      }}
                                      //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                      className={`${errors.submitButton &&
                                        touched.submitButton
                                        ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                        : "border border-gray-300 focus:ring-black focus:border-black"
                                        } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                                    />
                                  </div>
                                  <p>px</p>

                                  <ErrorMessage name="submitButton">
                                    {(error) => (
                                      <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                        {error}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </div>
                                <div className="col-span-1" />
                                <div className="col-span-3 flex items-center">
                                  <label className="font-normal" htmlFor="formInput">
                                    Question input form
                                  </label>
                                </div>
                                <div className="col-span-2 flex flex-row items-center justify-center">
                                  <div className="relative rounded-md mt-1 mr-2">
                                    <Field
                                      type="number"
                                      name="formInput"
                                      value={
                                        borderRadiusState.formInput.borderRadius
                                      }
                                      onChange={(e) => {
                                        setBorderRadius(
                                          "formInput",
                                          e.target.value
                                        );
                                      }}
                                      //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                      className={`${errors.formInput && touched.formInput
                                        ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                        : "border border-gray-300 focus:ring-black focus:border-black"
                                        } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                                    />
                                  </div>
                                  <p>px</p>

                                  <ErrorMessage name="formInput">
                                    {(error) => (
                                      <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                        {error}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </div>
                                <div className="col-span-1" />
                                <div className="col-span-3 flex items-center">
                                  <label className="font-normal" htmlFor="questionBackground">
                                    Question background
                                  </label>
                                </div>
                                <div className="col-span-2 flex flex-row items-center justify-center">
                                  <div className="relative rounded-md mt-1 mr-2">
                                    <Field
                                      type="number"
                                      name="questionBackground"
                                      value={
                                        borderRadiusState.questionBackground
                                          .borderRadius
                                      }
                                      onChange={(e) => {
                                        setBorderRadius(
                                          "questionBackground",
                                          e.target.value
                                        );
                                      }}
                                      //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                      className={`${errors.questionBackground &&
                                        touched.questionBackground
                                        ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                        : "border border-gray-300 focus:ring-black focus:border-black"
                                        } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                                    />
                                  </div>
                                  <p>px</p>

                                  <ErrorMessage name="questionBackground">
                                    {(error) => (
                                      <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                        {error}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </div>
                                <div className="col-span-1" />
                                {/*<div className="col-span-6 pr-12 text-right sm:pr-12">
                                  <button
                                    type="submit"
                                    className="primary-button"
                                  >
                                    Save
                                  </button>
                                    </div>*/}
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/3 h-96">
                      <DummyQuestionList widgetColors={colorState} widgetBorderRadius={borderRadiusState} />
                    </div>
                  </div>
                  <div className="col-span-6 text-right">
                    <button
                      onClick={() => resetCustomizations()}
                      className="secondary-button mr-4"
                    >
                      Reset settings
                    </button>
                    <button
                      onClick={() => formRef.current.handleSubmit()}
                      className="primary-button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

      </DashboardLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    state: state.app,
  }),
  null
)(SettingsWidget);
