import React, { useEffect } from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../components/privateRoute";
import Dashboard from "../app/dashboard";
import SettingsGeneral from "../app/settingsGeneral";
import SettingsWidget from "../app/settingsWidget";
import SettingsPassword from "../app/settingsPassword";
import SettingsPlan from "../app/settingsPlan";
import SettingsBilling from "../app/settingsBilling";
import LiveStreams from "../app/liveStreams";
import LiveStreamDetails from "../app/liveStreamDetails";
import Apply from "../app/apply";
import Stream from "../app/stream";
import Stream2 from "../app/stream2";
import StreamCustomization from "../app/streamCustomization";
import StreamDetails from "../app/streamDetails";
import StreamPreview from "../app/streamPreview";
import StreamActive from "../app/streamActive";
import StreamEnded from "../app/streamEnded";
import DocumentationSetupMainWidget from "../app/documentationSetupMainWidget";
import DocumentationSetupEventListeners from "../app/documentationSetupEventListeners";
import DocumentationStream from "../app/documentationStream";
import Setup from "../app/setup";
import Team from "../app/team";
import Notification from "../components/notification";

import { connect } from "react-redux";

import { addUserId, addInitialData, toggleAppIsReady, removeAllData } from "../state/app";

import { navigate } from "gatsby-link";

import firebase from "./../utilities/firebase.js";
import firebaseFunction from "./../utilities/firebaseFunction.js";

const App = ({ user, dispatch, state, appIsReady }) => {

  useEffect(() => {
    let unsubscribeToAuthChanges = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        dispatch(
          addUserId({
            uid: user.uid
          })
        );
      } else {
        dispatch(
          removeAllData()
        )
        navigate("/login");
      }
    })
    return () => {
      unsubscribeToAuthChanges()
    }
  }, [firebase])

  useEffect(() => {
    console.log("1")
    let unsubscribeToUserBrandChanges = () => { }
    if (state.uid) {
      console.log("2")
      unsubscribeToUserBrandChanges = firebase.firestore().collection("userBrandAssociations").where("userId", "==", state.uid).where("defaultBrandForThisUser", "==", true).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          dispatch(
            addInitialData({
              ...doc.data()
            })
          );
        })
      })
    }
    return () => {
      unsubscribeToUserBrandChanges()
    }
  }, [state.uid])

  useEffect(() => {
    console.log("3")
    if (state.brandId && !state.streamId) {
      console.log("4")
      dispatch(
        toggleAppIsReady({
          appIsReady: true
        })
      )
    }
  }, [state.brandId])

  useEffect(() => {
    console.log("5")
    let unsubscribeToStreamsChanges = () => { }
    let unsubscribeToSecretStreamKeyChanges = () => { }
    if (state.streamId) {
      console.log("6")
      unsubscribeToStreamsChanges = firebase.firestore().collection("streams").doc(state.streamId).collection("privateDetails").doc(state.streamId).onSnapshot((doc) => {
        console.log("8")
        dispatch(
          addInitialData({
            ...doc.data()
          })
        );
      })
      console.log("7")
      unsubscribeToSecretStreamKeyChanges = firebase.firestore().collection("streams").doc(state.streamId).collection("privateDetails").doc(state.streamId).collection("secretStreamKey").doc(state.streamId).onSnapshot((doc) => {
        console.log("9")
        dispatch(
          addInitialData({
            ...doc.data()
          })
        );
        dispatch(
          toggleAppIsReady({
            appIsReady: true
          })
        )
      })
    }
    return () => {
      unsubscribeToStreamsChanges()
      unsubscribeToSecretStreamKeyChanges()
    }
  }, [state.streamId])

  useEffect(() => {
    console.log("10x")
    if (state.subscriptionId) {
      setTimeout(() => {
        firebaseFunction("updateSubscriptionStatus", { subscriptionId: state.subscriptionId, brandId: state.brandId })
      }, 1500)
    }

  }, [state.subscriptionStatus])

  console.log("USER REDUX LOADED: ", state);

  return (
    /*<Layout>*/
    appIsReady ? (
      <>
        <div className="hidden md:block">
          <Notification
          /*showNotification={state.notification?.active ? state.notification?.active : false}
          type={state.notification?.type}
          header={state.notification?.header}
          message={state.notification?.message}*/
          />
          <Router>
            <PrivateRoute path="/app/dashboard" component={Dashboard} />
            <PrivateRoute
              path="/app/settings/general"
              component={SettingsGeneral}
            />
            <PrivateRoute
              path="/app/settings/widget"
              component={SettingsWidget}
            />
            <PrivateRoute
              path="/app/settings/password"
              component={SettingsPassword}
            />
            <PrivateRoute path="/app/settings/plan" component={SettingsPlan} />
            <PrivateRoute
              path="/app/settings/billing"
              component={SettingsBilling}
            />
            {/*<PrivateRoute path="/app/prospects" component={Prospects} />
            <PrivateRoute path="/app/advocates" component={Advocates} />*/}
            <PrivateRoute path="/app/live-streams" component={LiveStreams} />
            <PrivateRoute
              path="/app/live-streams/*"
              component={LiveStreamDetails}
            />
            <PrivateRoute path="/app/apply" component={Apply} />
            {/*<PrivateRoute path="/app/stream2" component={Stream} />*/}
            <PrivateRoute path="/app/stream/customize" component={StreamCustomization} />
            <PrivateRoute path="/app/stream/details" component={StreamDetails} />
            <PrivateRoute path="/app/stream/preview" component={StreamPreview} />
            <PrivateRoute path="/app/stream/active" component={StreamActive} />
            <PrivateRoute path="/app/stream/ended" component={StreamEnded} />

            {/*<PrivateRoute path="/app/stream" component={Stream2} />*/}
            <PrivateRoute path="/app/docs/setup/main-widget" component={DocumentationSetupMainWidget} />
            <PrivateRoute path="/app/docs/setup/event-listeners" component={DocumentationSetupEventListeners} />
            <PrivateRoute path="/app/docs/stream" component={DocumentationStream} />
            {/*<PrivateRoute path="/app/setup" component={Setup} />*/}
            <PrivateRoute path="/app/team" component={Team} />
          </Router>
        </div>
        <div className="md:hidden">
          <div className="flex justify-center items-center h-screen">
            <p>Must be viewed on screens larger than 767px</p>
          </div>
        </div>
      </>
    ) : (
      <div className="flex justify-center items-center h-screen">
        <svg
          className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        {/*<p className="ml-2">Loading...</p>*/}
      </div>
    )
    /*</Layout>*/
  );
};

export default connect(
  (state) => ({
    appIsReady: state.app.appIsReady,
    state: state.app
  }),
  null
)(App);
