import React, { useState } from "react";
import { SketchPicker } from "react-color";

const ColorPicker = (props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  return (
    <div>
      <div className="flex flex-row">
        <div
          className="w-10 h-10 rounded-md mr-4"
          style={{ backgroundColor: props.hexColor }}
        ></div>
        <button
          className="secondary-button"
          onClick={() => {
            setDisplayColorPicker(!displayColorPicker);
          }}
        >
          Change color
        </button>
      </div>
      {displayColorPicker && (
        <SketchPicker
          className="mt-4 mb-8"
          onChange={(color) => {
            props.setColor(color.hex, props.colorLocation, props.displayName);
          }}
          color={props.hexColor}
          disableAlpha={true}
          presetColors={[]}
        />
      )}
    </div>
  );
};

export default ColorPicker;
