import React, { useState } from "react";
import { connect } from "react-redux";

const DummyQuestionList = (props) => {
    const [questionList, setQuestionList] = useState([{ question: "Is it possible to change the color of the widget?", createdAt: "8:32", status: "idle", documentId: "gdf7dh3jjfs9376" }, { question: "Can it be customized?", createdAt: "8:47", status: "idle", documentId: "fd4H4sd8Fgh79" }])
    const [copy, setCopy] = useState({
        header: "Your questions",
        questionInputPlaceholder: "Ask a question",
        questionTooFewCharsError:
            "Must be 10 characters or more. The better the question, the better the answer.",
        questionRequiredError: "Required",
        noStreamersError:
            "Currently there are no active streamers. Please try again later.",
        submitQuestionButton: "Submit",
        submittingQuestionButton: "Submitting...",
        leaveButton: "Leave",
        closingStream: "Closing window..",
        startingStream: "Hold on, fetching stream",
        questionStatusAnswered: "Answered in the stream",
        questionStatusSkipped: "This question was skipped by the presenter",
        questionStatusSupport:
            "This is marked as a support question. Please see our support pages",
        questionStatusSupportLinkCopy: "here",
        whoIsStreaming: "is streaming on behalf of",
    })
    const [dummyQuestion, setDummyQuestion] = useState("")

    console.log("XXXYYY::: ", props)
    return (
        <>
            <div
                className={"flex bg-white flex-col h-full border-2 border-black rounded-md p-3"}
                style={{
                    background:
                        props.widgetColors.chatBackground
                            .hexColor,
                }}
            >
                <div
                    className={
                        "flex flex-col justify-between h-full"
                    }
                >
                    <div style={{ height: "100%", position: "relative" }}>
                        {questionList !== undefined &&
                            questionList.length > 0 && (
                                <h3
                                    className={"text-base mb-0 font-medium mt-0"}
                                    style={{
                                        color:
                                            props.widgetColors.headerText
                                                .hexColor,
                                    }}
                                >
                                    {copy.header}
                                </h3>
                            )}
                        <div
                            className={"overflow-scroll h-full w-full absolute"}
                        >
                            {questionList.length >= 4 && (
                                <div
                                    className={"h-8 w-full sticky top-0 z-50"}
                                    style={{
                                        background: `linear-gradient(180deg,${props.widgetColors.chatBackground.hexColor}FF 0%,${props.widgetColors.chatBackground.hexColor}00 80%)`,
                                    }}
                                ></div>
                            )}

                            <ul
                                className={"list-none p-0 m-0"}
                            >
                                {questionList.map((listItem, i) => {
                                    return (
                                        <li
                                            style={{
                                                paddingTop: "0.5rem",
                                                paddingBottom: "0rem",
                                            }}
                                            key={i}
                                        >
                                            <div style={{ position: "relative" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontWeight: "400",
                                                            fontSize: "0.7rem",
                                                            margin: "0rem",
                                                            color:
                                                                props.widgetColors
                                                                    .timeText.hexColor,
                                                            marginLeft: "0.8rem",
                                                        }}
                                                    >
                                                        {/* Extend touch target to entire panel */}
                                                        {listItem.createdAt}
                                                    </p>
                                                    <div
                                                        style={{
                                                            marginRight: "0.2rem",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            alignSelf: "flex-start"
                                                        }}
                                                    >
                                                        <p
                                                            className={"mt-0 text-base leading-4 pb-2 break-words"}
                                                            style={{
                                                                backgroundColor:
                                                                    props.widgetColors
                                                                        .questionBackground.hexColor,
                                                                color:
                                                                    props.widgetColors
                                                                        .questionText.hexColor,
                                                                borderRadius:
                                                                    props.widgetBorderRadius.questionBackground.borderRadius.toString() +
                                                                    "px",
                                                                padding: "0.555rem 0.9rem",
                                                            }}
                                                        >
                                                            {listItem.question}
                                                        </p>

                                                        {listItem.documentId &&
                                                            listItem.status !== "answered" &&
                                                            listItem.status !== "answeredWithText" &&
                                                            listItem.status !== "skipped" &&
                                                            listItem.status !== "support" &&
                                                            (listItem.documentId ? (
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        console.log("Dummy delete")
                                                                    }
                                                                    style={{
                                                                        display: "inline-flex",
                                                                        zIndex: "10",
                                                                        backgroundColor:
                                                                            props.widgetColors.chatBackground
                                                                                .hexColor,
                                                                        minWidth: "40px",
                                                                        minHeight: "20px",
                                                                        color:
                                                                            props.widgetColors.deleteIcon
                                                                                .hexColor,
                                                                        fontSize: "0.75rem",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        borderRadius: "5rem",
                                                                        padding: "0",
                                                                        border: "none",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <svg
                                                                        style={{
                                                                            width: "1rem",
                                                                            height: "1rem",
                                                                        }}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth={2}
                                                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            ) : (
                                                                <>
                                                                    <svg
                                                                        className="nuine-misc-8"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <circle
                                                                            style={{ opacity: "0.25" }}
                                                                            cx="12"
                                                                            cy="12"
                                                                            r="10"
                                                                            stroke="currentColor"
                                                                            strokeWidth="4"
                                                                        ></circle>
                                                                        <path
                                                                            style={{ opacity: "0.75" }}
                                                                            fill="currentColor"
                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                        ></path>
                                                                    </svg>{" "}
                                                                    {/*copy.submittingQuestionButton*/}
                                                                </>
                                                            ))}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "0.25rem",
                                                        marginBottom: "0.25rem",
                                                        justifyContent: "end",
                                                        paddingLeft: "65px"
                                                    }}
                                                >
                                                    {listItem.status === "answered" ? (
                                                        <span
                                                            style={{
                                                                display: "inline-flex",
                                                                padding: "6px 14px",
                                                                backgroundColor: "#D1FAE5",
                                                                color: "#065F46",
                                                                fontSize: "0.85rem",
                                                                lineHeight: "1.25rem",
                                                                fontWeight: "400",
                                                                alignItems: "center",
                                                                borderRadius: "15px",
                                                            }}
                                                        >
                                                            {copy.questionStatusAnswered}
                                                        </span>
                                                    ) : listItem.status === "answeredWithText" ? (
                                                        <span
                                                            style={{
                                                                display: "inline-flex",
                                                                padding: "6px 14px",
                                                                backgroundColor: "#D1FAE5",
                                                                color: "#065F46",
                                                                fontSize: "0.85rem",
                                                                lineHeight: "1.25rem",
                                                                fontWeight: "400",
                                                                alignItems: "center",
                                                                borderRadius: "15px",
                                                            }}
                                                        >
                                                            {listItem.answer ? listItem.answer : "Answered"}
                                                        </span>
                                                    ) : listItem.status === "skipped" ? (
                                                        <span
                                                            style={{
                                                                display: "inline-flex",
                                                                padding: "6px 14px",
                                                                backgroundColor: "#FEF3C7",
                                                                color: "#92400E",
                                                                fontSize: "0.85rem",
                                                                lineHeight: "1.25rem",
                                                                fontWeight: "400",
                                                                alignItems: "center",
                                                                borderRadius: "15px",
                                                            }}
                                                        >
                                                            {copy.questionStatusSkipped}
                                                        </span>
                                                    ) : listItem.status === "support" ? (
                                                        props.streamDetails.supportsiteURL ? (
                                                            <span
                                                                style={{
                                                                    //display: "inline-flex",
                                                                    padding: "6px 14px",
                                                                    backgroundColor: "#FEF3C7",
                                                                    color: "#92400E",
                                                                    fontSize: "0.85rem",
                                                                    lineHeight: "1.25rem",
                                                                    fontWeight: "400",
                                                                    borderRadius: "15px",
                                                                }}
                                                            >
                                                                {copy.questionStatusSupport}{" "}
                                                                <a
                                                                    style={{
                                                                        textDecoration: "underline",
                                                                        fontSize: "0.85rem",
                                                                    }}
                                                                    href={
                                                                        props.streamDetails
                                                                            .supportsiteURL
                                                                    }
                                                                    target="_blank"
                                                                >
                                                                    {
                                                                        this.state.copy
                                                                            .questionStatusSupportLinkCopy
                                                                    }
                                                                </a>
                                                                .
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    display: "inline-flex",
                                                                    padding: "6px 14px",
                                                                    backgroundColor: "#FEF3C7",
                                                                    color: "#92400E",
                                                                    fontSize: "0.85rem",
                                                                    lineHeight: "1rem",
                                                                    fontWeight: "400",
                                                                    alignItems: "center",
                                                                    borderRadius: "15px",
                                                                }}
                                                            >
                                                                {copy.questionStatusSupport}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            {questionList.length >= 4 && (
                                <div
                                    className={"h-8 w-full sticky z-50 bottom-4"}
                                    style={{
                                        background: `linear-gradient(0deg, ${props.widgetColors.chatBackground.hexColor}FF 0%,${props.widgetColors.chatBackground.hexColor}00 80%)`,
                                    }}
                                ></div>
                            )}
                        </div>
                    </div>
                    <div
                        className={"mt-4 bg-white pt-2 z-50"}
                        style={{
                            background:
                                props.widgetColors.chatBackground
                                    .hexColor,
                        }}
                    >
                        <div className={"flex flex-col justify-between"}>
                            <div className={"w-full flex flex-col"}>
                                <textarea
                                    value={dummyQuestion}
                                    onChange={(e) => setDummyQuestion(e.value)}
                                    placeholder={copy.questionInputPlaceholder}
                                    rows={1}
                                    className={"block w-full rounded-lg resize-none text-sm pt-2 pb-2 pl-6 pr-6 box-border block p-2 mt-1 text-gray-500 w-full rounded-md border-2 border-gray-800 resize-none"}
                                    style={{
                                        borderColor:
                                            props.widgetColors
                                                .formInputBorder.hexColor,
                                        backgroundColor:
                                            props.widgetColors
                                                .formInputBackground.hexColor,
                                        color:
                                            props.widgetColors
                                                .formInputText.hexColor,
                                        borderRadius:
                                            props.widgetBorderRadius.formInput.borderRadius.toString() +
                                            "px",
                                    }}
                                //minLength="10"
                                //placeholder={copy.questionInputPlaceholder}
                                />

                                <button
                                    type="submit"
                                    className={"inline-flex pt-2 pb-2 pl-6 pr-6 bg-indigo-600 text-white text-xs justify-center items-center rounded-lg border-0 border-transparent font-medium cursor-pointer min-w-[105px] h-9 mt-1"}
                                    style={{
                                        backgroundColor:
                                            props.widgetColors
                                                .submitButtonBackground.hexColor,
                                        color:
                                            props.widgetColors
                                                .submitButtonText.hexColor,
                                        borderRadius:
                                            props.widgetBorderRadius.submitButton.borderRadius.toString() +
                                            "px",
                                    }}
                                >

                                    {copy.submitQuestionButton}

                                </button>
                            </div>
                        </div>
                        {/*this.state.formError && (
                        <span className={"inline-flex pl-2 pr-2 mt-2 bg-pink-200 text-red-800 text-xs leading-4 font-medium items-center ml-4"}>
                            {this.state.formError}
                        </span>
                    )*/}
                    </div>
                </div>
            </div>
            <p className="text-sm mt-2">How the Q&A section of the Nuine widget will look on your webpage</p>
        </>
    )
}


export default connect(
    (state, props) => ({
        state: state.app,
        props: props,
    }),
    null
)(DummyQuestionList);