import React from "react";

import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";

const Setup = ({ state }) => {

  const attributes = [
    {
      attribute: "totalValue",
      description: "The total order value.",
      type: "integer or double",
      example: "858 or 858.17",
    },
    {
      attribute: "currency",
      description: "The currency code of the order.",
      type: "string",
      example: '"USD", "EUR", "GBP", other',
    },
    {
      attribute: "id",
      description:
        "An unique order id. It is recommended to use the order id from your ecommerce platform.",
      type: "string",
      example: '"s4CLg0nHZ8EvNCtmUEaX"',
    },
    {
      attribute: "items",
      description: "List of all the items in the order.",
      type: "array",
      example: "-",
    },
    {
      attribute: "sku",
      description: "The item SKU or product id.",
      type: "string",
      example: '"P6466", "V-93757", "NILE-HUG-TY"',
    },
    {
      attribute: "qunatity",
      description: "The number of items of the specific SKU.",
      type: "integer",
      example: "1, 3, 17",
    },
    {
      attribute: "itemValue",
      description:
        "Price of the item. Do not multiply the quantity with the item price. Send only in the price for one single item.",
      type: "integer or double",
      example: "38, 156.37",
    },
  ];
  return (
    <DashboardLayout>
      <div className="sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-12 px-12">
          <div className="space-y-6">
            <div>
              <h2>Setup Nuine</h2>
              <p className="text-base text-gray-800 pt-2">
                It is easy to setup Nuine on your ecommerce store. Just follow
                these three steps or send them to your developer.
              </p>
            </div>
            <div>
              <h3 className="pt-4">1. Add the main snippet</h3>
              <p className="text-base text-gray-800 pt-2">
                Add this snippet in the{" "}
                <code className="bg-gray-100 p-1">head</code> section of your
                code. That is between{" "}
                <code className="bg-gray-100 p-1">head</code> and{" "}
                <code className="bg-gray-100 p-1">/head</code>. You need only
                one global snippet per page.
              </p>
              <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                <code>
                  {`<script>`}
                  <br />
                  <span className="pl-6">
                    {`(function (w, d, s, o, f, js, fjs) {`}
                  </span>
                  <br />
                  <span className="pl-12">
                    {`w["Simple-Widget"] = o;`}

                    {` w[o] =`}

                    {` w[o] ||`}

                    {` function () {`}
                  </span>
                  <br />
                  <span className="pl-20">
                    {`(w[o].q = w[o].q || []).push(arguments);`}
                  </span>
                  <br />
                  <span className="pl-12">{`};`}</span>
                  <br />
                  <span className="pl-12">
                    {`(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);`}
                  </span>
                  <br />
                  <span className="pl-12">
                    {`js.id = o;`}

                    {` js.src = f;`}

                    {` js.async = 1;`}

                    {`fjs.parentNode.insertBefore(js, fjs);`}
                  </span>
                  <br />
                  <span className="pl-6">
                    {`})(window, document, "script", "w1", "./widget.js");`}
                  </span>
                  <br />
                  <span className="pl-6">
                    {`w1("init", { targetElementId: "nuine-widget", brandId: "${state.brandId}" });`}
                  </span>
                  <br />
                  {`</script>`}
                  <br />
                </code>
              </div>
            </div>
            <div>
              <h3 className="pt-4">2. Display the widget</h3>
              <p className="text-base text-gray-800 pt-2">
                This snipped are to be placed on all pages where you want the
                widget to display:
              </p>
              <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                <code>{`<div id="nuine-widget"></div>`}</code>
              </div>
            </div>
            <div>
              <h3 className="pt-4">3. Send the order event</h3>
              <p className="text-base text-gray-800 pt-2">
                If you want to track the effect of Nuine and the performance of
                the individual sales representatives, you need to send Nuine an
                event each time an order is placed. Add this script on your
                order confirmation page and replace the colored text with order
                data.
              </p>
              <p className="text-base text-gray-800 pt-2">
                This snippet must be placed right below the main snippet.
              </p>
              <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                <code>
                  {`<script>`}
                  <br />
                  <span className="pl-6">{`w1("order", {`}</span>
                  <br />
                  <span className="pl-12">
                    {`brandId: "${state.brandId}",`}
                  </span>
                  <br />
                  <span className="pl-12">{`order: {`}</span>
                  <br />
                  <span className="pl-20">{`totalValue:`}</span>{" "}
                  <span className="text-indigo-800">{`TOTAL_ORDER_VALUE,`}</span>
                  <br />
                  <span className="pl-20">{`currency:`}</span>{" "}
                  <span className="text-indigo-800">{`CURRENCY_ISO_CODE,`}</span>
                  <br />
                  <span className="pl-20">{`id:`}</span>{" "}
                  <span className="text-indigo-800">{`ORDER_ID`}</span>
                  <br />
                  <span className="pl-20">{`items: [`}</span>
                  <br />
                  <span className="pl-28">{`{ sku: `}</span>{" "}
                  <span className="text-indigo-800">{`PRODUCT_SKU`}</span>
                  {`, quantity:`}
                  <span className="text-indigo-800">{`PRODUCT_QUANTITY`}</span>
                  {`, itemValue:`}
                  <span className="text-indigo-800">{`ITEM_VALUE`}</span>
                  {` },`}
                  <br />
                  <span className="pl-28">{`{ sku: `}</span>{" "}
                  <span className="text-indigo-800">{`PRODUCT_SKU`}</span>
                  {`, quantity:`}
                  <span className="text-indigo-800">{`PRODUCT_QUANTITY`}</span>
                  {`, itemValue:`}
                  <span className="text-indigo-800">{`ITEM_VALUE`}</span>
                  {` },`}
                  <br />
                  <span className="pl-20">{`],`}</span>
                  <br />
                  <span className="pl-12">{`},`}</span>
                  <br />
                  <span className="pl-6">{`});`}</span>
                  <br />
                  {`</script>`}
                  <br />
                </code>
              </div>

              <div className="overflow-hidden border-b border-gray-200 pt-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Attribute
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Example
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {attributes.map((attribute) => (
                      <tr key={attribute.attribute}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <code>{attribute.attribute}</code>
                        </td>
                        <td className="px-6 py-4 whitespace-wrap text-sm text-gray-600">
                          {attribute.description}
                        </td>
                        <td className="px-6 py-4 whitespace-wrap text-sm text-gray-600">
                          {attribute.type}
                        </td>
                        <td className="px-6 py-4 whitespace-wrap text-sm text-gray-600">
                          {attribute.example}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className="text-base text-gray-800 pt-4">Example snippet:</p>
              <div className="bg-gray-100 px-8 py-4 text-sm mt-4">
                <code>
                  {`<script>`}
                  <br />
                  <span className="pl-6">{`w1("order", {`}</span>
                  <br />
                  <span className="pl-12">
                    {`brandId: "${state.brandId}",`}
                  </span>
                  <br />
                  <span className="pl-12">{`order: {`}</span>
                  <br />
                  <span className="pl-20">{`totalValue: 302.57,`}</span>
                  <br />
                  <span className="pl-20">{`currency: "USD",`}</span>
                  <br />
                  <span className="pl-20">{`id: "s4CLg0nHZ8EvNCtmUEaX",`}</span>
                  <br />
                  <span className="pl-20">{`items: [`}</span>
                  <br />
                  <span className="pl-28">
                    {`{ sku: "N345", quantity: 2, itemValue: 65 },`}
                  </span>
                  <br />
                  <span className="pl-28">
                    {`{ sku: "N008", quantity: 1, itemValue: 172.57 },`}
                  </span>
                  <br />
                  <span className="pl-20">{`],`}</span>
                  <br />
                  <span className="pl-12">{`},`}</span>
                  <br />
                  <span className="pl-6">{`});`}</span>
                  <br />
                  {`</script>`}
                  <br />
                </code>
              </div>
            </div>
            <div>
              <h3 className="pt-4">Success</h3>
              <p className="text-base text-gray-800 pt-2">
                Nuine should now be up and running on your ecommerce store. If
                you run into any trouble, please don't hesitate to reach out to
                use here.
              </p>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(
  (state) => ({
    state: state.app
  }),
  null
)(Setup);
