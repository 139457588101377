import React, { useState } from "react";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";
import DocumentationMenu from "../components/documentationMenu";

const SettingsGeneral = ({ state }) => {

  return (
    <div>
      <DashboardLayout>
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <DocumentationMenu page="stream" />
          <div className="cardContainerOuter border-b">
            <div className="cardContainerInner">
              <div>
                <h2 className="">Setup the stream</h2>
                <p className="text-gray-800 pt-4">
                  To stream through <b>nuine</b> you need to use a stream software. We recommend OBS (Open Broadcaster Software) as it is free, is battle tested and have a large community actively developing it.
                </p>
                <p className="text-gray-800 pt-4">Download OBS <a href="https://obsproject.com/download" target="_blank" className="text-coral-500 hover:text-coral-600">here</a>.</p>
              </div>

              <div className="flex flex-row pb-16 pt-10">
                <div>
                  <h3 className="">How to stream with OBS</h3>
                  <p className="text-gray-800 pt-4">
                    When you click “Start stream” in the <b>nuine</b> platform, you first customise the stream, then, in the next step, you are given a Stream URL and a Stream Key.
                  </p>
                  <p className="text-gray-800 pt-4">
                  In OBS, click “Settings” -> “Stream”. Choose "Custom" in the “Service” dropdown. Place Stream URL in the “Server” field and Stream Key in the “Stream Key” field. Leave “Use authentication” unchecked.
                  </p>
                  <p className="text-gray-800 pt-4">
                    When you click “Start stream” in OBS, your screen in the <b>nuine</b> platform will change to a preview screen. From there you can "Publish stream" and it will go live on your webpage.
                  </p>
                </div>
              </div>
              <div className="flex flex-row pb-16">
                <div>
                  <h3 className="">Why can’t I stream directly from the browser?</h3>
                  <p className="text-gray-800 pt-2">
                    Great question. We are constantly testing ways to stream through your browser, but as of now it just inst reliable enough to be used when you are going to have a live stream you can trust won’t go down.
                  </p>
                </div>
              </div>
              <div className="flex flex-row pb-16">
                <div>
                  <h3 className="">What is the perks with OBS?</h3>
                  <p className="text-gray-800 pt-2">
                    <ul>
                      <li><b className="pr-1">•</b> It is reliable</li>
                      <li><b className="pr-1">•</b> It let' s you easily use several cameras and switch between them during the stream</li>
                      <li><b className="pr-1">•</b> You can use any external microphone</li>
                      <li><b className="pr-1">•</b> You have full control over the output quality. (We optimise the quality for the viewer based on their internet speed)</li>
                      <li><b className="pr-1">•</b> You can screencast, that means to share your screen. For example when showcasing a software.</li>
                      <li><b className="pr-1">•</b> You can control the cameras from both the software and from an hardware controller</li>
                    </ul>
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </DashboardLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    state: state.app
  }),
  null
)(SettingsGeneral);
