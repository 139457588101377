import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import { UserAddIcon } from "@heroicons/react/outline";

import { connect } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StreamSteps = ({ state, dispatch, props }) => {
  const [stepLocation, setStepLocation] = useState(window.location.pathname)
  console.log("window.location.pathname.includes: ", window.location.pathname.includes("customize"))
  const steps = [
    {
      id: "Customize",
      name: "Set stream options",
      step: 1,
      status: stepLocation.includes("customize") ? "current" : (stepLocation.includes("details") || stepLocation.includes("preview") || stepLocation.includes("active")) && "complete"
    },
    {
      id: "Details",
      name: "Get stream details",
      step: 2,
      status: stepLocation.includes("details") ? "current" : stepLocation.includes("customize") ? false : (stepLocation.includes("preview") || stepLocation.includes("active")) && "complete"
    },
    {
      id: "Preview",
      name: "Preview stream before going live",
      step: 3,
      status: stepLocation.includes("preview") ? "current" : (stepLocation.includes("customize") || stepLocation.includes("details")) ? false : "complete"
    },
    {
      id: "Stream",
      name: "Stream is live on your page",
      step: 4,
      status: stepLocation.includes("active") ? "current" : (stepLocation.includes("customize") || stepLocation.includes("details") || stepLocation.includes("preview")) ? false : "complete"
    },
  ];

  return (
    <div className={
      (stepLocation.includes("active") && state.streamStatus === 'active')
        ? `animate-hideStreamSteps py-6 border-b`
        : `py-6 border-b`
    }>
      {/* Tabs */}

      <nav aria-label="Progress">
        <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8">
          {steps.map((step) => (
            <li key={step.name} className="md:flex-1">
              {console.log(step.id, step.status)}
              {step.status === "complete" ? (
                <div className="group pl-4 py-2 flex flex-col border-l-4 border-black md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                  <span className="text-xs text-black font-semibold tracking-wide uppercase">
                    {step.id}
                  </span>
                  <span className="text-sm font-base text-gray-500">{step.name}</span>
                </div>
              ) : step.status === "current" ? (
                <div
                  className="pl-4 py-2 flex flex-col border-l-4 border-black md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                  aria-current="step"
                >
                  <span className="text-xs text-black font-semibold tracking-wide uppercase">
                    {step.id}
                  </span>
                  <span className="text-sm font-base text-gray-500">{step.name}</span>
                </div>
              ) : (
                <div className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
                  <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase ">
                    {step.id}
                  </span>
                  <span className="text-sm font-base text-gray-500">{step.name}</span>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(StreamSteps);
