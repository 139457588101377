import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
//import stripe from 'stripe'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { navigate } from "gatsby";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";
import SettingsMenu from "../components/settingsMenu";
import Plans from "../components/plans";
import PaymentAndBilling from "../components/paymentAndBilling";
import PaymentAndBilling2 from "../components/paymentAndBilling2";

// import { updateUserDetails } from "../state/app";

const tiers = [
  {
    name: "Trial",
    priceMonthly: 0,
    overagePrice: 0,
    description: "",
  },
  {
    name: "Starter",
    priceMonthly: 99,
    overagePrice: 0.129,
    description: "",
  },
  {
    name: "Medium",
    priceMonthly: 299,
    overagePrice: 0.079,
    description: "",
  },
  {
    name: "Large",
    priceMonthly: 699,
    overagePrice: 0.069,
    description: "",
  },
];

//const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const SettingsPlan = ({ state, dispatch }) => {
  const [updateDetails, setUpdateDetails] = useState("");
  const [step, setStep] = useState(1);
  const [chosenPlan, setChosenPlan] = useState(0);
  const [isInitiatingSubscription, setIsInitiatingSubscription] =
    useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [initialSubscriptionStatus, setInitialSubscriptionStatus] = useState()
  const [newSubscriptionActivated, setNewSubscriptionActivated] = useState(false)
  const [subscriptionCanceled, setSubscriptionCanceled] = useState(false)
  const [stripePromise, setStripePromise] = useState(loadStripe(process.env.STRIPE_PUBLIC_KEY))


  /*useEffect(() => {
    console.log('YEAH1')
    console.log('YEAH1: ', chosenPlan)
    console.log('YEAH1: ', state.subscriptionPlanId)
    console.log('YEAH1: ', chosenPlan.stripeProductId)
    if (chosenPlan !== 'canceled') {
      if (state.subscriptionPlanId === chosenPlan.stripeProductId) {
        console.log('YEAH2')
        setIsSubscribed(true)
        setIsInitiatingSubscription(false)
      }
    }

  }, [state.subscriptionPlanId])*/

  useEffect(() => {
    console.log("state.subscriptionStatus: ", state.subscriptionStatus)
    setInitialSubscriptionStatus(state.subscriptionStatus)
  }, [])

  useEffect(() => {
    console.log('YEAH1')

    if (newSubscriptionActivated) {
      setIsSubscribed(true)
      setIsInitiatingSubscription(false)
      setNewSubscriptionActivated(false)
    }
  }, [newSubscriptionActivated])

  useEffect(() => {
    console.log('YEAH2')

    if (subscriptionCanceled) {
      setIsSubscribed(false)
      setInitialSubscriptionStatus('canceled')
      setIsInitiatingSubscription(false)
    }
  }, [subscriptionCanceled])


  /*useEffect(() => {
    console.log('YEAH3')
    if (state.subscriptionStatus === 'canceled' && chosenPlan === 'canceled') {
      setInitialSubscriptionStatus('canceled')
      setIsSubscribed(false)
      setIsInitiatingSubscription(false)
    }

  }, [state.subscriptionStatus])*/


  const updateStep = (newStep) => {
    setStep(newStep);
  };

  const updateChosenPlan = (plan) => {
    setChosenPlan(plan);
  };

  const flipIsInitiatingSubscription = (newState) => {
    setIsInitiatingSubscription(newState);
  };

  const flipIsSubscribed = (newState) => {
    setIsSubscribed(newState);
  };

  console.log(step, chosenPlan);
  console.log("isInitiatingSubscription: ", isInitiatingSubscription);
  console.log("isSubscribed : ", isSubscribed);

  return (
    state.userRole === "admin" && (
      <div>
        <div>
          {isInitiatingSubscription && (
            <div
              className={`${isInitiatingSubscription ? "z-[100] absolute" : "z-0"
                } bg-coral-600 w-full h-full opacity-90`}
            >
              <div className="flex justify-center items-center h-screen flex-col">
                <svg
                  className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                <p className="mt-4 text-white">Setting up subscription</p><p className="text-white">Do not refresh or close tab</p>
              </div>
            </div>
          )}

          <DashboardLayout>
            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
              <SettingsMenu page="plan" />

              {(!isSubscribed && !subscriptionCanceled && state.userRole === "admin") && (
                <div>
                  {step === 1 && (
                    <Plans
                      updateStep={updateStep}
                      updateChosenPlan={updateChosenPlan}
                      flipIsInitiatingSubscription={
                        flipIsInitiatingSubscription
                      }
                      setSubscriptionCanceled={setSubscriptionCanceled}
                      setInitialSubscriptionStatus={setInitialSubscriptionStatus}
                      setIsSubscribed={setIsSubscribed}
                      setIsInitiatingSubscription={setIsInitiatingSubscription}
                      setNewSubscriptionActivated={setNewSubscriptionActivated}
                    />
                  )}
                  {step === 2 && (
                    /*<PaymentAndBilling
                      chosenPlan={chosenPlan}
                      tiers={tiers}
                    />*/
                    <Elements stripe={stripePromise}>
                      <PaymentAndBilling2
                        chosenPlan={chosenPlan}
                        tiers={tiers}
                        flipIsInitiatingSubscription={
                          flipIsInitiatingSubscription
                        }
                        flipIsSubscribed={flipIsSubscribed}
                        isSubscribed={isSubscribed}
                        setNewSubscriptionActivated={setNewSubscriptionActivated}
                      />
                    </Elements>
                  )}
                </div>
              )}
              {isSubscribed &&
                <div className="space-y-6" id="top">
                  <div className="cardContainerOuter">
                    <div className="cardContainerInner">
                      <h2>
                        Success
                      </h2>
                      <p className="mt-4">
                        {`You are now on the ${chosenPlan.title} plan `}
                      </p>
                      <button
                        className="secondary-button mt-4"
                        onClick={() => navigate("/app/dashboard")}
                      >
                        Go home
                      </button>
                    </div>
                  </div>
                </div>
              }
              {subscriptionCanceled &&
                <div className="space-y-6" id="top">
                  <div className="cardContainerOuter">
                    <div className="cardContainerInner">
                      <h2>
                        You have canceled your subscription
                      </h2>
                      <button
                        className="secondary-button mt-4"
                        onClick={() => navigate("/app/dashboard")}
                      >
                        Go home
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </DashboardLayout>
        </div>
      </div>
    )
  );
};

export default connect(
  (state) => ({
    state: state.app,
  }),
  null
)(SettingsPlan);
