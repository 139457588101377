export const languages = [
  { abbreviation: "ab", name: "Abkhaz", nativeName: "аҧсуа" },
  { abbreviation: "aa", name: "Afar", nativeName: "Afaraf" },
  { abbreviation: "af", name: "Afrikaans", nativeName: "Afrikaans" },
  { abbreviation: "ak", name: "Akan", nativeName: "Akan" },
  { abbreviation: "sq", name: "Albanian", nativeName: "Shqip" },
  { abbreviation: "am", name: "Amharic", nativeName: "አማርኛ" },
  { abbreviation: "ar", name: "Arabic", nativeName: "العربية" },
  { abbreviation: "an", name: "Aragonese", nativeName: "Aragonés" },
  { abbreviation: "hy", name: "Armenian", nativeName: "Հայերեն" },
  { abbreviation: "as", name: "Assamese", nativeName: "অসমীয়া" },
  {
    abbreviation: "av",
    name: "Avaric",
    nativeName: "авар мацӀ, магӀарул мацӀ",
  },
  { abbreviation: "ae", name: "Avestan", nativeName: "avesta" },
  { abbreviation: "ay", name: "Aymara", nativeName: "aymar aru" },
  { abbreviation: "az", name: "Azerbaijani", nativeName: "azərbaycan dili" },
  { abbreviation: "bm", name: "Bambara", nativeName: "bamanankan" },
  { abbreviation: "ba", name: "Bashkir", nativeName: "башҡорт теле" },
  { abbreviation: "eu", name: "Basque", nativeName: "euskara, euskera" },
  { abbreviation: "be", name: "Belarusian", nativeName: "Беларуская" },
  { abbreviation: "bn", name: "Bengali", nativeName: "বাংলা" },
  { abbreviation: "bh", name: "Bihari", nativeName: "भोजपुरी" },
  { abbreviation: "bi", name: "Bislama", nativeName: "Bislama" },
  { abbreviation: "bs", name: "Bosnian", nativeName: "bosanski jezik" },
  { abbreviation: "br", name: "Breton", nativeName: "brezhoneg" },
  { abbreviation: "bg", name: "Bulgarian", nativeName: "български език" },
  { abbreviation: "my", name: "Burmese", nativeName: "ဗမာစာ" },
  { abbreviation: "ca", name: "Catalan; Valencian", nativeName: "Català" },
  { abbreviation: "ch", name: "Chamorro", nativeName: "Chamoru" },
  { abbreviation: "ce", name: "Chechen", nativeName: "нохчийн мотт" },
  {
    abbreviation: "ny",
    name: "Chichewa; Chewa; Nyanja",
    nativeName: "chiCheŵa, chinyanja",
  },
  {
    abbreviation: "zh",
    name: "Chinese",
    nativeName: "中文 (Zhōngwén), 汉语, 漢語",
  },
  { abbreviation: "cv", name: "Chuvash", nativeName: "чӑваш чӗлхи" },
  { abbreviation: "kw", name: "Cornish", nativeName: "Kernewek" },
  { abbreviation: "co", name: "Corsican", nativeName: "corsu, lingua corsa" },
  { abbreviation: "cr", name: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
  { abbreviation: "hr", name: "Croatian", nativeName: "hrvatski" },
  { abbreviation: "cs", name: "Czech", nativeName: "česky, čeština" },
  { abbreviation: "da", name: "Danish", nativeName: "dansk" },
  {
    abbreviation: "dv",
    name: "Divehi; Dhivehi; Maldivian;",
    nativeName: "ދިވެހި",
  },
  { abbreviation: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
  { abbreviation: "en", name: "English", nativeName: "English" },
  { abbreviation: "eo", name: "Esperanto", nativeName: "Esperanto" },
  { abbreviation: "et", name: "Estonian", nativeName: "eesti, eesti keel" },
  { abbreviation: "ee", name: "Ewe", nativeName: "Eʋegbe" },
  { abbreviation: "fo", name: "Faroese", nativeName: "føroyskt" },
  { abbreviation: "fj", name: "Fijian", nativeName: "vosa Vakaviti" },
  { abbreviation: "fi", name: "Finnish", nativeName: "suomi, suomen kieli" },
  {
    abbreviation: "fr",
    name: "French",
    nativeName: "français, langue française",
  },
  {
    abbreviation: "ff",
    name: "Fula; Fulah; Pulaar; Pular",
    nativeName: "Fulfulde, Pulaar, Pular",
  },
  { abbreviation: "gl", name: "Galician", nativeName: "Galego" },
  { abbreviation: "ka", name: "Georgian", nativeName: "ქართული" },
  { abbreviation: "de", name: "German", nativeName: "Deutsch" },
  { abbreviation: "el", name: "Greek, Modern", nativeName: "Ελληνικά" },
  { abbreviation: "gn", name: "Guaraní", nativeName: "Avañeẽ" },
  { abbreviation: "gu", name: "Gujarati", nativeName: "ગુજરાતી" },
  {
    abbreviation: "ht",
    name: "Haitian; Haitian Creole",
    nativeName: "Kreyòl ayisyen",
  },
  { abbreviation: "ha", name: "Hausa", nativeName: "Hausa, هَوُسَ" },
  { abbreviation: "he", name: "Hebrew (modern)", nativeName: "עברית" },
  { abbreviation: "hz", name: "Herero", nativeName: "Otjiherero" },
  { abbreviation: "hi", name: "Hindi", nativeName: "हिन्दी, हिंदी" },
  { abbreviation: "ho", name: "Hiri Motu", nativeName: "Hiri Motu" },
  { abbreviation: "hu", name: "Hungarian", nativeName: "Magyar" },
  { abbreviation: "ia", name: "Interlingua", nativeName: "Interlingua" },
  { abbreviation: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
  {
    abbreviation: "ie",
    name: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII",
  },
  { abbreviation: "ga", name: "Irish", nativeName: "Gaeilge" },
  { abbreviation: "ig", name: "Igbo", nativeName: "Asụsụ Igbo" },
  { abbreviation: "ik", name: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
  { abbreviation: "io", name: "Ido", nativeName: "Ido" },
  { abbreviation: "is", name: "Icelandic", nativeName: "Íslenska" },
  { abbreviation: "it", name: "Italian", nativeName: "Italiano" },
  { abbreviation: "iu", name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
  {
    abbreviation: "ja",
    name: "Japanese",
    nativeName: "日本語 (にほんご／にっぽんご)",
  },
  { abbreviation: "jv", name: "Javanese", nativeName: "basa Jawa" },
  {
    abbreviation: "kl",
    name: "Kalaallisut, Greenlandic",
    nativeName: "kalaallisut, kalaallit oqaasii",
  },
  { abbreviation: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ" },
  { abbreviation: "kr", name: "Kanuri", nativeName: "Kanuri" },
  { abbreviation: "ks", name: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
  { abbreviation: "kk", name: "Kazakh", nativeName: "Қазақ тілі" },
  { abbreviation: "km", name: "Khmer", nativeName: "ភាសាខ្មែរ" },
  { abbreviation: "ki", name: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
  { abbreviation: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda" },
  { abbreviation: "ky", name: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
  { abbreviation: "kv", name: "Komi", nativeName: "коми кыв" },
  { abbreviation: "kg", name: "Kongo", nativeName: "KiKongo" },
  {
    abbreviation: "ko",
    name: "Korean",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
  },
  { abbreviation: "ku", name: "Kurdish", nativeName: "Kurdî, كوردی‎" },
  { abbreviation: "kj", name: "Kwanyama, Kuanyama", nativeName: "Kuanyama" },
  { abbreviation: "la", name: "Latin", nativeName: "latine, lingua latina" },
  {
    abbreviation: "lb",
    name: "Luxembourgish, Letzeburgesch",
    nativeName: "Lëtzebuergesch",
  },
  { abbreviation: "lg", name: "Luganda", nativeName: "Luganda" },
  {
    abbreviation: "li",
    name: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgs",
  },
  { abbreviation: "ln", name: "Lingala", nativeName: "Lingála" },
  { abbreviation: "lo", name: "Lao", nativeName: "ພາສາລາວ" },
  { abbreviation: "lt", name: "Lithuanian", nativeName: "lietuvių kalba" },
  { abbreviation: "lu", name: "Luba-Katanga", nativeName: "" },
  { abbreviation: "lv", name: "Latvian", nativeName: "latviešu valoda" },
  { abbreviation: "gv", name: "Manx", nativeName: "Gaelg, Gailck" },
  { abbreviation: "mk", name: "Macedonian", nativeName: "македонски јазик" },
  { abbreviation: "mg", name: "Malagasy", nativeName: "Malagasy fiteny" },
  {
    abbreviation: "ms",
    name: "Malay",
    nativeName: "bahasa Melayu, بهاس ملايو‎",
  },
  { abbreviation: "ml", name: "Malayalam", nativeName: "മലയാളം" },
  { abbreviation: "mt", name: "Maltese", nativeName: "Malti" },
  { abbreviation: "mi", name: "Māori", nativeName: "te reo Māori" },
  { abbreviation: "mr", name: "Marathi (Marāṭhī)", nativeName: "मराठी" },
  { abbreviation: "mh", name: "Marshallese", nativeName: "Kajin M̧ajeļ" },
  { abbreviation: "mn", name: "Mongolian", nativeName: "монгол" },
  { abbreviation: "na", name: "Nauru", nativeName: "Ekakairũ Naoero" },
  {
    abbreviation: "nv",
    name: "Navajo, Navaho",
    nativeName: "Diné bizaad, Dinékʼehǰí",
  },
  { abbreviation: "nb", name: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
  { abbreviation: "nd", name: "North Ndebele", nativeName: "isiNdebele" },
  { abbreviation: "ne", name: "Nepali", nativeName: "नेपाली" },
  { abbreviation: "ng", name: "Ndonga", nativeName: "Owambo" },
  {
    abbreviation: "nn",
    name: "Norwegian Nynorsk",
    nativeName: "Norsk nynorsk",
  },
  { abbreviation: "no", name: "Norwegian", nativeName: "Norsk" },
  { abbreviation: "ii", name: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
  { abbreviation: "nr", name: "South Ndebele", nativeName: "isiNdebele" },
  { abbreviation: "oc", name: "Occitan", nativeName: "Occitan" },
  { abbreviation: "oj", name: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  {
    abbreviation: "cu",
    name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    nativeName: "ѩзыкъ словѣньскъ",
  },
  { abbreviation: "om", name: "Oromo", nativeName: "Afaan Oromoo" },
  { abbreviation: "or", name: "Oriya", nativeName: "ଓଡ଼ିଆ" },
  { abbreviation: "os", name: "Ossetian, Ossetic", nativeName: "ирон æвзаг" },
  {
    abbreviation: "pa",
    name: "Panjabi, Punjabi",
    nativeName: "ਪੰਜਾਬੀ, پنجابی‎",
  },
  { abbreviation: "pi", name: "Pāli", nativeName: "पाऴि" },
  { abbreviation: "fa", name: "Persian", nativeName: "فارسی" },
  { abbreviation: "pl", name: "Polish", nativeName: "polski" },
  { abbreviation: "ps", name: "Pashto, Pushto", nativeName: "پښتو" },
  { abbreviation: "pt", name: "Portuguese", nativeName: "Português" },
  { abbreviation: "qu", name: "Quechua", nativeName: "Runa Simi, Kichwa" },
  { abbreviation: "rm", name: "Romansh", nativeName: "rumantsch grischun" },
  { abbreviation: "rn", name: "Kirundi", nativeName: "kiRundi" },
  {
    abbreviation: "ro",
    name: "Romanian, Moldavian, Moldovan",
    nativeName: "română",
  },
  { abbreviation: "ru", name: "Russian", nativeName: "русский язык" },
  { abbreviation: "sa", name: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
  { abbreviation: "sc", name: "Sardinian", nativeName: "sardu" },
  { abbreviation: "sd", name: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
  { abbreviation: "se", name: "Northern Sami", nativeName: "Davvisámegiella" },
  { abbreviation: "sm", name: "Samoan", nativeName: "gagana faa Samoa" },
  { abbreviation: "sg", name: "Sango", nativeName: "yângâ tî sängö" },
  { abbreviation: "sr", name: "Serbian", nativeName: "српски језик" },
  {
    abbreviation: "gd",
    name: "Scottish Gaelic; Gaelic",
    nativeName: "Gàidhlig",
  },
  { abbreviation: "sn", name: "Shona", nativeName: "chiShona" },
  { abbreviation: "si", name: "Sinhala, Sinhalese", nativeName: "සිංහල" },
  { abbreviation: "sk", name: "Slovak", nativeName: "slovenčina" },
  { abbreviation: "sl", name: "Slovene", nativeName: "slovenščina" },
  { abbreviation: "so", name: "Somali", nativeName: "Soomaaliga, af Soomaali" },
  { abbreviation: "st", name: "Southern Sotho", nativeName: "Sesotho" },
  {
    abbreviation: "es",
    name: "Spanish; Castilian",
    nativeName: "español, castellano",
  },
  { abbreviation: "su", name: "Sundanese", nativeName: "Basa Sunda" },
  { abbreviation: "sw", name: "Swahili", nativeName: "Kiswahili" },
  { abbreviation: "ss", name: "Swati", nativeName: "SiSwati" },
  { abbreviation: "sv", name: "Swedish", nativeName: "svenska" },
  { abbreviation: "ta", name: "Tamil", nativeName: "தமிழ்" },
  { abbreviation: "te", name: "Telugu", nativeName: "తెలుగు" },
  { abbreviation: "tg", name: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
  { abbreviation: "th", name: "Thai", nativeName: "ไทย" },
  { abbreviation: "ti", name: "Tigrinya", nativeName: "ትግርኛ" },
  {
    abbreviation: "bo",
    name: "Tibetan Standard, Tibetan, Central",
    nativeName: "བོད་ཡིག",
  },
  { abbreviation: "tk", name: "Turkmen", nativeName: "Türkmen, Түркмен" },
  {
    abbreviation: "tl",
    name: "Tagalog",
    nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔",
  },
  { abbreviation: "tn", name: "Tswana", nativeName: "Setswana" },
  {
    abbreviation: "to",
    name: "Tonga (Tonga Islands)",
    nativeName: "faka Tonga",
  },
  { abbreviation: "tr", name: "Turkish", nativeName: "Türkçe" },
  { abbreviation: "ts", name: "Tsonga", nativeName: "Xitsonga" },
  {
    abbreviation: "tt",
    name: "Tatar",
    nativeName: "татарча, tatarça, تاتارچا‎",
  },
  { abbreviation: "tw", name: "Twi", nativeName: "Twi" },
  { abbreviation: "ty", name: "Tahitian", nativeName: "Reo Tahiti" },
  {
    abbreviation: "ug",
    name: "Uighur, Uyghur",
    nativeName: "Uyƣurqə, ئۇيغۇرچە‎",
  },
  { abbreviation: "uk", name: "Ukrainian", nativeName: "українська" },
  { abbreviation: "ur", name: "Urdu", nativeName: "اردو" },
  { abbreviation: "uz", name: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
  { abbreviation: "ve", name: "Venda", nativeName: "Tshivenḓa" },
  { abbreviation: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
  { abbreviation: "vo", name: "Volapük", nativeName: "Volapük" },
  { abbreviation: "wa", name: "Walloon", nativeName: "Walon" },
  { abbreviation: "cy", name: "Welsh", nativeName: "Cymraeg" },
  { abbreviation: "wo", name: "Wolof", nativeName: "Wollof" },
  { abbreviation: "fy", name: "Western Frisian", nativeName: "Frysk" },
  { abbreviation: "xh", name: "Xhosa", nativeName: "isiXhosa" },
  { abbreviation: "yi", name: "Yiddish", nativeName: "ייִדיש" },
  { abbreviation: "yo", name: "Yoruba", nativeName: "Yorùbá" },
  {
    abbreviation: "za",
    name: "Zhuang, Chuang",
    nativeName: "Saɯ cueŋƅ, Saw cuengh",
  },
];
