import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";

import DashboardLayout from "../components/dashboardLayout";

import StreamSteps from "../components/streamSteps";

import { navigate } from "gatsby";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { languages } from "../utilities/languages";

import { ChevronDownIcon } from "@heroicons/react/solid";

import firebaseFunction from "./../utilities/firebaseFunction.js";
import { addInitialData } from "../state/app";

const StreamCustomization = ({ state, stream, dispatch }) => {
  const [streamError, setStreamError] = useState(false);
  const [stepStatus, setStepStatus] = useState({
    status: "idle",
    step1: "current",
    step2: "upcoming",
    step3: "upcoming",
  });
  const [language, setLanguage] = useState("en");
  const [processingStreamDetails, setProcessingStreamDetails] = useState(false)

  useEffect(() => {
    console.log("YEEEEY: ", state.streamStatus)
    if (state.streamStatus === "idle") {
      navigate("/app/stream/details")
    } else if (state.streamStatus === "preview") {
      navigate("/app/stream/preview")
    } else if (state.streamStatus === "active") {
      navigate("/app/stream/active")
    }
  }, [state.streamStatus]);

  const createLiveStream = async (streamLanguage, widgetCategories) => {
    setProcessingStreamDetails(true)

    let createLiveStreamResponse = await firebaseFunction("createLiveStream", {
      userFirstName: state.userFirstName,
      brandId: state.brandId,
      brandName: state.brandName,
      streamLanguage: streamLanguage,
      widgetCategories: widgetCategories,
      widgetIsInDevelopmentMode: state.widgetIsInDevelopmentMode
    })

    if (createLiveStreamResponse.error) {
      //alert(createLiveStreamResponse.data.message)
      //showNotification({ type: "information", header: "X", message: createLiveStreamResponse.data.message })
      dispatch(
        addInitialData({
          notification: { showNotification: true, type: "information", header: createLiveStreamResponse.data.message, message: false }
        })
      );
      setProcessingStreamDetails(false)
    }

    // var createLiveStream = firebase
    //   .functions()
    //   .httpsCallable("createLiveStream");

    // createLiveStream({
    //   userFirstName: state.userFirstName,
    //   brandId: state.brandId,
    //   brandName: state.brandName,
    //   streamLanguage: streamLanguage,
    //   widgetCategories: widgetCategories,
    //   widgetIsInDevelopmentMode: state.widgetIsInDevelopmentMode
    // }).then((result) => {
    //   if (result.data.code === 200) {
    //     console.log("RESULT: ", result);
    //   }
    //   // TODO: Fix so that this is sent from the backend in case trial has ended
    //   /* else if (result.data.code === 402) {
    //     console.log("STREAM ERROR!!!");
    //     setStreamError(
    //       `Trial has ended. Please let ${state.brandName} know that they have to update their plan.`
    //     );
    //   } */
    // });
  };

  return (
    <DashboardLayout>
      <StreamSteps stepStatus={stepStatus} />
      <div
        className={
          stream.isStreaming
            ? "space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-gray-900 h-full"
            : "space-y-6 sm:px-6 lg:px-0 lg:col-span-9 bg-gray-900"
        }
      >
        <div
          className={
            stream.isStreaming
              ? "cardContainerOuter h-full"
              : "cardContainerOuter"
          }
        >
          <div
            className={
              stream.isStreaming
                ? "cardContainerInner h-full"
                : "cardContainerInner"
            }
          >

            {streamError && (
              <div className="absolute z-10 rounded-md bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    {/* Heroicon name: solid/information-circle */}
                    <svg
                      className="h-5 w-5 text-yellow-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-yellow-700">{`${streamError}`}</p>
                  </div>
                </div>
              </div>
            )}
            <div>

              <div>
                <Formik
                  initialValues={{
                    language: "en",
                    widgetCategories: [],
                  }}
                  validationSchema={Yup.object({
                    language: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    //setFormSubmitting(true);
                    console.log(values.widgetCategory);
                    createLiveStream(values.language, values.widgetCategories);
                    setSubmitting(false);

                    //setFormSubmitting(false);
                    //resetForm();
                  }}
                >
                  {({ errors, touched, setFieldValue, isValidating }) => (
                    <Form>
                      <div className="text-base space-y-2">
                        <h2 className="mb-4">Stream details</h2>
                        <div className="grid grid-cols-6 gap-6">
                          <div
                            className={`${language === "US" || language === "CA"
                              ? "col-span-6 sm:col-span-3"
                              : "col-span-6 sm:col-span-6 mb-4"
                              }`}
                          >
                            <label htmlFor="language">Select language</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                id="language"
                                name="language"
                                as="select"
                                className={`${errors.language && touched.language
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base`}
                                /*onChange={(e) => {
                                        
                                      }}*/
                                onChange={(e) => {
                                  console.log("e.target.value: ", e.target.value);
                                  setLanguage(e.target.value);
                                  setFieldValue("language", e.target.value);
                                }}
                              >
                                <option value="None">Select language</option>
                                {languages.map((language, i) => {
                                  return (
                                    <option value={language.abbreviation} key={i}>
                                      {language.name}
                                    </option>
                                  );
                                })}
                                {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                              </Field>
                              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ChevronDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                            <ErrorMessage name="language">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          {(Array.isArray(state.widgetCategories) && state.widgetCategories.length) ?
                            <fieldset className="col-span-6 sm:col-span-6 mb-6">
                              <label>Categories</label>
                              <div className="border-b border-gray-200 divide-y divide-gray-200">
                                {state.widgetCategories.map((category, id) => (
                                  <div
                                    key={id}
                                    className="relative flex items-center py-4"
                                  >
                                    <div className="mr-3 flex items-center h-5">
                                      <Field
                                        id={`widgetCategories-${id}`}
                                        value={category}
                                        name="widgetCategories"
                                        type="checkbox"
                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                      />
                                    </div>
                                    <div className="min-w-0 text-sm">
                                      <label
                                        htmlFor={`widgetCategories-${id}`}
                                        className="font-medium text-gray-700 select-none"
                                      >
                                        {category}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                            : <div></div>}

                          {/*<button type="submit">Subscribe</button> */}
                        </div>
                        <div className="text-right">
                          <button type="submit" className="primary-button">
                            {processingStreamDetails ? <svg
                              className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg> : "Next"}

                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(
  (state) => ({
    state: state.app,
    stream: state.app.stream,
  }),
  null
)(StreamCustomization);
