import React, { useState, useEffect } from "react";
import { Disclosure, Switch } from "@headlessui/react";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { connect } from "react-redux";

import DashboardLayout from "../components/dashboardLayout";

import {
  CheckCircleIcon,
  XCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Team = ({ state, dispatch }) => {
  const [advocatesIsLoaded, setAdvocatesIsLoaded] = useState(false);
  const [listOfAdvocates, setListOfAdvocates] = useState({ list: "" });
  const [advocateFirstName, setAdvocateFirstName] = useState("");
  const [advocateLastName, setAdvocateLastName] = useState("");
  const [processingAdvocateStatus, setProcessingAdvocateStatus] =
    useState(false);

  if (
    (state.userRole === "admin" || state.userRole === "manager") &&
    !advocatesIsLoaded
  ) {
    var getTeamMembers = firebase.functions().httpsCallable("getTeamMembers");

    getTeamMembers({ brandId: state.brandId }).then((result) => {
      console.log("result.data:::: ", result.data);
      setAdvocatesIsLoaded(true);

      setListOfAdvocates({
        list: result.data,
      });

      console.log("result.data: ", result.data);
    });
  }

  const toggleAdvocateStatus = (advocateId, index) => {
    setProcessingAdvocateStatus(true);
    if (listOfAdvocates.list[index].eligibleToStream === true) {
      var deactivateAdvocate = firebase
        .functions()
        .httpsCallable("deactivateAdvocate");

      deactivateAdvocate({
        brandId: state.brandId,
        advocateId: advocateId,
      }).then((result) => {
        let tempListOfAdvocates = listOfAdvocates.list;

        tempListOfAdvocates[index].eligibleToStream = false;
        setListOfAdvocates({ list: tempListOfAdvocates });
        setProcessingAdvocateStatus(false);
      });
    } else if (listOfAdvocates.list[index].eligibleToStream === false) {
      var activateAdvocate = firebase
        .functions()
        .httpsCallable("activateAdvocate");

      activateAdvocate({
        brandId: state.brandId,
        advocateId: advocateId,
      }).then((result) => {
        let tempListOfAdvocates = listOfAdvocates.list;

        tempListOfAdvocates[index].eligibleToStream = true;
        setListOfAdvocates({ list: tempListOfAdvocates });
        setProcessingAdvocateStatus(false);
      });
    }
  };

  const secondsToHms = (seconds) => {
    if (seconds > 0) {
      seconds = Number(seconds);
      var h = Math.floor(seconds / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor((seconds % 3600) % 60);
      /*
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    */
      var hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
      var mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
      var sDisplay = s > 0 ? s + (s === 1 ? "s " : "s ") : "";

      return hDisplay + mDisplay + sDisplay;
    } else {
      return "0h 0m 0s";
    }
  };

  return (
    <div>
      <DashboardLayout>
        {state.userRole === "admin" && (
          <div className="flex flex-col">
            {advocatesIsLoaded ? (
              <div>
                <div className="sm:rounded-md sm:overflow-hidden">
                  <div className="py-12 px-12">
                    <div>
                      <div>
                        <h2>Advocates</h2>
                        <p className="mt-4">
                          Persons who can live stream on behalf of your brand
                        </p>
                      </div>

                      <div className="overflow-hidden border-b border-gray-200 pt-6">
                        <div className="overflow-hidden">
                          <ul role="list" className="divide-y divide-gray-200">
                            {listOfAdvocates.list !== "" ? (
                              listOfAdvocates.list.map((listItem, i) => {
                                return (
                                  <Disclosure as="li" key={i}>
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button className="hover:bg-gray-50 w-full focus:outline-none">
                                          <div className="flex items-center px-4 py-4 sm:px-6">
                                            <div className="min-w-0 flex-1 flex items-center">
                                              <div className="flex-shrink-0">
                                                <div className="h-12 w-12 rounded-full bg-black text-white font-bold text-xl flex justify-center items-center pt-0.5 pl-0.5">
                                                  {listItem.firstName[0].toUpperCase() +
                                                    listItem.lastName[0].toUpperCase()}
                                                </div>
                                                {/*<img
                                            className="h-12 w-12 rounded-full"
                                            src={listItem.applicant.imageUrl}
                                            alt=""
                                          />*/}
                                              </div>
                                              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 text-left">
                                                <div>
                                                  <p className="text-sm font-medium text-black truncate">
                                                    {listItem.firstName +
                                                      " " +
                                                      listItem.lastName}
                                                  </p>
                                                  <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    {listItem.role[0].toUpperCase() +
                                                      listItem.role.substring(
                                                        1
                                                      )}
                                                  </p>
                                                </div>
                                                <div className="hidden md:block">
                                                  <div>
                                                    <p className="text-sm text-gray-900">
                                                      Member since{" "}
                                                      <time>
                                                        {new Date(
                                                          listItem.joinedDate
                                                            ._seconds * 1000
                                                        ).toLocaleDateString()}
                                                      </time>
                                                    </p>
                                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                                      {listItem.eligibleToStream ===
                                                        true ? (
                                                        <CheckCircleIcon
                                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                                          aria-hidden="true"
                                                        />
                                                      ) : (
                                                        <XCircleIcon
                                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                                          aria-hidden="true"
                                                        />
                                                      )}
                                                      {listItem.eligibleToStream ===
                                                        true
                                                        ? "Active"
                                                        : "Deactivated"}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              <ChevronRightIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                            </div>
                                          </div>
                                        </Disclosure.Button>
                                        <Disclosure.Panel
                                          as="dd"
                                          className="mt-2 pl-10"
                                        >
                                          <div>
                                            <div className="mt-5 ">
                                              <dl className="">
                                                {/*<dl className="sm:divide-y sm:divide-gray-200">*/}
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Full name
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.firstName +
                                                      " " +
                                                      listItem.lastName}
                                                  </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Email
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.email}
                                                  </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Joined
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <time>
                                                      {new Date(
                                                        listItem.joinedDate
                                                          ._seconds * 1000
                                                      ).toLocaleDateString()}
                                                    </time>
                                                  </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Sales generated
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.orders
                                                      ? listItem.orders
                                                      : 0}
                                                  </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Revenue generated
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.revenue
                                                      ? "$" +
                                                      listItem.revenue.USD.toFixed(
                                                        2
                                                      )
                                                      : "$" + 0}
                                                  </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Average stream time
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.numberOfStreams &&
                                                      listItem.timeStreamed
                                                      ? secondsToHms(
                                                        listItem.timeStreamed /
                                                        listItem.numberOfStreams
                                                      )
                                                      : secondsToHms(0)}
                                                  </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Average sales per stream
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.orders &&
                                                      listItem.numberOfStreams
                                                      ? (
                                                        listItem.orders /
                                                        listItem.numberOfStreams
                                                      ).toFixed(2)
                                                      : 0}
                                                  </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Number of streames
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.numberOfStreams
                                                      ? listItem.numberOfStreams
                                                      : 0}
                                                  </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Time streamed
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.timeStreamed
                                                      ? secondsToHms(
                                                        listItem.timeStreamed
                                                      )
                                                      : secondsToHms(0)}
                                                  </dd>
                                                </div>

                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Questions answered
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {listItem.questionsAnswered
                                                      ? listItem.questionsAnswered
                                                      : 0}
                                                  </dd>
                                                </div>

                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    {listItem.eligibleToStream ===
                                                      true
                                                      ? "Deactivate user"
                                                      : "Activate user"}
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <Switch
                                                      disabled={
                                                        processingAdvocateStatus
                                                      }
                                                      checked={
                                                        listItem.eligibleToStream ===
                                                        true
                                                      }
                                                      onChange={() => {
                                                        toggleAdvocateStatus(
                                                          listItem.userId,
                                                          i
                                                        );
                                                      }}
                                                      className={classNames(
                                                        listItem.eligibleToStream ===
                                                          true
                                                          ? "bg-coral-500"
                                                          : "bg-gray-200",
                                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coral-500"
                                                      )}
                                                    >
                                                      <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                          listItem.eligibleToStream ===
                                                            true
                                                            ? "translate-x-5"
                                                            : "translate-x-0",
                                                          "pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200"
                                                        )}
                                                      />
                                                    </Switch>
                                                  </dd>
                                                </div>
                                              </dl>
                                            </div>
                                          </div>
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                                );
                              })
                            ) : (
                              <div>
                                <p>There are currently no prospects</p>
                              </div>
                            )}
                          </ul>
                        </div>
                        {/*<table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-100">
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              <th scope="col" className="whitespace-nowrap">
                                Joined
                              </th>
                              <th scope="col">Sales</th>
                              <th scope="col">Rating</th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {listOfAdvocates.list !== "" ? (
                              listOfAdvocates.list.map((listItem, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="whitespace-nowrap">
                                      {listItem.firstName +
                                        " " +
                                        listItem.lastName}
                                    </td>
                                    <td>{listItem.email}</td>
                                    <td>
                                      {new Date(
                                        listItem.applicationApprovedAt
                                          ._seconds * 1000
                                      ).toLocaleDateString()}
                                    </td>
                                    <td>{listItem.salesGenerated}</td>
                                    <td>-</td>
                                    <td className="whitespace-nowrap">
                                      
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td>There are currently no prospects</td>
                              </tr>
                            )}
                          </tbody>
                            </table>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center h-screen">
                <svg
                  className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                {/*<p className="ml-2">Loading...</p>*/}
              </div>
            )}
          </div>
        )}
      </DashboardLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    state: state.app
  }),
  null
)(Team);
