import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import { UserAddIcon } from "@heroicons/react/outline";

import { connect } from "react-redux";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { languages } from "../utilities/languages";

import { ChevronDownIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StreamStep1 = ({ state, dispatch, props }) => {
  const [language, setLanguage] = useState("en");
  const [streamDetails, setStreamDetails] = useState(false);

  return (
    <div className="py-6">
      {!props.streamDetails && (
        <div>
          <Formik
            initialValues={{
              language: "en",
              widgetCategories: [],
            }}
            validationSchema={Yup.object({
              language: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              //setFormSubmitting(true);
              console.log(values.widgetCategory);
              props.createLiveStream(values.language, values.widgetCategories);
              setSubmitting(false);
              props.setStreamDetails();
              //setFormSubmitting(false);
              //resetForm();
            }}
          >
            {({ errors, touched, setFieldValue, isValidating }) => (
              <Form>
                <div className="text-base space-y-2">
                  <h3 className="mb-4">Stream details</h3>
                  <div className="grid grid-cols-6 gap-6">
                    <div
                      className={`${language === "US" || language === "CA"
                        ? "col-span-6 sm:col-span-3"
                        : "col-span-6 sm:col-span-6 mb-4"
                        }`}
                    >
                      <label htmlFor="language">Select language</label>
                      <div className="relative rounded-md mt-1">
                        <Field
                          id="language"
                          name="language"
                          as="select"
                          className={`${errors.language && touched.language
                            ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                            : "border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base`}
                          /*onChange={(e) => {
                                  
                                }}*/
                          onChange={(e) => {
                            console.log("e.target.value: ", e.target.value);
                            setLanguage(e.target.value);
                            setFieldValue("language", e.target.value);
                          }}
                        >
                          <option value="None">Select language</option>
                          {languages.map((language, i) => {
                            return (
                              <option value={language.abbreviation} key={i}>
                                {language.name}
                              </option>
                            );
                          })}
                          {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                        </Field>
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <ErrorMessage name="language">
                        {(error) => (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {error}
                          </span>
                        )}
                      </ErrorMessage>
                    </div>
                    <fieldset className="col-span-6 sm:col-span-6 mb-6">
                      <label>Categories</label>
                      <div className="border-b border-gray-200 divide-y divide-gray-200">
                        {state.widgetCategories.map((category, id) => (
                          <div
                            key={id}
                            className="relative flex items-center py-4"
                          >
                            <div className="mr-3 flex items-center h-5">
                              <Field
                                id={`widgetCategories-${id}`}
                                value={category}
                                name="widgetCategories"
                                type="checkbox"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                            </div>
                            <div className="min-w-0 text-sm">
                              <label
                                htmlFor={`widgetCategories-${id}`}
                                className="font-medium text-gray-700 select-none"
                              >
                                {category}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </fieldset>

                    {/*<button type="submit">Subscribe</button> */}
                  </div>
                  <div className="bg-white text-right">
                    <button type="submit" className="primary-button">
                      Next
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {props.streamDetails && (
        <div className="grid space-x-8">
          <div className="">
            <h3>Initiate the stream</h3>
            <p className="font-bold mt-4">1. Open OBS</p>
            <p>Open OBS from your computer</p>
            <p className="font-bold mt-4">2. Insert credentials</p>
            <p>Valid credential sf or your next stream are:</p>
            <p>
              Stream URL: <span className="font-bold">{props.streamURL}</span>
            </p>
            <p>
              Stream key: <span className="font-bold">{props.streamKey}</span>
            </p>
            <p className="font-bold mt-4">3. Start stream</p>
            <p>
              When you are ready, click "Start stream" in OBS and you will be
              streaming live on your store. This screen will then change to the
              Q&A board, where you will see questions from the viewers.{" "}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(StreamStep1);
