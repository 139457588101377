import React, { useEffect } from "react";
import { Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { addInitialData } from "../state/app";

import { CheckCircleIcon, XCircleIcon, InformationCircleIcon } from "@heroicons/react/outline";

const Notification = ({ state, props, dispatch }) => {

    useEffect(() => {
        if (state.notification?.showNotification) {
            setTimeout(() => {
                dispatch(
                    addInitialData({
                        notification: { ...state.notification, showNotification: false }
                    })
                )
            }, 12000)
        }
    }, [state.notification?.showNotification])

    const closeNotification = () => {
        dispatch(
            addInitialData({
                notification: { ...state.notification, showNotification: false }
            })
        )
    }

    return (
        <div>
            <div className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end z-[99]">
                <Transition
                    show={state.notification?.showNotification ? state.notification?.showNotification : false}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="max-w-sm w-full bg-white pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                >
                    <div className="p-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                {state.notification?.type === "success" && <CheckCircleIcon className="h-6 w-6 text-fingreen-500" aria-hidden="true" />}
                                {state.notification?.type === "information" && <InformationCircleIcon className="h-6 w-6 text-honeyYellow-500" aria-hidden="true" />}
                                {state.notification?.type === "error" && <XCircleIcon className="h-6 w-6 text-coral-500" aria-hidden="true" />}
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-gray-900">
                                    {state.notification?.header}
                                </p>
                                {state.notification?.message && (
                                    <p className="mt-1 text-sm text-gray-500">
                                        {state.notification?.message}
                                    </p>
                                )}
                            </div>
                            <div className="ml-4 mt-[2px] flex-shrink-0 flex">
                                <button
                                    onClick={() => {
                                        closeNotification()
                                    }}
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span className="sr-only">Close</span>
                                    {/* Heroicon name: solid/x */}
                                    <svg
                                        className="h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default connect(
    (state) => ({
        state: state.app
    }),
    null
)(Notification);