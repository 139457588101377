import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
//import stripe from 'stripe'
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";
import "firebase/auth";

import { RadioGroup } from '@headlessui/react'

import { connect } from "react-redux";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import { updateUserDetails } from "../state/app";

import { countries } from "../utilities/countries";
import { usStates } from "../utilities/usStates";
import { caStates } from "../utilities/caStates";
import { navigate } from "gatsby";

import { ChevronDownIcon } from "@heroicons/react/solid";

import firebaseFunction from "./../utilities/firebaseFunction.js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const settings = [
  { name: 'Public access', description: 'This project would be available to anyone who has the link' },
  { name: 'Private to Project Members', description: 'Only members of this project would be able to access' },
  { name: 'Private to you', description: 'You are the only one able to access this project' },
]

const PaymentAndBilling2 = ({ reduxState, dispatch, props }) => {
  //const [stripe, setStripe] = useState("");
  //const [stripeElements, setStripeElements] = useState('')
  const [stripeCard, setStripeCard] = useState("");
  const [isTalkingToStripe, setIsTalkingToStripe] = useState(false);
  const [stripeElementError, setStripeElementError] = useState(false);
  const [isStripeElementCompleted, setIsStripeElementCompleted] =
    useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [country, setCountry] = useState("US");
  const [state, setState] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('newCard')
  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(false)

  console.log("customerPaymentMethods: ", customerPaymentMethods)

  useEffect(() => {
    const getPaymentMethods = async () => {
      let customerPaymentMethodsResponse = await firebaseFunction("getCustomersPaymentMethods", {
        brandId: reduxState.brandId,
        stripeCustomerId: reduxState.stripeCustomerId
      })
      if (customerPaymentMethodsResponse.data) {
        setCustomerPaymentMethods(customerPaymentMethodsResponse.data)
        console.log("customerPaymentMethodsResponse.data::: ", customerPaymentMethodsResponse.data)

        customerPaymentMethodsResponse.data.map(method => {
          if (method.default) {
            setSelectedPaymentMethod(method.id)
          }
        })
      } else {
        setCustomerPaymentMethods(false)
      }
    }

    getPaymentMethods()

  }, [])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const stripe = useStripe();
  const elements = useElements();

  const handleSubscribeWithCreditCard = async (formValues) => {

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);


    let createStripeSubscriptionResponse = await firebaseFunction("createStripeSubscription2", {
      customerId: reduxState.stripeCustomerId,
      priceIds: props.chosenPlan.stripePriceIds,
      subscriptionPlanId: props.chosenPlan.id,
      formValues: formValues,
      brandId: reduxState.brandId,
      paymentMethod: selectedPaymentMethod
    })

    if (createStripeSubscriptionResponse.error) {
      setStripeElementError("There was an error. Please refresh the window and try again.")
      props.flipIsInitiatingSubscription(false);
      return;
    }

    console.log(
      "createStripeSubscriptionResponse: ",
      createStripeSubscriptionResponse
    );

    // Create payment method and confirm payment intent.
    try {
      let stripeResult
      if (selectedPaymentMethod === "newCard") {
        stripeResult = await stripe
          .confirmCardPayment(createStripeSubscriptionResponse.data.clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: formValues.firstName + " " + formValues.lastName, //TODO Change to use "NAME ON CARD" values (need to ad that field)
              },
            },
          })
      } else {
        stripeResult = await stripe
          .confirmCardPayment(createStripeSubscriptionResponse.data.clientSecret, {
            payment_method: selectedPaymentMethod,
          })
      }

      if (stripeResult.error) {
        throw (stripeResult.error);
      }

      switch (stripeResult.paymentIntent.status) {
        case 'succeeded':
          console.log('FFFFF: Success! Payment received.')

          const setDefaultPaymentMethod = async () => {
            let setDefaultPaymentMethod = await firebaseFunction("setDefaultPaymentMethod", {
              brandId: reduxState.brandId,
              stripeCustomerId: reduxState.stripeCustomerId,
              paymentMethodId: selectedPaymentMethod === "newCard" ? stripeResult.paymentIntent.payment_method : selectedPaymentMethod,
              newCard: selectedPaymentMethod === "newCard" ? true : false
            })
            console.log("PYMNT: ", setDefaultPaymentMethod)
          }

          setDefaultPaymentMethod()

          //TODO: Decide if this is needed or trust the webook to complete 
          const updateSubscriptionStatusFromPaymentIntentObject = async () => {
            let updateSubscriptionStatusFromPaymentIntentObject = await firebaseFunction("updateSubscriptionStatusFromPaymentIntentObject", { brandId: reduxState.brandId, paymentIntentId: stripeResult.paymentIntent.id, subscriptionId: createStripeSubscriptionResponse.data.subscriptionId })
            console.log("PYMNT: ", updateSubscriptionStatusFromPaymentIntentObject)
            props.setNewSubscriptionActivated(true)
          }
          updateSubscriptionStatusFromPaymentIntentObject()
          //props.flipIsSubscribed(true);
          break;

        case 'processing':
          console.log("FFFFF: Payment processing. We'll update you when payment is received.")
          props.flipIsInitiatingSubscription(false);
          break;

        case 'requires_payment_method':
          console.log('FFFFF: Payment failed. Please try another payment method.')
          setStripeElementError("We are sorry, there was an error processing your payment. Please try again (with a different card).")
          props.flipIsInitiatingSubscription(false);
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          break;

        default:
          console.log('FFFFF: Something went wrong.')
          setStripeElementError("We are sorry, there was an error processing your payment. Please try again (with a different card).")
          props.flipIsInitiatingSubscription(false);
          break;
      }
    } catch (error) {
      if (error.type === "card_error") {
        setStripeElementError(error.message)
        props.flipIsInitiatingSubscription(false);
      } else {
        console.error(error)
        setStripeElementError(`An error occurred: Please refresh the browser window and try again.`)
        props.flipIsInitiatingSubscription(false);
      }
    }
  };

  const stripeElementStatus = (event) => {
    console.log(event);
    setIsStripeElementCompleted(event.complete);
    if (event.error) {
      setStripeElementError(event.error.message);
    } else {
      setStripeElementError(false);
    }
  };

  return (
    <div>
      {reduxState.userRole === "admin" &&

        <div className="cardContainerOuter">
          <div className="cardContainerInner">
            <div>
              <h2>Billing and payment details</h2>
            </div>
            <div>
              <div className="py-5">
                <Formik
                  initialValues={{
                    firstName: reduxState.billingAddress.firstName || "",
                    lastName: reduxState.billingAddress.lastName || "",
                    email: reduxState.billingAddress.email || "",
                    companyName: reduxState.billingAddress.companyName || "",
                    taxId: reduxState.billingAddress.taxId || "",
                    country: reduxState.billingAddress.country || "US",
                    state: reduxState.billingAddress.state || "",
                    addressLine1: reduxState.billingAddress.addressLine1 || "",
                    addressLine2: reduxState.billingAddress.addressLine2 || "",
                    postalCode: reduxState.billingAddress.postalCode || "",
                    city: reduxState.billingAddress.city || "",
                  }}
                  validationSchema={Yup.object({
                    firstName: Yup.string().required("Required"),
                    lastName: Yup.string().required("Required"),
                    email: Yup.string()
                      .email("Invalid email address")
                      .required("Required"),
                    companyName: Yup.string().required("Required"),
                    country: Yup.string().required("Required"),
                    addressLine1: Yup.string().required("Required"),
                    postalCode: Yup.string().required("Required"),
                    city: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {


                    if (selectedPaymentMethod === "newCard") {
                      if (!isStripeElementCompleted) {
                        if (!stripeElementError) {
                          setStripeElementError(
                            "Required. Please make sure the field is filled in correctly."
                          );
                        }
                        return;
                      }
                    }
                    props.flipIsInitiatingSubscription(true);
                    setFormSubmitting(true);
                    handleSubscribeWithCreditCard(values);
                    setSubmitting(false);
                    setFormSubmitting(false);


                    //resetForm();
                  }}
                >
                  {({ errors, touched, setFieldValue, isValidating }) => (
                    <Form>
                      <div className="text-base space-y-2">
                        <h3 className="my-2">Billing address</h3>
                        <p className="mt-2 mb-4">Your card is charged at the beginning of each subscription period</p>
                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="firstName">First name</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                type="text"
                                name="firstName"
                                autoComplete="given-name"
                                placeholder="Buzz"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.firstName && touched.firstName
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="firstName">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="lastName">Last name</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                type="text"
                                name="lastName"
                                autoComplete="family-name"
                                required
                                placeholder="Lightyear"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.lastName && touched.lastName
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="lastName">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-6">
                            <label htmlFor="email">Email Address</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                name="email"
                                placeholder="buzz@lightyear.com"
                                type="email"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.email && touched.email
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="email">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="companyName">Company name</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                type="text"
                                name="companyName"
                                autoComplete="organization"
                                placeholder="Moonshot company"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.companyName && touched.companyName
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="companyName">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="taxId">Tax ID #</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                type="text"
                                name="taxId"
                                autoComplete=""
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.taxId && touched.taxId
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="taxId">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div
                            className={`${country === "US" || country === "CA"
                              ? "col-span-6 sm:col-span-3"
                              : "col-span-6 sm:col-span-6"
                              }`}
                          >
                            <label htmlFor="country">Country</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                id="country"
                                name="country"
                                as="select"
                                className={`${errors.country && touched.country
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                                /*onChange={(e) => {
                                
                              }}*/
                                onChange={(e) => {
                                  console.log(
                                    "e.target.value: ",
                                    e.target.value
                                  );
                                  setCountry(e.target.value);
                                  setFieldValue("country", e.target.value);
                                }}
                              >
                                <option value="None">Select country</option>
                                {countries.map((country, i) => {
                                  return (
                                    <option
                                      value={country.abbreviation}
                                      key={i}
                                    >
                                      {country.name}
                                    </option>
                                  );
                                })}
                                {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                              </Field>
                              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ChevronDownIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                            <ErrorMessage name="country">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          {(country === "US" || country === "CA") && (
                            <div className="col-span-6 sm:col-span-3">
                              <label htmlFor="state">State</label>
                              <div className="relative rounded-md mt-1">
                                <Field
                                  id="state"
                                  name="state"
                                  as="select"
                                  className={`${errors.state && touched.state
                                    ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                    : "border border-gray-300 focus:ring-black focus:border-black"
                                    } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                                  onChange={(e) => {
                                    setState(e.target.value);
                                    setFieldValue("state", e.target.value);
                                  }}
                                >
                                  <option value="None">Select state</option>
                                  {country === "US"
                                    ? usStates.map((state, i) => {
                                      return (
                                        <option
                                          value={state.abbreviation}
                                          key={i}
                                        >
                                          {state.name}
                                        </option>
                                      );
                                    })
                                    : caStates.map((state, i) => {
                                      return (
                                        <option
                                          value={state.abbreviation}
                                          key={i}
                                        >
                                          {state.name}
                                        </option>
                                      );
                                    })}
                                  {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                                </Field>
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                  <ChevronDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                              <ErrorMessage name="state">
                                {(error) => (
                                  <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                    {error}
                                  </span>
                                )}
                              </ErrorMessage>
                            </div>
                          )}

                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="addressLine1">
                              Address line 1
                            </label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                type="text"
                                name="addressLine1"
                                autoComplete="address-line1"
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.addressLine1 && touched.addressLine1
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="addressLine1">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="addressLine2">
                              Address line 2
                            </label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                type="text"
                                name="addressLine2"
                                autoComplete="address-line2"
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.addressLine2 && touched.addressLine2
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="addressLine2">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="postalCode">Postal code</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                type="text"
                                name="postalCode"
                                autoComplete="postal-code"
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.postalCode && touched.postalCode
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="postalCode">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="city">City</label>
                            <div className="relative rounded-md mt-1">
                              <Field
                                type="text"
                                name="city"
                                autoComplete="city"
                                placeholder="i.e. xx-xxxxxxx"
                                //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                className={`${errors.city && touched.city
                                  ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                  : "border border-gray-300 focus:ring-black focus:border-black"
                                  } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              />
                            </div>
                            <ErrorMessage name="city">
                              {(error) => (
                                <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                  {error}
                                </span>
                              )}
                            </ErrorMessage>
                          </div>
                          <div
                            className="col-span-6 mt-4"
                            style={{ marginBottom: "-15px" }}
                          >
                            <h3>Payment</h3>
                            <p className="mt-2 mb-4">Your card is charged at the beginning of each subscription period</p>
                          </div>



                          <div className="col-span-6 sm:col-span-6 mb-8">
                            <RadioGroup value={selectedPaymentMethod} onChange={setSelectedPaymentMethod}>
                              <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
                              <div className="bg-white rounded-md -space-y-px">
                                {customerPaymentMethods && customerPaymentMethods.map((method, i) => {
                                  return (
                                    <RadioGroup.Option
                                      key={i}
                                      value={method.id}
                                      className={({ checked }) =>
                                        classNames(
                                          i === 0 && 'rounded-tl-md rounded-tr-md',
                                          //settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                          checked ? 'bg-coral-50 border-coral-500 z-10' : 'border-gray-200',
                                          'relative border p-4 flex cursor-pointer focus:outline-none'
                                        )
                                      }
                                    >
                                      {({ active, checked }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              checked ? 'bg-coral-500 border-transparent' : 'bg-white border-gray-300',
                                              active ? '' : '',
                                              'h-4 w-4 min-w-[16px] mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                            )}
                                            aria-hidden="true"
                                          >
                                            <span className="rounded-full bg-white w-1.5 h-1.5" />
                                          </span>
                                          <div className="ml-3 flex flex-col">
                                            <RadioGroup.Label
                                              as="span"
                                              className={classNames(checked ? 'text-black' : 'text-gray-900', 'block text-sm font-medium')}
                                            >
                                              {method.brand.charAt(0).toUpperCase() + method.brand.slice(1)}
                                            </RadioGroup.Label>
                                            <RadioGroup.Description
                                              as="span"
                                              className={classNames(checked ? 'text-black' : 'text-gray-500', 'block text-sm')}
                                            >
                                              **** {method.last4}, {method.expMonth}/{method.expYear}
                                            </RadioGroup.Description>
                                            {stripeElementError && (
                                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800 w-fit">
                                                {stripeElementError}
                                              </span>
                                            )}
                                          </div>
                                        </>
                                      )}

                                    </RadioGroup.Option>
                                  )

                                })}

                                <RadioGroup.Option
                                  key='newCard'
                                  value={'newCard'}
                                  className={({ checked }) =>
                                    classNames(
                                      (customerPaymentMethods === false || customerPaymentMethods === undefined || customerPaymentMethods.length == 0) ? 'rounded-md' : 'rounded-bl-md rounded-br-md',
                                      checked ? 'bg-coral-50 border-coral-500 z-10' : 'border-gray-200',
                                      'relative border p-4 flex cursor-pointer focus:outline-none'
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          checked ? 'bg-coral-500 border-transparent' : 'bg-white border-gray-300',
                                          active ? '' : '',
                                          'h-4 w-4 min-w-[16px] mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                      </span>
                                      <div className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                          as="span"
                                          className={classNames(checked ? 'text-black' : 'text-gray-900', 'block text-sm font-medium')}
                                        >
                                          Add a new credit card
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="span"
                                          className={classNames(checked ? 'text-black' : 'text-gray-500', 'block text-sm')}
                                        >
                                        </RadioGroup.Description>
                                        <div className={classNames(checked ? 'flex flex-col pt-2 w-[330px]' : 'hidden')}>
                                          <div
                                            className={`${stripeElementError
                                              ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                              : "border border-gray-300 focus:ring-black focus:border-coral-500"
                                              } appearance-none block w-full px-3 py-3 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-coral-500 sm:text-base bg-white mt-1`}
                                          >
                                            <CardElement
                                              onChange={(event) => {
                                                stripeElementStatus(event);
                                              }}
                                              options={{
                                                style: {
                                                  base: {
                                                    "fontSize": "14px",
                                                    "fontFamily":
                                                      'monospace, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
                                                    "color": "black",
                                                    "::placeholder": {
                                                      color: "#9ca3af",
                                                    },
                                                  },
                                                  invalid: {
                                                    color: "#991b1b",
                                                  },
                                                },
                                              }}
                                            />
                                          </div>
                                          {stripeElementError && (
                                            <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800 w-fit">
                                              {stripeElementError}
                                            </span>
                                          )}

                                        </div>
                                      </div>
                                    </>
                                  )}
                                </RadioGroup.Option>

                              </div>
                            </RadioGroup>
                          </div>
                        </div>
                        <div className="text-right">
                          <button type="submit" className="primary-button">
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default connect(
  (state, props) => ({
    reduxState: state.app,
    props: props,
  }),
  null
)(PaymentAndBilling2);
