import React, { useEffect, useState } from "react";
import { PlayerSdk } from "@api.video/player-sdk";
import { connect } from "react-redux";

import firebase from "firebase/app";

import "firebase/functions";
import "firebase/auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StreamStep2 = ({ state, dispatch, props }) => {
  const [apiVideoSDK, setApiVideoSDK] = useState("");
  const [isActivating, setIsActivating] = useState(false);

  useEffect(() => {
    if (!apiVideoSDK) {
      const sdk = new PlayerSdk("#nuine-live-stream", {
        id: props.streamId,
        live: true,
        muted: true,
        hideControls: true,
        hideTitle: true,
        autoPlay: false,
      });
      setApiVideoSDK(sdk);
    }
  }, []);

  const activateLiveStream = () => {
    console.log("TEST");
    setIsActivating(true);
    var activateLiveStream = firebase
      .functions()
      .httpsCallable("activateLiveStream");

    activateLiveStream({
      brandId: state.brandId,
      streamId: props.streamId,
    }).then((result) => {
      /*if (result.data.code === 200) {
        console.log("SUCCESS");
      }*/
      setIsActivating(false);
      console.log("RESULT: ", result);
    });
  };

  return (
    <div className="py-6">
      <div className="flex flex-col items-center justify-center flex-grow">
        <div className="relative w-full h-0 pb-562">
          <div
            id="nuine-live-stream"
            className="absolute h-full w-full flex justify-center items-end top-0 left-0"
          ></div>
        </div>

        <button
          onClick={() => activateLiveStream()}
          disabled={isActivating}
          className="primary-button mt-6"
        >
          {isActivating ? (
            <svg
              className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Publish stream to webpage"
          )}
        </button>
      </div>
    </div>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(StreamStep2);
